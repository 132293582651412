import {Button as AntButton} from 'antd';
import styled from 'styled-components';

export const StyledDangerButton = styled(AntButton)`
    :hover,
    :focus {
        color: red;
    }
`;
export const StyledTagContainer = styled.div`
    > *:not(:last-child) {
        margin-right: 15px;
    }
`;

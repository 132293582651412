import {put, select, takeLatest} from 'redux-saga/effects';
import getQuotes from '../../../api/quote-builder/get-quotes';
import {IGetQuoteResponseData} from '../../../interfaces/quote/responses/quote-response.interface';
import {
    IGetAllQuotesStartAction,
    IGetMoreQuotesStartAction,
    QuoteViewActionTypes,
} from '../../action-types/quote-view-types';
import {getAllQuotesDone, getMoreQuotesDone} from '../../actions/quote-view.actions';
import {ReduxStoreType} from '../../root.reducer';

export function* getAllQuotes({payload}: IGetAllQuotesStartAction) {
    const {organisation} = payload;
    const {authorization}: ReduxStoreType = yield select();
    try {
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();
        const response: IGetQuoteResponseData = yield getQuotes(accessToken, organisation);
        const sortedQuotes = response.data.sort(
            (a, b) => new Date(b.createdAt!)!.getTime() - new Date(a.createdAt!)!.getTime()
        );
        yield put(
            getAllQuotesDone({
                allQuotes: {
                    data: sortedQuotes,
                    metaData: response.metaData,
                },
            })
        );
    } catch (error) {
        yield put(
            getAllQuotesDone({
                errorGettingQuotes: true,
                errorMessageGettingQuotes: 'There was an error getting all quotes',
            })
        );
    }
}

export function* onGetAllQuotes() {
    yield takeLatest(QuoteViewActionTypes.GET_ALL_QUOTES_START, getAllQuotes);
}

export function* getMoreQuotes({payload}: IGetMoreQuotesStartAction) {
    const {organisation} = payload;
    const {authorization, quoteView}: ReduxStoreType = yield select();
    try {
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const {allQuotes} = quoteView;
        const accessToken: string = yield getAccessTokenSilently();
        const response: IGetQuoteResponseData = yield getQuotes(
            accessToken,
            organisation,
            allQuotes.metaData?.nextPage
        );
        const newQuotes = [...allQuotes.data, ...response.data];
        const sortedQuotes = newQuotes.sort(
            (a, b) => new Date(b.createdAt!)!.getTime() - new Date(a.createdAt!)!.getTime()
        );
        yield put(
            getMoreQuotesDone({
                allQuotes: {
                    data: sortedQuotes,
                    metaData: response.metaData,
                },
            })
        );
    } catch (error) {
        yield put(
            getMoreQuotesDone({
                errorGettingQuotes: true,
                errorMessageGettingQuotes: 'There was an error getting more quotes',
            })
        );
    }
}

export function* onGetMoreQuotes() {
    yield takeLatest(QuoteViewActionTypes.GET_MORE_QUOTES_START, getMoreQuotes);
}

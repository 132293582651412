import {
    IGetAllQuotesDonePayload,
    IDeleteQuoteStartPayload,
    IDeleteQuoteDonePayload,
    QuoteViewActionTypes,
} from '../action-types/quote-view-types';
export const getAllQuotesStart = (payload: {organisation: string}) => ({
    type: QuoteViewActionTypes.GET_ALL_QUOTES_START,
    payload,
});

export const getAllQuotesDone = (payload: IGetAllQuotesDonePayload) => ({
    type: QuoteViewActionTypes.GET_ALL_QUOTES_DONE,
    payload,
});

export const deleteQuoteStart = (payload: IDeleteQuoteStartPayload) => ({
    type: QuoteViewActionTypes.DELETE_QUOTE_START,
    payload,
});

export const deleteQuoteDone = (payload: IDeleteQuoteDonePayload) => ({
    type: QuoteViewActionTypes.DELETE_QUOTE_DONE,
    payload,
});

export const getMoreQuotesStart = (payload: {organisation: string | null}) => ({
    type: QuoteViewActionTypes.GET_MORE_QUOTES_START,
    payload,
});

export const getMoreQuotesDone = (payload: IGetAllQuotesDonePayload) => ({
    type: QuoteViewActionTypes.GET_MORE_QUOTES_DONE,
    payload,
});

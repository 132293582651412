export interface ITableHeaderTitle {
    title: string; 
    align?: 'left' | 'right' | 'center';
}

export const RenewLicenseTableHeaderTitle: ITableHeaderTitle[] = [
    {
        title: 'Product',
        align: 'left',
    },
    {
        title: 'Expiry date',
        align: 'left',
    },
    {
        title: 'Tier',
        align: 'left',
    },
    {
        title: 'Amount',
        align: 'right',
    },
    {
        title: 'Action',
        align: 'center',
    },
];

export const UsersTableHeaderTitle: ITableHeaderTitle[] = [
    {
        title: 'Email address',
        align: 'left',
    },
    {
        title: 'Added on',
        align: 'left',
    },
    {
        title: 'Action',
        align: 'center',
    },
];

export const QuoteItemTableHeaderTitle: ITableHeaderTitle[] = [
    {
        title: 'Product',
        align: 'left',
    },
    {
        title: 'Tier',
        align: 'left',
    },
    {
        title: 'Price',
        align: 'right',
    },
    {
        title: 'Action',
        align: 'center',
    },
];

export const UpgradeLicenseTableHeaderTitle: ITableHeaderTitle[] = [
    {
        title: 'Service',
        align: 'left',
    },
    {
        title: 'Tier',
        align: 'left',
    },
    {
        title: 'Action',
        align: 'center',
    },
];

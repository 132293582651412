import { UserActionTypes, IUserState } from '../action-types/user.types';

export const INITIAL_STATE: IUserState = {
    gettingUsers: false,
    gettingMoreUsers: false,
    users: {
        data: [],
        metaData: {
            nextPage: '',
        },
    },
    error: false,
    errorMessage: null,
    organisation: {
        organisationData: null,
        gettingData: false,
        errorGettingData: false,
        errorMessage: null,

        isSaving: false,
        errorSaving: false,
        savedChanges: false,
    },
};

const userReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case UserActionTypes.GET_ALL_USERS_START:
            return {
                ...state,
                gettingUsers: true,
                error: false,
            };
        case UserActionTypes.GET_MORE_USERS_START:
            return {
                ...state,
                gettingMoreUsers: true,
                error: false,
            };
        case UserActionTypes.GET_ALL_USERS_DONE:
            return {
                ...state,
                gettingUsers: false,
                ...action.payload,
            };
        case UserActionTypes.GET_MORE_USERS_DONE:
            return {
                ...state,
                gettingMoreUsers: false,
                ...action.payload,
            };
        case UserActionTypes.GET_ORGANISATION_DETAILS_START:
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    savedChanges: false,
                    gettingData: true,
                    errorGettingData: false,
                    errorMessage: null,
                },
            };
        case UserActionTypes.GET_ORGANISATION_DETAILS_DONE:
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    gettingData: false,
                    errorGettingData: false,
                    errorMessage: null,
                    savedChanges: false,
                    ...action.payload,
                },
            };
        case UserActionTypes.UPDATE_ORGANISATION_DETAILS_START:
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    isSaving: true,
                    errorSaving: false,
                    savedChanges: false,
                    errorMessage: null,
                },
            };
        case UserActionTypes.UPDATE_ORGANISATION_DETAILS_DONE:
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    isSaving: false,
                    errorMessage: action.payload.errorMessage || null,
                    errorSaving: action.payload.errorSaving || false,
                    savedChanges: action.payload.savedChanges || true,
                    organisationData: {
                        ...state.organisation.organisationData,
                        contactDetails: {
                            ...state.organisation.organisationData?.contactDetails,
                            ...action.payload.contactDetails,
                        },
                    },
                },
            };

        default:
            return state;
    }
};

export default userReducer;

import axios from 'axios';
import {HAMLET_API_KEY, HAMLET_URL} from '../../../../constants/api.constants';
import { ILockedAtlassianQuoteData } from '../../../../interfaces/suppliers/atlassian/atlassian-cart.interface';

const lockAtlassianCart = async (cartID: string, mac: string): Promise<ILockedAtlassianQuoteData> => {
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };

    const response = await axios.post(`${HAMLET_URL}/cart/toorder`, {cartID}, {headers});

    return response.data;
};

export default lockAtlassianCart;

import {IAtlassianItem} from '../../../interfaces/suppliers/atlassian/atlassian-item.interface';
import Supplier from '../../../enums/supplier';
import {IOrganisationTier} from '../../../interfaces/organisation/organisation.interface';

export const atlassianItemFactory = (
    atlassianItem: IAtlassianItem,
    organisationTier: IOrganisationTier | null = null
): IAtlassianItem => ({
    supplier: Supplier.atlassian,
    uuid: atlassianItem.uuid,
    name: atlassianItem.name,
    saleType: atlassianItem.saleType,
    service: atlassianItem.service,
    discounts: atlassianItem.discounts,
    totalDiscount: atlassianItem.totalDiscount,
    price: atlassianItem.price,
    quantity: atlassianItem.quantity,
    unitCount: atlassianItem.unitCount,
    unitLabel: atlassianItem.unitLabel,
    months: atlassianItem.months,
    description: atlassianItem.description,
    createdAt: atlassianItem.createdAt,
    productKey: atlassianItem.productKey,
});

import {atlassianItemFactory} from './atlassian-item.factory';
import {IAtlassianCart} from '../../../interfaces/suppliers/atlassian/atlassian-cart.interface';
import Supplier from '../../../enums/supplier';
import {IOrganisationTier} from "../../../interfaces/organisation/organisation.interface";

export const atlassianCartFactory = (cart: IAtlassianCart, organisationTier: IOrganisationTier | null = null): IAtlassianCart => ({
    type: Supplier.atlassian,
    uuid: cart.uuid,
    items: cart.items.map((i: any) => atlassianItemFactory(i, organisationTier)),
    currency: cart.currency,
    lockedQuoteData: cart.lockedQuoteData,
});

import React from 'react';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {Button, PanelBody, PanelHeader, PanelWithBorder, Rule} from '@adaptavist/component-library';
import {Row} from 'antd';
import {ReactComponent as MarketplaceLogo} from '../../assets/marketplace_logo.svg';
import CenterContent, {CenterCol} from '../../components/structure/center-content';
import {AppName} from '../../constants/metadata.constants';

const LandingView = () => {
    document.title = `${AppName}`;
    const {loginWithRedirect} = useAuth0WithCypress();
    const signUpWithRedirect = () => loginWithRedirect({screen_hint: 'signup'});
    return (
        <CenterContent height="90vh">
            <PanelWithBorder>
                <PanelHeader paddingBottom={2}>
                    <CenterCol>
                        <MarketplaceLogo />
                        <Rule />
                    </CenterCol>
                </PanelHeader>
                <br />
                <PanelBody>
                    <Row gutter={[0, 20]}>
                        <CenterCol>
                            <Button block onClick={loginWithRedirect}>
                                Login
                            </Button>
                        </CenterCol>
                        <CenterCol>
                            <Button block ghost onClick={signUpWithRedirect}>
                                Sign Up
                            </Button>
                        </CenterCol>
                    </Row>
                </PanelBody>
            </PanelWithBorder>
        </CenterContent>
    );
};
export default LandingView;

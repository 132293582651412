import axios from 'axios';
import {PARTNER_PORTAL_API, PARTNER_PORTAL_API_KEY} from '../../constants/api.constants';
import {quoteFactory} from '../../factories/quote.factory';
import {IQuote} from '../../interfaces/quote/quote.interface';
import {IOrganisationTier} from '../../interfaces/organisation/organisation.interface';

interface ICreateQuote {
    quote: IQuote;
    accessToken: string;
    organisation: string;
    organisationTier: IOrganisationTier | null;
}

const createQuote = async (payload: ICreateQuote) => {
    const {quote, accessToken, organisation, organisationTier} = payload;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': PARTNER_PORTAL_API_KEY,
    };

    const {organisation: organisationUuid, user, uuid, ...quoteData} = quote;

    const data = {
        pdfTemplate: 'proforma-demo-v35.docx',
        ...quoteData,
    };

    const response = await axios.post(`${PARTNER_PORTAL_API}/organisations/${organisation}/quotes`, data, {headers});

    return quoteFactory(response.data, organisationTier);
};

export default createQuote;

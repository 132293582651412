import styled from 'styled-components';

export const ButtonContainer = styled.div`
    text-align: right;
    > *:not(:last-child) {
        margin-right: 30px;
    }
`;

export const RolesContainer = styled.div`
    > *:not(:last-child) {
        margin-right: 15px;
    }
    > * {
        margin-bottom: 15px;
    }
`;

export const EmailTag = styled.div`
    background-color: #d4d5d6;
    font-size: 16px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
`;

export const TagButton = styled.button`
    background-color: white;
    outline: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #4775ff;
        color: #fff;
    }
`;

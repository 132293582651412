import {notification} from 'antd';

export const openNotification = ({
    content,
    type,
    duration = 10,
}: {
    content: string;
    type: 'error' | 'success';
    duration?: number;
}) => {
    notification.config({
        placement: 'bottomRight',
        duration: duration,
    });
    notification[type]({
        message: content || 'There was an error while trying to send invite. Please, try again',
    });
};

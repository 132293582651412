import React, {FC, useState} from 'react';
import {useParams} from 'react-router';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {useDispatch} from 'react-redux';
// COMPONENTS
import CenterContent from '../../components/structure/center-content';
import InviteUnconfirmedPanel from './confirm-invite-panels/invite-unconfirmed-panel';
import InviteConfirmedPanel from './confirm-invite-panels/invite-confirmed-panel';
import InviteFailedPanel from './confirm-invite-panels/invite-failed-panel';
// API
import confirmInvite from '../../api/chell/users/confirm-invite';
// ACTION
import {handleLogin} from '../../redux/actions/authorization.actions';
// OTHERS
import ConfirmInviteStatus from '../../enums/confirm-invite-status';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';

const ConfirmInviteView: FC = () => {
    const [invitationStatus, setInvitationStatus] = useState<ConfirmInviteStatus>(ConfirmInviteStatus.unconfirmed);
    const [isConfirming, setIsConfirming] = useState(false);
    const params = useParams<{token: string}>();
    const {user, getAccessTokenSilently, getIdTokenClaims} = useAuth0WithCypress();
    const {setOrganisationsClaims} = useOrganisation();
    const dispatch = useDispatch();

    const confirmationHandler = async () => {
        if (!user) return;
        setIsConfirming(true);
        const accessToken = await getAccessTokenSilently();
        try {
            await confirmInvite(accessToken, params.token);
        } catch (e) {
            setInvitationStatus(ConfirmInviteStatus.confirmationFailed);
            setIsConfirming(false);
            return;
        }
        try {
            await getAccessTokenSilently({ignoreCache: true});
        } catch (e) {
            console.log('Failed to refresh auth0 token');
        }
        try {
            const claims: any = await getIdTokenClaims();
            const organisations = claims['https://my-adaptavist.com/authorization'].organisations;
            dispatch(handleLogin(organisations));
            setOrganisationsClaims(claims);
        } catch (e) {
            console.log('Failed to reload organisation');
        }
        setInvitationStatus(ConfirmInviteStatus.confirmed);
        setIsConfirming(false);
    };

    const isInvitationStatus = (status: ConfirmInviteStatus) => status === invitationStatus;

    return (
        <CenterContent height="70vh">
            <div style={{paddingTop: '40px', margin: '0 auto', maxWidth: '400px'}}>
                {isInvitationStatus(ConfirmInviteStatus.unconfirmed) && (
                    <InviteUnconfirmedPanel isConfirming={isConfirming} confirmationHandler={confirmationHandler} />
                )}
                {isInvitationStatus(ConfirmInviteStatus.confirmed) && <InviteConfirmedPanel />}
                {isInvitationStatus(ConfirmInviteStatus.confirmationFailed) && (
                    <InviteFailedPanel isConfirming={isConfirming} confirmationHandler={confirmationHandler} />
                )}
            </div>
        </CenterContent>
    );
};

export default ConfirmInviteView;

import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Radio, Select} from 'antd';
import {Button, Table, TableCell, TableHeader} from '@adaptavist/component-library';
import getAtlassianTiers from '../../../../api/carts/atlassian/hamlet/get-atlassian-tiers';
import {IAtlassianProductTiers} from '../../../../interfaces/suppliers/atlassian/atlassian-product-tiers.interface';
// import Select from 'react-select';
import {toCapitalised} from '../../../../utilities/switch-case';
import IReactSelectOption from '../../../../interfaces/forms/react-select.interface';
import {useDispatch, useSelector} from 'react-redux';
import {addToCartStart, setSelectedProduct} from '../../../../redux/actions/quote-actions';
import styled from 'styled-components';
import {RadioChangeEvent} from 'antd/lib/radio';
import {LoadingPage} from '../../../loading';
import {ReduxStoreType} from '../../../../redux/root.reducer';
import Supplier from '../../../../enums/supplier';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

const {Option} = Select;

const TableHeaderTitle: {title: string; align?: 'left' | 'right' | 'center'}[] = [
    {title: 'Product', align: 'left'},
    {title: 'Tier', align: 'left'},
    {title: 'Maintenance period', align: 'left'},
    {title: 'Action', align: 'center'},
];

const SpacedButtonsWrapper = styled.div`
    > *:not(:last-child) {
        margin: 15px;
    }
`;

const AtlassianSelector = () => {
    const quoteReduxState = useSelector((state: ReduxStoreType) => state.quote);
    const {selectedProduct} = quoteReduxState;
    const dispatch = useDispatch();

    const {getOrganisationTier} = useOrganisation();
    const [stagedProduct, setStagedProduct] = useState<IAtlassianProductTiers | null>(null);
    const [stagedProductTierOptions, setStagedProductTierOptions] = useState<IReactSelectOption[] | null>(null);
    const [fetchingProduct, setFetchingProduct] = useState(false);

    const [selectorState, setSelectorState] = useState<{tier: string | null; month: typeof months[0]}>({
        tier: null,
        month: months[0],
    });

    useEffect(() => {
        if (!selectedProduct) return;
        setFetchingProduct(true);
        const fetchProductTiers = async () => await getAtlassianTiers(selectedProduct.value, 'UK');
        fetchProductTiers().then((product) => {
            setStagedProduct(product);
            const productOptions = getOptions(product);
            const defaultTier = productOptions ? productOptions[0].value : null;
            setSelectorState({
                ...selectorState,
                tier: defaultTier,
            });
            setStagedProductTierOptions(productOptions);
            setFetchingProduct(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProduct]);

    const handleTierChange = (value: string) => {
        setSelectorState({...selectorState, tier: value});
    };

    const handleMonthChange = (event: RadioChangeEvent) => {
        const {name, value} = event.target;
        if (name !== undefined) {
            setSelectorState({...selectorState, [name]: value});
        }
    };

    const {addingToCart, errorAddingToCart} = quoteReduxState;
    const {analytics} = useSegment();

    const handleAddToCart = () => {
        const {tier, month} = selectorState;
        const organisationTier = getOrganisationTier();
        dispatch(
            addToCartStart({
                organisationTier,
                tier,
                month,
                supplier: Supplier.atlassian,
                trackUserAction: analytics.trackUserAction,
            })
        );
    };

    if (fetchingProduct) {
        return (
            <LoadingPage>
                <h2>Getting product...</h2>
            </LoadingPage>
        );
    }

    if (addingToCart) {
        return (
            <LoadingPage>
                <h2>Adding product...</h2>
            </LoadingPage>
        );
    }

    return (
        <Fragment>
            {stagedProduct && stagedProductTierOptions && (
                <div>
                    <Table>
                        <thead>
                            <tr>
                                {TableHeaderTitle.map(({title, align}, index) => (
                                    <TableHeader key={`${title}-${index}`} align={align}>
                                        {title}
                                    </TableHeader>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <TableCell>
                                    <p>{stagedProduct!.description}</p>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        defaultValue={stagedProductTierOptions[0].value}
                                        style={{width: 151}}
                                        onChange={handleTierChange}
                                    >
                                        {stagedProductTierOptions.map((option, index) => (
                                            <Option key={`${option.label}-${index}`} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Radio.Group defaultValue={months[0]} name="month" onChange={handleMonthChange}>
                                        {months.map((month, index) => (
                                            <Radio key={`${month.label}-${index}`} value={month}>
                                                {`${month.value} Months`}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </TableCell>
                                <TableCell align="center">
                                    <SpacedButtonsWrapper>
                                        <Button
                                            onClick={handleAddToCart}
                                            loading={addingToCart}
                                            disabled={addingToCart}
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            ghost
                                            buttonType="danger"
                                            onClick={() => dispatch(setSelectedProduct(null))}
                                            disabled={addingToCart}
                                        >
                                            Cancel
                                        </Button>
                                    </SpacedButtonsWrapper>
                                </TableCell>
                            </tr>
                        </tbody>
                    </Table>
                    {errorAddingToCart && (
                        <Alert type="error" message="There was an error adding product. Please try again" closable />
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default AtlassianSelector;

const getOptions = (stagedProduct: IAtlassianProductTiers) =>
    stagedProduct.tiers.map((t) => ({
        value: t.id,
        label: `${getUnits(t.units)} ${getLabel(t.label, t.units)}`,
    }));

const getUnits = (units: number) => {
    if (units > 0 && units < 987654321) return units;
    return 'Unlimited';
};

const getLabel = (label: string, units: number) => `${toCapitalised(label)}${units !== 1 ? 's' : ''}`;

const months = [
    {value: '12', label: '12 Months'},
    {value: '24', label: '24 Months'},
];

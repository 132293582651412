import {ICart} from '../../interfaces/quote/cart.interface';
import {IItem} from '../../interfaces/quote/item.interface';
import {IQuote} from '../../interfaces/quote/quote.interface';
import IProductLookup from '../../interfaces/suppliers/atlassian/product-lookup.interface';
import {
    IAddToCartByTypeStartActionPayload,
    IGenerateQuoteDonePayload,
    IGenerateQuoteStartPayload,
    QuoteActionTypes,
} from '../action-types/quote-types';
import IPerson from '../../interfaces/contact/person.interface';
import ICompany from '../../interfaces/contact/company.interface';

export const replaceQuote = (newQuote: IQuote) => ({
    type: QuoteActionTypes.REPLACE_QUOTE,
    payload: newQuote,
});

export const resetQuote = () => ({
    type: QuoteActionTypes.RESET_QUOTE,
});

export const createQuote = (userUuid: string, organisationUuid: string) => ({
    type: QuoteActionTypes.CREATE_QUOTE,
    payload: {
        userUuid,
        organisationUuid,
    },
});

export const addToCartStart = (payload: IAddToCartByTypeStartActionPayload) => ({
    type: QuoteActionTypes.ADD_TO_CART_BY_TYPE_START,
    payload,
});

export const addToCartDone = (payload: ICart) => ({
    type: QuoteActionTypes.ADD_TO_CART_DONE,
    payload,
});

export const addToCartFailed = () => ({
    type: QuoteActionTypes.ADD_TO_CART_FAILED,
});

export const setSelectedProduct = (payload: IProductLookup | null) => ({
    type: QuoteActionTypes.SET_SELECTED_PRODUCT,
    payload,
});

export const removeCart = (uuid: string) => ({
    type: QuoteActionTypes.REMOVE_FROM_CART,
    payload: uuid,
});

export const replaceCart = (cart: ICart) => ({
    type: QuoteActionTypes.REPLACE_CART,
    payload: cart,
});

export const addItemByUuid = (cartUuid: string, item: IItem) => ({
    type: QuoteActionTypes.ADD_ITEM_BY_UUID,
    payload: {
        cartUuid,
        item,
    },
});

export const addItemByType = (item: IItem) => ({
    type: QuoteActionTypes.ADD_ITEM_BY_TYPE,
    payload: item,
});

export const removeItem = (cartUuid: string, item: IItem) => ({
    type: QuoteActionTypes.REMOVE_ITEM,
    payload: {
        cartUuid,
        item,
    },
});

export const replaceItem = (cartUuid: string, item: IItem) => ({
    type: QuoteActionTypes.REPLACE_ITEM,
    payload: {
        cartUuid,
        item,
    },
});

export const setTechnicalContactAction = (accessToken: string, techContact: IPerson, techOrganisation: ICompany) => ({
    type: QuoteActionTypes.SET_TECHNICAL_CONTACT_DETAILS,
    payload: {
        techContact,
        techOrganisation,
        accessToken,
    },
});

export const generateQuoteStart = (payload: IGenerateQuoteStartPayload) => ({
    type: QuoteActionTypes.GENERATE_QUOTE_START,
    payload,
});

export const generateQuoteDone = (payload: IGenerateQuoteDonePayload) => ({
    type: QuoteActionTypes.GENERATE_QUOTE_DONE,
    payload,
});

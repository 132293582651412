import React, {FC} from 'react';
import {IItem} from '../../../interfaces/quote/item.interface';
import DefaultItemRow from './suppliers/default-item-row';
import AtlassianItemRow from './suppliers/atlassian-item-row';
import {IAtlassianItem} from '../../../interfaces/suppliers/atlassian/atlassian-item.interface';

export interface IItemRowSwitch {
    cartUuid: string;
    trackingId: string;
    item: IItem;
}

const ItemRowSwitch: FC<IItemRowSwitch> = (props) => {
    const {item} = props;
    switch (item.supplier) {
        case 'atlassian':
            return <AtlassianItemRow {...props} item={item as IAtlassianItem} />;
        default:
            return <DefaultItemRow {...props} />;
    }
};

export default ItemRowSwitch;

import {IOrganisationFormValues} from '../../components/forms/form-values-interface';
import {ITrackUserAction} from '@adaptavist-commerce/analytics-provider-react';
import {IUserAPIResponse} from '../../interfaces/rbac/user/user.interface';

export const UserActionTypes = {
    GET_ALL_USERS_START: 'GET_ALL_USERS_START',
    GET_ALL_USERS_DONE: 'GET_ALL_USERS_DONE',
    GET_MORE_USERS_START: 'GET_MORE_USERS_START',
    GET_MORE_USERS_DONE: 'GET_MORE_USERS_DONE',

    GET_ORGANISATION_DETAILS_START: 'GET_ORGANISATION_DETAILS_START',
    GET_ORGANISATION_DETAILS_DONE: 'GET_ORGANISATION_DETAILS_DONE',
    UPDATE_ORGANISATION_DETAILS_START: 'UPDATE_ORGANISATION_DETAILS_START',
    UPDATE_ORGANISATION_DETAILS_DONE: 'UPDATE_ORGANISATION_DETAILS_DONE',
};

export interface IOrganisationData {
    contactDetails: IOrganisationFormValues | null;
    tier?: {
        discount: number;
        name: string;
    };
}

export interface IUserState {
    gettingUsers: boolean;
    gettingMoreUsers: boolean;
    users: IUserAPIResponse;
    error: false;
    errorMessage: string | null;
    organisation: {
        organisationData: IOrganisationData | null;
        gettingData: boolean;
        errorGettingData: boolean;
        errorMessage: boolean | null;

        isSaving: boolean;
        errorSaving: boolean;
        savedChanges: boolean;
    };
}

export interface IGetAllUsersStartAction {
    type: typeof UserActionTypes.GET_ALL_USERS_START;
    payload: IGetAllUsersStartPayload;
}

export interface IGetAllUsersStartPayload {
    organisation: string | null;
}

export interface IGetAllUsersDoneActionPayload {
    users?: IUserAPIResponse;
    error: boolean;
}

export interface IGetAllUsersDoneAction {
    type: typeof UserActionTypes.GET_ALL_USERS_DONE;
    payload: IGetAllUsersDoneActionPayload;
}

export interface IGetMoreUsersStartAction {
    type: typeof UserActionTypes.GET_MORE_USERS_START;
    payload: IGetMoreUsersStartPayload;
}

export interface IGetMoreUsersStartPayload {
    organisation: string | null;
}

export interface IGetMoreUsersDonePayload {
    users?: IUserAPIResponse;
    error: boolean;
}

export interface IGetMoreUsersDoneAction {
    type: typeof UserActionTypes.GET_MORE_USERS_DONE;
    payload: IGetMoreUsersDonePayload;
}

export interface IGetOrganisationDetailsStartAction {
    type: typeof UserActionTypes.GET_ORGANISATION_DETAILS_START;
    payload: IGetOrganisationDetailsStartPayload;
}

export interface IGetOrganisationDetailsStartPayload {
    organisation: string | null;
}

export interface IGetOrganisationDonePayload {
    errorGettingData?: boolean;
    errorMessage?: string | null;
    organisationData?: IOrganisationData;
}
export interface IGetOrganisationDoneAction {
    type: typeof UserActionTypes.GET_ORGANISATION_DETAILS_DONE;
    payload: IGetOrganisationDonePayload;
}

export interface IUpdateOrganisationStartPayload {
    contactDetails: IOrganisationFormValues;
    organisation: string | null;
    trackUserAction?: (payload: ITrackUserAction) => void;
}
export interface IUpdateOrganisationStartAction {
    type: typeof UserActionTypes.UPDATE_ORGANISATION_DETAILS_START;
    payload: IUpdateOrganisationStartPayload;
}

export interface IUpdateOrganisationDonePayload {
    errorSaving?: boolean;
    savedChanges?: boolean;
    contactDetails?: IOrganisationFormValues;
    errorMessage?: string;
}
export interface IUpdateOrganisationDoneAction {
    type: typeof UserActionTypes.UPDATE_ORGANISATION_DETAILS_DONE;
    payload: IUpdateOrganisationDonePayload;
}

import React, {FC} from 'react';
import {IItem} from '../../../../interfaces/quote/item.interface';
import {useDispatch} from 'react-redux';
import {removeItem} from '../../../../redux/actions/quote-actions';
import {Button, TableCell} from "@adaptavist/component-library";

const DefaultItemRow: FC<{cartUuid: string; item: IItem}> = ({cartUuid, item}) => {
    const dispatch = useDispatch();
    return (
        <tr key={item.uuid}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.quantity} Qty</TableCell>
            <TableCell align="right">${item.price}</TableCell>
            <TableCell align="center">
                <Button buttonType="danger" ghost onClick={() => dispatch(removeItem(cartUuid, item))}>
                    Delete
                </Button>
            </TableCell>
        </tr>
    );
};

export default DefaultItemRow;

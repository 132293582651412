import axios from 'axios';
import {IUser} from '../../../interfaces/rbac/user/user.interface';
import {CHELL_API_URL, CHELL_API_KEY} from '../../../constants/api.constants';
import IRbacStoredData from '../../../interfaces/rbac/rbac-stored-data.interface';

export const getAllUsersAPI = async (
    accessToken: string,
    organisation: string,
    organisationId: string,
    nextPage?: string
): Promise<IUser[]> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };

    const response = await axios.get<IUser[]>(
        `${CHELL_API_URL}/organisations/${organisation}/user${nextPage ? `?nextPage=${nextPage}` : ''}`,
        {headers}
    );

    return response.data;
};

export const editUserAPI = async (
    {
        accessToken,
        organisation,
        userRbac,
    }: {
        accessToken: string;
        organisation: string;
        userRbac: IRbacStoredData & {uuid: string};
    },
    organisationId: string
) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };
    const {uuid, ...data} = userRbac;
    if (!uuid) throw new Error('uuid is required');
    await axios.put(`${CHELL_API_URL}/organisations/${organisation}/user/${uuid}`, data, {headers});
};

export const getUserAPI = async (
    {
        accessToken,
        organisation,
        uuid,
    }: {
        accessToken: string;
        organisation: string;
        uuid: string;
    },
    organisationId: string
): Promise<IUser> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };
    const response = await axios.get<IUser>(`${CHELL_API_URL}/organisations/${organisation}/user/${uuid}`, {headers});
    return response.data;
};

interface IInviteUsers {
    accessToken: string;
    organisation: string;
    invites: {emailAddress: string; roles: string[]}[];
}
/**
 *
 * @param props - {
 * accessToken: string, invites : {emailAddress: string, roles: string[]}
 * }
 */
export const inviteUsers = async (props: IInviteUsers, organisationId: string) => {
    const {accessToken, organisation, invites} = props;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };
    const body = {invites};
    return await axios.post(`${CHELL_API_URL}/organisations/${organisation}/user/invite`, body, {headers});
};

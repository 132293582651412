import {IAtlassianProductTiers} from '../../../interfaces/suppliers/atlassian/atlassian-product-tiers.interface';
import {IAtlassianTiersResponse} from '../../../interfaces/suppliers/atlassian/responses/atlassian-tiers-response.interface';

const atlassianProductTiersFactory = (productTiers: IAtlassianTiersResponse): IAtlassianProductTiers => ({
    description: productTiers.products[0].productDescription,
    productKey: productTiers.products[0].productKey,
    tiers: productTiers.products[0].orderableItems.map((oi) => ({
        id: oi.orderableItemId,
        label: oi.unitLabel,
        units: oi.unitCount,
    })),
});

export default atlassianProductTiersFactory;

import {IQuoteViewActions, IQuoteViewState, QuoteViewActionTypes} from '../action-types/quote-view-types';

const initialState: IQuoteViewState = {
    allQuotes: {
        data: [],
        metaData: null,
    },
    gettingAllQuotes: false,
    gettingMoreQuotes: false,
    deletingQuote: false,
    errorDeletingQuote: false,
    errorGettingQuotes: false,
    errorMessageGettingQuotes: null,
};

const quoteViewReducer = (state = initialState, action: IQuoteViewActions): IQuoteViewState => {
    switch (action.type) {
        case QuoteViewActionTypes.GET_ALL_QUOTES_START:
            return {
                ...state,
                gettingAllQuotes: true,
            };
        case QuoteViewActionTypes.GET_MORE_QUOTES_START:
            return {
                ...state,
                gettingMoreQuotes: true,
            };
        case QuoteViewActionTypes.GET_ALL_QUOTES_DONE:
            return {
                ...state,
                gettingAllQuotes: false,
                errorGettingQuotes: false,
                errorMessageGettingQuotes: null,
                ...action.payload,
            };
        case QuoteViewActionTypes.GET_MORE_QUOTES_DONE:
            return {
                ...state,
                gettingMoreQuotes: false,
                errorGettingQuotes: false,
                errorMessageGettingQuotes: null,
                ...action.payload,
            };
        case QuoteViewActionTypes.DELETE_QUOTE_START:
            return {
                ...state,
                deletingQuote: true,
                errorDeletingQuote: false,
            };
        case QuoteViewActionTypes.DELETE_QUOTE_DONE:
            return {
                ...state,
                deletingQuote: false,
                errorDeletingQuote: action.payload.errorDeletingQuote || false,
                allQuotes: {
                    ...state.allQuotes,
                    ...action.payload.updatedAllQuotesData,
                },
            };
        default:
            return state;
    }
};

export default quoteViewReducer;

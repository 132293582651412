import axios from 'axios';
import {PARTNER_PORTAL_API, PARTNER_PORTAL_API_KEY} from '../../constants/api.constants';
import {IGetQuoteResponseAPI, IGetQuoteResponseData} from '../../interfaces/quote/responses/quote-response.interface';
import {quoteFactory} from '../../factories/quote.factory';

const getQuotes = async (
    accessToken: string,
    organisation: string,
    nextPage?: string
): Promise<IGetQuoteResponseData> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': PARTNER_PORTAL_API_KEY,
    };

    const response = await axios.get<IGetQuoteResponseAPI>(
        `${PARTNER_PORTAL_API}/organisations/${organisation}/quotes${nextPage ? `?nextPage=${nextPage}` : ''}`,
        {
            headers,
        }
    );

    return {data: response.data.data.map((d) => quoteFactory(d)), metaData: response.data.metaData};
};

export default getQuotes;

import React, {FC, useEffect, Fragment} from 'react';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {Link} from 'react-router-dom';
// COMPONENTS
import {Button} from '@adaptavist/component-library';
import {Empty, Typography} from 'antd';
import QuoteList from '../../components/view-quote/quote-list/quote-list';
import LoadingPage from '../../components/loading';
import {EmptyPageWrapper} from '../../components/structure/empty-page-wrapper';
// OTHERS - Actions, Context
import {getAllQuotesStart} from '../../redux/actions/quote-view.actions';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {useSelector, useDispatch} from 'react-redux';
import {ReduxStoreType} from '../../redux/root.reducer';
import {AppName} from '../../constants/metadata.constants';

const {Title, Text} = Typography;

const QuotesListView: FC = () => {
    document.title = `View All Quotes - ${AppName}`;
    const {allQuotes, gettingAllQuotes} = useSelector((state: ReduxStoreType) => state.quoteView);
    const callReduxAction = useDispatch();

    const {isAuthenticated} = useAuth0WithCypress();

    const {organisation} = useOrganisation();

    useEffect(() => {
        if (!isAuthenticated || !organisation) return;
        callReduxAction(getAllQuotesStart({organisation}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, organisation]);

    if (gettingAllQuotes) {
        return (
            <EmptyPageWrapper height={'70vh'}>
                <LoadingPage>
                    <h2>Getting all quotes...</h2>
                </LoadingPage>
            </EmptyPageWrapper>
        );
    }

    if ((allQuotes.data.length === 0 || !allQuotes) && !gettingAllQuotes) {
        return (
            <EmptyPageWrapper height={'70vh'}>
                <Empty
                    description={
                        <Fragment>
                            <Title level={4}>There were no quotes found.</Title>
                            <Text>You can create a new quote by clicking on the button below.</Text>
                        </Fragment>
                    }
                >
                    <Link to="/quote/new">
                        <Button ghost>Create Quote</Button>
                    </Link>
                </Empty>
            </EmptyPageWrapper>
        );
    }

    return <Fragment>{allQuotes.data && <QuoteList quotes={allQuotes.data} />}</Fragment>;
};

export default QuotesListView;

import {
    searchAtlassianMarketplaceDataCenterForAdaptavistProducts,
    searchAtlassianMarketplaceServerForAdaptavistProducts,
} from '../carts/atlassian/product-search/search-atlassian-marketplace-products';
import IProductLookup from '../../interfaces/suppliers/atlassian/product-lookup.interface';

function countMatches(needle: string, haystack: string): number {
    const haystackLower = haystack.toLowerCase();
    return needle
        .split(' ')
        .reduce((count: number, str) => (haystackLower.includes(str.toLowerCase()) ? count + 1 : count), 0);
}

const searchProducts = async (search: string) => {
    const responses = await Promise.all([
        searchAtlassianMarketplaceDataCenterForAdaptavistProducts(),
        searchAtlassianMarketplaceServerForAdaptavistProducts(),
    ]);
    return responses
        .flat(1)
        .reduce((arr: (IProductLookup & {count: number})[], p) => {
            const count = countMatches(search, p.label);
            return count > 0 ? [...arr, {...(p as IProductLookup), count}] : arr;
        }, [])
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
        .sort((a, b) => b.count - a.count);
};

export default searchProducts;

import {IOrganisationTier} from "../../../../interfaces/organisation/organisation.interface";
import {HAMLET_API_KEY, HAMLET_URL} from "../../../../constants/api.constants";
import axios from "axios";
import IAtlassianCartResponse
    from "../../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface";
import {atlassianNewCartFactory} from "../../../../factories/suppliers/atlassian/atlassian-new-cart.factory";

export interface IAddAtlassianItemToCart {
    cartId: string;
    tier: string;
    maintenanceMonths: string;
    organisationTier: IOrganisationTier | null
    mac: string;
}

export const addAtlassianItemToCart = async (payload: IAddAtlassianItemToCart) => {
    const {cartId, tier, maintenanceMonths, organisationTier, mac} = payload;
    const data = {
        cartID: cartId,
        newItems: [
            {
                orderableItemId: tier,
                maintenanceMonths: maintenanceMonths,
                renewalMonths: maintenanceMonths,
            },
        ],
    };
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };
    const response = await axios.post<IAtlassianCartResponse>(`${HAMLET_URL}/cart/add`, JSON.stringify(data), {
        headers,
    });

    return atlassianNewCartFactory(response.data, organisationTier);
};

import React from 'react';
import {Row, Col} from 'antd';
import {EmptyPageWrapper} from './empty-page-wrapper';

const colSize = {
    xs: {span: 20, offset: 2},
    sm: {span: 20, offset: 2},
    md: {span: 16, offset: 4},
    lg: {span: 16, offset: 4},
};

const panelColSize = {
    xs: {span: 20, offset: 2},
    sm: {span: 18, offset: 3},
    md: {span: 16, offset: 4},
    lg: {span: 12, offset: 6},
    xl: {span: 8, offset: 8},
    xxl: {span: 6, offset: 9},
};

export const CenterContent: React.FC<{height?: string}> = ({children, height}) => {
    return (
        <EmptyPageWrapper height={height}>
            <Row gutter={[0, 10]} style={{textAlign: 'center'}}>
                <Col
                    xs={panelColSize.xs}
                    sm={panelColSize.sm}
                    md={panelColSize.md}
                    lg={panelColSize.lg}
                    xl={panelColSize.xl}
                    xxl={panelColSize.xxl}
                >
                    {children}
                </Col>
            </Row>
        </EmptyPageWrapper>
    );
};

export const CenterCol: React.FC = ({children}) => {
    return (
        <Col xs={colSize.xs} sm={colSize.sm} md={colSize.md} lg={colSize.lg}>
            {children}
        </Col>
    );
};
export default CenterContent;

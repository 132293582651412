import {GetTokenSilentlyOptions} from '../../interfaces/authorization';
import {AuthorizationActionTypes} from '../action-types/authorization.types';

type UserOrganisation = {
    [key: string]: {
        roles: string[];
        permissions: string[];
    };
};

export const handleLogin = (userOrganisations: UserOrganisation) => ({
    type: AuthorizationActionTypes.HANDLE_LOGIN,
    payload: userOrganisations,
});

export const initialiseAccessToken = (payload: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) => ({
    type: AuthorizationActionTypes.INITIALISE_ACCESS_TOKEN,
    payload,
});

export const selectOrganisation = (organisation: string) => ({
    type: AuthorizationActionTypes.SELECT_ORGANISATION,
    payload: organisation,
});

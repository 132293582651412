export const switchKebabCaseToSpaces = (str: string) => str.replace('-', ' ');
export const switchKebabCaseToSpacesCapitalised = (str: string) =>
    str
        .split('-')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ');
export const switchSnakeCaseToSpaces = (str: string) => str.replace('_', ' ');
export const switchSnakeCaseToSpacesCapitalised = (str: string) =>
    str
        .split('-')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ');
export const switchSpacesToPascalCase = (str: string) =>
    str
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join('');
export const toCapitalised = (str: string) => str.charAt(0) + str.slice(1).toLowerCase();

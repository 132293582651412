import {put, select, takeLatest} from 'redux-saga/effects';
import {getAllUsersAPI} from '../../../api/chell/users/users';
import {IUserAPIResponse} from '../../../interfaces/rbac/user/user.interface';
import {IAuthorizationState} from '../../action-types/authorization.types';
import {IGetAllUsersStartAction, IGetMoreUsersStartAction, UserActionTypes} from '../../action-types/user.types';
import {getAllUsersActionDone, getMoreUsersActionDone} from '../../actions/user.actions';
import {ReduxStoreType} from '../../root.reducer';

export function* getAllUsers({payload}: IGetAllUsersStartAction) {
    const {organisation} = payload;
    const {authorization}: {authorization: IAuthorizationState} = yield select();
    try {
        if (!authorization.organisation) return;
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();
        const response: IUserAPIResponse = yield getAllUsersAPI(accessToken, organisation, authorization.organisation);
        yield put(
            getAllUsersActionDone({
                users: response,
                error: false,
            })
        );
    } catch (error) {
        yield put(
            getAllUsersActionDone({
                error: true,
            })
        );
    }
}

export function* onGetAllUsers() {
    yield takeLatest(UserActionTypes.GET_ALL_USERS_START, getAllUsers);
}

export function* getMoreUsers({payload}: IGetMoreUsersStartAction) {
    const {organisation} = payload;
    const {authorization}: {authorization: IAuthorizationState} = yield select();
    const {user}: ReduxStoreType = yield select();
    try {
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const {users} = user;
        const accessToken: string = yield getAccessTokenSilently();
        const response: IUserAPIResponse = yield getAllUsersAPI(accessToken, organisation, users.metaData?.nextPage);
        const newUsers = [...users.data, ...response.data];
        yield put(
            getMoreUsersActionDone({
                users: {
                    data: newUsers,
                    metaData: response.metaData,
                },
                error: false,
            })
        );
    } catch (error) {
        yield put(
            getMoreUsersActionDone({
                error: true,
            })
        );
    }
}

export function* onGetMoreUsers() {
    yield takeLatest(UserActionTypes.GET_MORE_USERS_START, getMoreUsers);
}

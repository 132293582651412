import axios from 'axios';

const getQuotePdf = async (url: string): Promise<any> => {

    const response = await axios.get<any>(url, { responseType: 'blob' });

    return response.data;
};

export default getQuotePdf;

import {ICart} from '../../interfaces/quote/cart.interface';
import {IQuote} from '../../interfaces/quote/quote.interface';
import IProductLookup from '../../interfaces/suppliers/atlassian/product-lookup.interface';
import Supplier from '../../enums/supplier';
import IPerson from '../../interfaces/contact/person.interface';
import ICompany from '../../interfaces/contact/company.interface';
import {IOrganisationTier} from '../../interfaces/organisation/organisation.interface';
import {ITrackUserAction} from '@adaptavist-commerce/analytics-provider-react';

export enum QuoteActionTypes {
    ADD_TO_CART_BY_TYPE_START = 'carts/ADD_TO_CART_START',
    ADD_TO_CART_DONE = 'carts/ADD_TO_CART_DONE',
    ADD_TO_CART_FAILED = 'carts/ADD_TO_CART_FAILED',
    REMOVE_FROM_CART = 'carts/REMOVE_FROM_CART',
    REPLACE_CART = 'carts/REPLACE_CART',

    REPLACE_QUOTE = 'quotes/REPLACE_QUOTE',
    RESET_QUOTE = 'quotes/RESET_QUOTE',
    CREATE_QUOTE = 'quotes/CREATE_QUOTE',

    ADD_ITEM_BY_UUID = 'items/ADD_ITEM_BY_UUID',
    ADD_ITEM_BY_TYPE = 'items/ADD_ITEM_BY_TYPE',
    REMOVE_ITEM = 'items/REMOVE_ITEM',
    REPLACE_ITEM = 'items/REPLACE_ITEM',

    SET_TECHNICAL_CONTACT_DETAILS = 'contact/ADD_TECHNICAL_CONTACT_DETAILS',

    GENERATE_QUOTE_START = 'GENERATE_QUOTE_START',
    GENERATE_QUOTE_UPDATE = 'GENERATE_QUOTE_UPDATE',
    GENERATE_QUOTE_DONE = 'GENERATE_QUOTE_DONE',

    SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
}

export interface IQuoteState extends IQuote {
    addingToCart: boolean;
    errorAddingToCart: boolean;
    trackingId: string;
    selectedProduct: IProductLookup | null;
    generatingQuote: boolean;
    errorGeneratingQuote: boolean;
    errorMessageGeneratingQuote: string | null | [{errorKey: string; field: string}];
    endUserContactDetails: IPerson | null;
    endUserCompanyDetails: ICompany | null;
}

export interface IAddToCartByTypeStartActionPayload {
    organisationTier: IOrganisationTier | null;
    tier: string | null;
    month: {
        value: string;
        label: string;
    };
    supplier: Supplier;
    trackUserAction?: (payload: ITrackUserAction) => void;
}

export interface IAddToCartByTypeStartAction {
    type: QuoteActionTypes.ADD_TO_CART_BY_TYPE_START;
    payload: IAddToCartByTypeStartActionPayload;
}

export interface IAddToCartDoneAction {
    type: QuoteActionTypes.ADD_TO_CART_DONE;
    payload: ICart;
}

export interface IGenerateQuoteStartPayload {
    endUserContactDetails: IPerson;
    endUserCompanyDetails: ICompany;
    organisation: string | null;
    organisationTier: IOrganisationTier | null;
}
export interface IGenerateQuoteStartAction {
    type: QuoteActionTypes.GENERATE_QUOTE_START;
    payload: IGenerateQuoteStartPayload;
}

export interface IGenerateQuoteDonePayload {
    errorGeneratingQuote?: boolean;
    errorMessageGeneratingQuote?: null | string | [{errorKey: string; field: string}];
}

export interface IGenerateQuoteDoneAction {
    type: QuoteActionTypes.GENERATE_QUOTE_DONE;
    payload: IGenerateQuoteDonePayload;
}

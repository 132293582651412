import {IItem} from '../interfaces/quote/item.interface';
import {ICart} from '../interfaces/quote/cart.interface';

export const getAllItems = (carts: ICart[]) =>
    carts.reduce(
        (arr: { cartUuid: string; item: IItem }[], c: ICart) => [
            ...arr,
            ...c.items.map((i) => ({
                cartUuid: c.uuid,
                item: i,
            })),
        ],
        []
    )
    .sort(
        (a: { cartUuid: string; item: IItem }, b: { cartUuid: string; item: IItem }) =>
            Number(a.item.createdAt < b.item.createdAt) - Number(a.item.createdAt > b.item.createdAt)
    );

export const getOneItem = (carts: ICart[]) => (cartUuid: string, itemUuid: string) =>
    carts.find((c: ICart) => c.uuid === cartUuid)?.items.find((i: IItem) => i.uuid === itemUuid);

import React, {FC, Fragment} from 'react';
import {Button, PanelBody, PanelHeader, PanelWithBorder, Rule} from "@adaptavist/component-library";
import {CenterCol} from "../../../components/structure/center-content";
import {CloseCircleOutlined, MailFilled} from "@ant-design/icons";
import {Row, Typography} from "antd";
import {Link} from "react-router-dom";

const {Paragraph, Text} = Typography;

interface InviteFailedPanelProps {
    isConfirming: boolean,
    confirmationHandler: () => void
}

const InviteFailedPanel: FC<InviteFailedPanelProps> = ({isConfirming, confirmationHandler}) =>
    <PanelWithBorder>
        <PanelHeader paddingBottom={0}>
            <CenterCol>
                <Fragment>
                    <MailFilled style={{fontSize: '50px', color: '#ff0000'}}/>
                    <h2>Confirmation Error</h2>
                </Fragment>
                <Rule/>
            </CenterCol>
        </PanelHeader>
        <br/>
        <PanelBody>
            <Row gutter={[0, 20]}>
                <CenterCol>
                    <Fragment>
                        <Paragraph>
                            <Text strong>
                                Your invitation confirmation may have failed based on the following:
                            </Text>
                        </Paragraph>
                        <Paragraph style={{textAlign: 'left'}}>
                            <CloseCircleOutlined style={{color: '#ff0000'}}/> This invitation has
                                                                              previously been confirmed.
                        </Paragraph>
                        <Paragraph style={{textAlign: 'left'}}>
                            <CloseCircleOutlined style={{color: '#ff0000'}}/> The invitation has expired.
                        </Paragraph>
                    </Fragment>
                </CenterCol>
            </Row>
            <Row gutter={[0, 20]}>
                <CenterCol>
                    <Button
                        block
                        onClick={confirmationHandler}
                        disabled={isConfirming}
                        loading={isConfirming}
                    >
                        Try Again
                    </Button>
                </CenterCol>
                <CenterCol>
                    <Link to="/">
                        <Button block ghost buttonType="secondary">
                            Cancel
                        </Button>
                    </Link>
                </CenterCol>
            </Row>
        </PanelBody>
    </PanelWithBorder>

export default InviteFailedPanel;

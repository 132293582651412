import {put, select, takeLatest} from 'redux-saga/effects';
// APIS
import saveAtlassianCartDraft from '../../../api/carts/atlassian/hamlet/save-atlassian-cart-draft';
import createQuote from '../../../api/quote-builder/create-quote';
import lockAtlassianCart from '../../../api/carts/atlassian/hamlet/lock-atlassian-cart';
// ACTIONS
import {createQuoteNextStep} from '../../actions/create-quote-controls.actions';
import {generateQuoteDone, replaceQuote} from '../../actions/quote-actions';
// OTHERS - interface / types, constants
import {IGenerateQuoteStartAction, IGenerateQuoteStartPayload, QuoteActionTypes} from '../../action-types/quote-types';
import {
    IAtlassianCart,
    ILockedAtlassianQuoteData,
} from '../../../interfaces/suppliers/atlassian/atlassian-cart.interface';
import {ATLASSIAN_BILLING_CONTACT, ATLASSIAN_PURCHASER_CONTACT} from '../../../constants/atlassian-quote.constants';
import Supplier from '../../../enums/supplier';
import {IQuote} from '../../../interfaces/quote/quote.interface';
import {ReduxStoreType} from '../../root.reducer';
import {IOrganisationTier} from '../../../interfaces/organisation/organisation.interface';

function* handleLockingAtlassianCart(
    payload: IGenerateQuoteStartPayload,
    quote: IQuote,
    cart: IAtlassianCart,
    organisationTier: IOrganisationTier | null = null
): Generator<
    Promise<IAtlassianCart | ILockedAtlassianQuoteData>,
    IAtlassianCart | null,
    IAtlassianCart & ILockedAtlassianQuoteData
> {
    if (!cart.lastCartResponse?.uuid) return null;
    const newCart = yield saveAtlassianCartDraft(
        cart,
        {
            ...ATLASSIAN_PURCHASER_CONTACT,
            ...ATLASSIAN_BILLING_CONTACT,
            technicalContactDetails: {
                email: payload.endUserContactDetails.email || '',
                firstName: payload.endUserContactDetails?.firstName || '',
                lastName: payload.endUserContactDetails?.lastName || '',
                phone: payload.endUserContactDetails?.phone || '1234',
            },
            technicalOrganisationDetails: {
                organisationName: payload.endUserCompanyDetails?.name || '',
                address1: payload.endUserCompanyDetails?.address.addressLineOne || '',
                address2: payload.endUserCompanyDetails?.address.addressLineTwo || null,
                city: payload.endUserCompanyDetails?.address.city || '',
                country: payload.endUserCompanyDetails?.address.country || '',
                isoCountryCode: payload.endUserCompanyDetails?.address.isoCountryCode || '',
                postcode: payload.endUserCompanyDetails?.address.postalCode || '',
                state: payload.endUserCompanyDetails?.address.region || '',
                taxId: payload.endUserCompanyDetails?.taxId || null,
            },
        },
        organisationTier,
        'UK'
    );
    newCart.lockedQuoteData = yield lockAtlassianCart(cart.lastCartResponse!.uuid, 'UK');
    return newCart;
}

function* lockCarts(payload: IGenerateQuoteStartPayload, quote: IQuote, organisationTier: IOrganisationTier | null) {
    const arr: IAtlassianCart[] = [];
    for (const cart of [...quote.carts]) {
        switch (cart.type) {
            case Supplier.atlassian:
                const response: IAtlassianCart = yield handleLockingAtlassianCart(
                    payload,
                    quote,
                    cart as IAtlassianCart,
                    organisationTier
                );
                arr.push(response);
                break;
            default:
                console.log('Unhandled supplier');
        }
    }

    return arr;
}

export function* generateQuote({payload}: IGenerateQuoteStartAction) {
    const {authorization, quote}: ReduxStoreType = yield select();
    const {organisation, organisationTier} = payload;
    try {
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();
        const lockedCarts: IAtlassianCart[] = yield lockCarts(payload, quote, organisationTier);
        const {
            selectedProduct,
            errorAddingToCart,
            generatingQuote,
            errorGeneratingQuote,
            errorMessageGeneratingQuote,
            addingToCart,
            ticketCode,
            trackingId,
            ...strippedQuote
        } = quote;
        lockedCarts.map((cart: {currency: any}) => delete cart.currency);
        const finalQuote: IQuote = {
            ...strippedQuote,
            trackingId: trackingId,
            carts: lockedCarts,
            endUserContactDetails: payload.endUserContactDetails,
            endUserCompanyDetails: payload.endUserCompanyDetails,
        };
        const savedQuote: IQuote = yield createQuote({
            quote: finalQuote,
            accessToken,
            organisation,
            organisationTier,
        });
        yield put(replaceQuote(savedQuote));
        yield put(generateQuoteDone({}));
        yield put(createQuoteNextStep());
    } catch (error) {
        const {i18nFieldErrors} = error.response.data;
        yield put(
            generateQuoteDone({
                errorGeneratingQuote: true,
                errorMessageGeneratingQuote: i18nFieldErrors,
            })
        );
    }
}

export function* onGenerateQuote() {
    yield takeLatest(QuoteActionTypes.GENERATE_QUOTE_START, generateQuote);
}

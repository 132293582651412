import {IAccountChangeOption} from "../../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface";
import {IAtlassianCart} from "../../../../interfaces/suppliers/atlassian/atlassian-cart.interface";
import {HAMLET_API_KEY, HAMLET_URL} from "../../../../constants/api.constants";
import axios from "axios";
import IAtlassianCartResponse
    from "../../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface";
import {atlassianNewCartFactory} from "../../../../factories/suppliers/atlassian/atlassian-new-cart.factory";
import {IOrganisationTier} from "../../../../interfaces/organisation/organisation.interface";

export interface IAddAtlassianRenewal {
    cartID: string;
    accountChangeOption: IAccountChangeOption;
    mac: string;
}

export const addAtlassianRenewal = async (payload: IAddAtlassianRenewal, organisationTier: IOrganisationTier | null = null): Promise<IAtlassianCart> => {
    const {cartID, accountChangeOption, mac} = payload;
    const data = {
        cartID,
        data: {
            accountChanges: [{accountChangeOption: accountChangeOption}],
        },
    };
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };
    const response = await axios.post<IAtlassianCartResponse>(`${HAMLET_URL}/cart/addrenewalitem`, data, {headers});

    return atlassianNewCartFactory(response.data, organisationTier);
};

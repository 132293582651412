// https://www.iso.org/obp/ui/#search
const isoCountryCodes = [
    {name: 'Andorra', alphaTwoCode: 'AD', alphaThreeCode: 'AND', numeric: '020'},
    {name: 'United Arab Emirates', alphaTwoCode: 'AE', alphaThreeCode: 'ARE', numeric: '784'},
    {name: 'Afghanistan', alphaTwoCode: 'AF', alphaThreeCode: 'AFG', numeric: '004'},
    {name: 'Antigua and Barbuda', alphaTwoCode: 'AG', alphaThreeCode: 'ATG', numeric: '028'},
    {name: 'Anguilla', alphaTwoCode: 'AI', alphaThreeCode: 'AIA', numeric: '660'},
    {name: 'Albania', alphaTwoCode: 'AL', alphaThreeCode: 'ALB', numeric: '008'},
    {name: 'Armenia', alphaTwoCode: 'AM', alphaThreeCode: 'ARM', numeric: '051'},
    {name: 'Angola', alphaTwoCode: 'AO', alphaThreeCode: 'AGO', numeric: '024'},
    {name: 'Antarctica', alphaTwoCode: 'AQ', alphaThreeCode: 'ATA', numeric: '010'},
    {name: 'Argentina', alphaTwoCode: 'AR', alphaThreeCode: 'ARG', numeric: '032'},
    {name: 'American Samoa', alphaTwoCode: 'AS', alphaThreeCode: 'ASM', numeric: '016'},
    {name: 'Austria', alphaTwoCode: 'AT', alphaThreeCode: 'AUT', numeric: '040'},
    {name: 'Australia', alphaTwoCode: 'AU', alphaThreeCode: 'AUS', numeric: '036'},
    {name: 'Aruba', alphaTwoCode: 'AW', alphaThreeCode: 'ABW', numeric: '533'},
    {name: 'Åland Islands', alphaTwoCode: 'AX', alphaThreeCode: 'ALA', numeric: '248'},
    {name: 'Azerbaijan', alphaTwoCode: 'AZ', alphaThreeCode: 'AZE', numeric: '031'},
    {name: 'Bosnia and Herzegovina', alphaTwoCode: 'BA', alphaThreeCode: 'BIH', numeric: '070'},
    {name: 'Barbados', alphaTwoCode: 'BB', alphaThreeCode: 'BRB', numeric: '052'},
    {name: 'Bangladesh', alphaTwoCode: 'BD', alphaThreeCode: 'BGD', numeric: '050'},
    {name: 'Belgium', alphaTwoCode: 'BE', alphaThreeCode: 'BEL', numeric: '056'},
    {name: 'Burkina Faso', alphaTwoCode: 'BF', alphaThreeCode: 'BFA', numeric: '854'},
    {name: 'Bulgaria', alphaTwoCode: 'BG', alphaThreeCode: 'BGR', numeric: '100'},
    {name: 'Bahrain', alphaTwoCode: 'BH', alphaThreeCode: 'BHR', numeric: '048'},
    {name: 'Burundi', alphaTwoCode: 'BI', alphaThreeCode: 'BDI', numeric: '108'},
    {name: 'Benin', alphaTwoCode: 'BJ', alphaThreeCode: 'BEN', numeric: '204'},
    {name: 'Saint Barthélemy', alphaTwoCode: 'BL', alphaThreeCode: 'BLM', numeric: '652'},
    {name: 'Bermuda', alphaTwoCode: 'BM', alphaThreeCode: 'BMU', numeric: '060'},
    {name: 'Brunei Darussalam', alphaTwoCode: 'BN', alphaThreeCode: 'BRN', numeric: '096'},
    {name: 'Bolivia (Plurinational State of)', alphaTwoCode: 'BO', alphaThreeCode: 'BOL', numeric: '068'},
    {name: 'Bonaire, Sint Eustatius and Saba', alphaTwoCode: 'BQ', alphaThreeCode: 'BES', numeric: '535'},
    {name: 'Brazil', alphaTwoCode: 'BR', alphaThreeCode: 'BRA', numeric: '076'},
    {name: 'Bahamas', alphaTwoCode: 'BS', alphaThreeCode: 'BHS', numeric: '044'},
    {name: 'Bhutan', alphaTwoCode: 'BT', alphaThreeCode: 'BTN', numeric: '064'},
    {name: 'Bouvet Island', alphaTwoCode: 'BV', alphaThreeCode: 'BVT', numeric: '074'},
    {name: 'Botswana', alphaTwoCode: 'BW', alphaThreeCode: 'BWA', numeric: '072'},
    {name: 'Belarus', alphaTwoCode: 'BY', alphaThreeCode: 'BLR', numeric: '112'},
    {name: 'Belize', alphaTwoCode: 'BZ', alphaThreeCode: 'BLZ', numeric: '084'},
    {name: 'Canada', alphaTwoCode: 'CA', alphaThreeCode: 'CAN', numeric: '124'},
    {name: 'Cocos (Keeling) Islands', alphaTwoCode: 'CC', alphaThreeCode: 'CCK', numeric: '166'},
    {name: 'Congo (the Democratic Republic of the)', alphaTwoCode: 'CD', alphaThreeCode: 'COD', numeric: '180'},
    {name: 'Central African Republic', alphaTwoCode: 'CF', alphaThreeCode: 'CAF', numeric: '140'},
    {name: 'Congo', alphaTwoCode: 'CG', alphaThreeCode: 'COG', numeric: '178'},
    {name: 'Switzerland', alphaTwoCode: 'CH', alphaThreeCode: 'CHE', numeric: '756'},
    {name: "Côte d'Ivoire", alphaTwoCode: 'CI', alphaThreeCode: 'CIV', numeric: '384'},
    {name: 'Cook Islands', alphaTwoCode: 'CK', alphaThreeCode: 'COK', numeric: '184'},
    {name: 'Chile', alphaTwoCode: 'CL', alphaThreeCode: 'CHL', numeric: '152'},
    {name: 'Cameroon', alphaTwoCode: 'CM', alphaThreeCode: 'CMR', numeric: '120'},
    {name: 'China', alphaTwoCode: 'CN', alphaThreeCode: 'CHN', numeric: '156'},
    {name: 'Colombia', alphaTwoCode: 'CO', alphaThreeCode: 'COL', numeric: '170'},
    {name: 'Costa Rica', alphaTwoCode: 'CR', alphaThreeCode: 'CRI', numeric: '188'},
    {name: 'Cuba', alphaTwoCode: 'CU', alphaThreeCode: 'CUB', numeric: '192'},
    {name: 'Cabo Verde', alphaTwoCode: 'CV', alphaThreeCode: 'CPV', numeric: '132'},
    {name: 'Curaçao', alphaTwoCode: 'CW', alphaThreeCode: 'CUW', numeric: '531'},
    {name: 'Christmas Island', alphaTwoCode: 'CX', alphaThreeCode: 'CXR', numeric: '162'},
    {name: 'Cyprus', alphaTwoCode: 'CY', alphaThreeCode: 'CYP', numeric: '196'},
    {name: 'Czechia', alphaTwoCode: 'CZ', alphaThreeCode: 'CZE', numeric: '203'},
    {name: 'Germany', alphaTwoCode: 'DE', alphaThreeCode: 'DEU', numeric: '276'},
    {name: 'Djibouti', alphaTwoCode: 'DJ', alphaThreeCode: 'DJI', numeric: '262'},
    {name: 'Denmark', alphaTwoCode: 'DK', alphaThreeCode: 'DNK', numeric: '208'},
    {name: 'Dominica', alphaTwoCode: 'DM', alphaThreeCode: 'DMA', numeric: '212'},
    {name: 'Dominican Republic', alphaTwoCode: 'DO', alphaThreeCode: 'DOM', numeric: '214'},
    {name: 'Algeria', alphaTwoCode: 'DZ', alphaThreeCode: 'DZA', numeric: '012'},
    {name: 'Ecuador', alphaTwoCode: 'EC', alphaThreeCode: 'ECU', numeric: '218'},
    {name: 'Estonia', alphaTwoCode: 'EE', alphaThreeCode: 'EST', numeric: '233'},
    {name: 'Egypt', alphaTwoCode: 'EG', alphaThreeCode: 'EGY', numeric: '818'},
    {name: 'Western Sahara*', alphaTwoCode: 'EH', alphaThreeCode: 'ESH', numeric: '732'},
    {name: 'Eritrea', alphaTwoCode: 'ER', alphaThreeCode: 'ERI', numeric: '232'},
    {name: 'Spain', alphaTwoCode: 'ES', alphaThreeCode: 'ESP', numeric: '724'},
    {name: 'Ethiopia', alphaTwoCode: 'ET', alphaThreeCode: 'ETH', numeric: '231'},
    {name: 'Finland', alphaTwoCode: 'FI', alphaThreeCode: 'FIN', numeric: '246'},
    {name: 'Fiji', alphaTwoCode: 'FJ', alphaThreeCode: 'FJI', numeric: '242'},
    {name: 'Falkland Islands [Malvinas]', alphaTwoCode: 'FK', alphaThreeCode: 'FLK', numeric: '238'},
    {name: 'Micronesia (Federated States of)', alphaTwoCode: 'FM', alphaThreeCode: 'FSM', numeric: '583'},
    {name: 'Faroe Islands', alphaTwoCode: 'FO', alphaThreeCode: 'FRO', numeric: '234'},
    {name: 'France', alphaTwoCode: 'FR', alphaThreeCode: 'FRA', numeric: '250'},
    {name: 'Gabon', alphaTwoCode: 'GA', alphaThreeCode: 'GAB', numeric: '266'},
    {
        name: 'United Kingdom',
        alphaTwoCode: 'GB',
        alphaThreeCode: 'GBR',
        numeric: '826',
    },
    {name: 'Grenada', alphaTwoCode: 'GD', alphaThreeCode: 'GRD', numeric: '308'},
    {name: 'Georgia', alphaTwoCode: 'GE', alphaThreeCode: 'GEO', numeric: '268'},
    {name: 'French Guiana', alphaTwoCode: 'GF', alphaThreeCode: 'GUF', numeric: '254'},
    {name: 'Guernsey', alphaTwoCode: 'GG', alphaThreeCode: 'GGY', numeric: '831'},
    {name: 'Ghana', alphaTwoCode: 'GH', alphaThreeCode: 'GHA', numeric: '288'},
    {name: 'Gibraltar', alphaTwoCode: 'GI', alphaThreeCode: 'GIB', numeric: '292'},
    {name: 'Greenland', alphaTwoCode: 'GL', alphaThreeCode: 'GRL', numeric: '304'},
    {name: 'Gambia', alphaTwoCode: 'GM', alphaThreeCode: 'GMB', numeric: '270'},
    {name: 'Guinea', alphaTwoCode: 'GN', alphaThreeCode: 'GIN', numeric: '324'},
    {name: 'Guadeloupe', alphaTwoCode: 'GP', alphaThreeCode: 'GLP', numeric: '312'},
    {name: 'Equatorial Guinea', alphaTwoCode: 'GQ', alphaThreeCode: 'GNQ', numeric: '226'},
    {name: 'Greece', alphaTwoCode: 'GR', alphaThreeCode: 'GRC', numeric: '300'},
    {name: 'South Georgia and the South Sandwich Islands', alphaTwoCode: 'GS', alphaThreeCode: 'SGS', numeric: '239'},
    {name: 'Guatemala', alphaTwoCode: 'GT', alphaThreeCode: 'GTM', numeric: '320'},
    {name: 'Guam', alphaTwoCode: 'GU', alphaThreeCode: 'GUM', numeric: '316'},
    {name: 'Guinea-Bissau', alphaTwoCode: 'GW', alphaThreeCode: 'GNB', numeric: '624'},
    {name: 'Guyana', alphaTwoCode: 'GY', alphaThreeCode: 'GUY', numeric: '328'},
    {name: 'Hong Kong', alphaTwoCode: 'HK', alphaThreeCode: 'HKG', numeric: '344'},
    {name: 'Heard Island and McDonald Islands', alphaTwoCode: 'HM', alphaThreeCode: 'HMD', numeric: '334'},
    {name: 'Honduras', alphaTwoCode: 'HN', alphaThreeCode: 'HND', numeric: '340'},
    {name: 'Croatia', alphaTwoCode: 'HR', alphaThreeCode: 'HRV', numeric: '191'},
    {name: 'Haiti', alphaTwoCode: 'HT', alphaThreeCode: 'HTI', numeric: '332'},
    {name: 'Hungary', alphaTwoCode: 'HU', alphaThreeCode: 'HUN', numeric: '348'},
    {name: 'Indonesia', alphaTwoCode: 'ID', alphaThreeCode: 'IDN', numeric: '360'},
    {name: 'Ireland', alphaTwoCode: 'IE', alphaThreeCode: 'IRL', numeric: '372'},
    {name: 'Israel', alphaTwoCode: 'IL', alphaThreeCode: 'ISR', numeric: '376'},
    {name: 'Isle of Man', alphaTwoCode: 'IM', alphaThreeCode: 'IMN', numeric: '833'},
    {name: 'India', alphaTwoCode: 'IN', alphaThreeCode: 'IND', numeric: '356'},
    {name: 'British Indian Ocean Territory', alphaTwoCode: 'IO', alphaThreeCode: 'IOT', numeric: '086'},
    {name: 'Iraq', alphaTwoCode: 'IQ', alphaThreeCode: 'IRQ', numeric: '368'},
    {name: 'Iran (Islamic Republic of)', alphaTwoCode: 'IR', alphaThreeCode: 'IRN', numeric: '364'},
    {name: 'Iceland', alphaTwoCode: 'IS', alphaThreeCode: 'ISL', numeric: '352'},
    {name: 'Italy', alphaTwoCode: 'IT', alphaThreeCode: 'ITA', numeric: '380'},
    {name: 'Jersey', alphaTwoCode: 'JE', alphaThreeCode: 'JEY', numeric: '832'},
    {name: 'Jamaica', alphaTwoCode: 'JM', alphaThreeCode: 'JAM', numeric: '388'},
    {name: 'Jordan', alphaTwoCode: 'JO', alphaThreeCode: 'JOR', numeric: '400'},
    {name: 'Japan', alphaTwoCode: 'JP', alphaThreeCode: 'JPN', numeric: '392'},
    {name: 'Kenya', alphaTwoCode: 'KE', alphaThreeCode: 'KEN', numeric: '404'},
    {name: 'Kyrgyzstan', alphaTwoCode: 'KG', alphaThreeCode: 'KGZ', numeric: '417'},
    {name: 'Cambodia', alphaTwoCode: 'KH', alphaThreeCode: 'KHM', numeric: '116'},
    {name: 'Kiribati', alphaTwoCode: 'KI', alphaThreeCode: 'KIR', numeric: '296'},
    {name: 'Comoros', alphaTwoCode: 'KM', alphaThreeCode: 'COM', numeric: '174'},
    {name: 'Saint Kitts and Nevis', alphaTwoCode: 'KN', alphaThreeCode: 'KNA', numeric: '659'},
    {name: "Korea (the Democratic People's Republic of)", alphaTwoCode: 'KP', alphaThreeCode: 'PRK', numeric: '408'},
    {name: 'Korea (the Republic of)', alphaTwoCode: 'KR', alphaThreeCode: 'KOR', numeric: '410'},
    {name: 'Kuwait', alphaTwoCode: 'KW', alphaThreeCode: 'KWT', numeric: '414'},
    {name: 'Cayman Islands', alphaTwoCode: 'KY', alphaThreeCode: 'CYM', numeric: '136'},
    {name: 'Kazakhstan', alphaTwoCode: 'KZ', alphaThreeCode: 'KAZ', numeric: '398'},
    {name: "Lao People's Democratic Republic", alphaTwoCode: 'LA', alphaThreeCode: 'LAO', numeric: '418'},
    {name: 'Lebanon', alphaTwoCode: 'LB', alphaThreeCode: 'LBN', numeric: '422'},
    {name: 'Saint Lucia', alphaTwoCode: 'LC', alphaThreeCode: 'LCA', numeric: '662'},
    {name: 'Liechtenstein', alphaTwoCode: 'LI', alphaThreeCode: 'LIE', numeric: '438'},
    {name: 'Sri Lanka', alphaTwoCode: 'LK', alphaThreeCode: 'LKA', numeric: '144'},
    {name: 'Liberia', alphaTwoCode: 'LR', alphaThreeCode: 'LBR', numeric: '430'},
    {name: 'Lesotho', alphaTwoCode: 'LS', alphaThreeCode: 'LSO', numeric: '426'},
    {name: 'Lithuania', alphaTwoCode: 'LT', alphaThreeCode: 'LTU', numeric: '440'},
    {name: 'Luxembourg', alphaTwoCode: 'LU', alphaThreeCode: 'LUX', numeric: '442'},
    {name: 'Latvia', alphaTwoCode: 'LV', alphaThreeCode: 'LVA', numeric: '428'},
    {name: 'Libya', alphaTwoCode: 'LY', alphaThreeCode: 'LBY', numeric: '434'},
    {name: 'Morocco', alphaTwoCode: 'MA', alphaThreeCode: 'MAR', numeric: '504'},
    {name: 'Monaco', alphaTwoCode: 'MC', alphaThreeCode: 'MCO', numeric: '492'},
    {name: 'Moldova (the Republic of)', alphaTwoCode: 'MD', alphaThreeCode: 'MDA', numeric: '498'},
    {name: 'Montenegro', alphaTwoCode: 'ME', alphaThreeCode: 'MNE', numeric: '499'},
    {name: 'Saint Martin (French part)', alphaTwoCode: 'MF', alphaThreeCode: 'MAF', numeric: '663'},
    {name: 'Madagascar', alphaTwoCode: 'MG', alphaThreeCode: 'MDG', numeric: '450'},
    {name: 'Marshall Islands', alphaTwoCode: 'MH', alphaThreeCode: 'MHL', numeric: '584'},
    {name: 'North Macedonia', alphaTwoCode: 'MK', alphaThreeCode: 'MKD', numeric: '807'},
    {name: 'Mali', alphaTwoCode: 'ML', alphaThreeCode: 'MLI', numeric: '466'},
    {name: 'Myanmar', alphaTwoCode: 'MM', alphaThreeCode: 'MMR', numeric: '104'},
    {name: 'Mongolia', alphaTwoCode: 'MN', alphaThreeCode: 'MNG', numeric: '496'},
    {name: 'Macao', alphaTwoCode: 'MO', alphaThreeCode: 'MAC', numeric: '446'},
    {name: 'Northern Mariana Islands', alphaTwoCode: 'MP', alphaThreeCode: 'MNP', numeric: '580'},
    {name: 'Martinique', alphaTwoCode: 'MQ', alphaThreeCode: 'MTQ', numeric: '474'},
    {name: 'Mauritania', alphaTwoCode: 'MR', alphaThreeCode: 'MRT', numeric: '478'},
    {name: 'Montserrat', alphaTwoCode: 'MS', alphaThreeCode: 'MSR', numeric: '500'},
    {name: 'Malta', alphaTwoCode: 'MT', alphaThreeCode: 'MLT', numeric: '470'},
    {name: 'Mauritius', alphaTwoCode: 'MU', alphaThreeCode: 'MUS', numeric: '480'},
    {name: 'Maldives', alphaTwoCode: 'MV', alphaThreeCode: 'MDV', numeric: '462'},
    {name: 'Malawi', alphaTwoCode: 'MW', alphaThreeCode: 'MWI', numeric: '454'},
    {name: 'Mexico', alphaTwoCode: 'MX', alphaThreeCode: 'MEX', numeric: '484'},
    {name: 'Malaysia', alphaTwoCode: 'MY', alphaThreeCode: 'MYS', numeric: '458'},
    {name: 'Mozambique', alphaTwoCode: 'MZ', alphaThreeCode: 'MOZ', numeric: '508'},
    {name: 'Namibia', alphaTwoCode: 'NA', alphaThreeCode: 'NAM', numeric: '516'},
    {name: 'New Caledonia', alphaTwoCode: 'NC', alphaThreeCode: 'NCL', numeric: '540'},
    {name: 'Niger', alphaTwoCode: 'NE', alphaThreeCode: 'NER', numeric: '562'},
    {name: 'Norfolk Island', alphaTwoCode: 'NF', alphaThreeCode: 'NFK', numeric: '574'},
    {name: 'Nigeria', alphaTwoCode: 'NG', alphaThreeCode: 'NGA', numeric: '566'},
    {name: 'Nicaragua', alphaTwoCode: 'NI', alphaThreeCode: 'NIC', numeric: '558'},
    {name: 'Netherlands', alphaTwoCode: 'NL', alphaThreeCode: 'NLD', numeric: '528'},
    {name: 'Norway', alphaTwoCode: 'NO', alphaThreeCode: 'NOR', numeric: '578'},
    {name: 'Nepal', alphaTwoCode: 'NP', alphaThreeCode: 'NPL', numeric: '524'},
    {name: 'Nauru', alphaTwoCode: 'NR', alphaThreeCode: 'NRU', numeric: '520'},
    {name: 'Niue', alphaTwoCode: 'NU', alphaThreeCode: 'NIU', numeric: '570'},
    {name: 'New Zealand', alphaTwoCode: 'NZ', alphaThreeCode: 'NZL', numeric: '554'},
    {name: 'Oman', alphaTwoCode: 'OM', alphaThreeCode: 'OMN', numeric: '512'},
    {name: 'Panama', alphaTwoCode: 'PA', alphaThreeCode: 'PAN', numeric: '591'},
    {name: 'Peru', alphaTwoCode: 'PE', alphaThreeCode: 'PER', numeric: '604'},
    {name: 'French Polynesia', alphaTwoCode: 'PF', alphaThreeCode: 'PYF', numeric: '258'},
    {name: 'Papua New Guinea', alphaTwoCode: 'PG', alphaThreeCode: 'PNG', numeric: '598'},
    {name: 'Philippines', alphaTwoCode: 'PH', alphaThreeCode: 'PHL', numeric: '608'},
    {name: 'Pakistan', alphaTwoCode: 'PK', alphaThreeCode: 'PAK', numeric: '586'},
    {name: 'Poland', alphaTwoCode: 'PL', alphaThreeCode: 'POL', numeric: '616'},
    {name: 'Saint Pierre and Miquelon', alphaTwoCode: 'PM', alphaThreeCode: 'SPM', numeric: '666'},
    {name: 'Pitcairn', alphaTwoCode: 'PN', alphaThreeCode: 'PCN', numeric: '612'},
    {name: 'Puerto Rico', alphaTwoCode: 'PR', alphaThreeCode: 'PRI', numeric: '630'},
    {name: 'Palestine, State of', alphaTwoCode: 'PS', alphaThreeCode: 'PSE', numeric: '275'},
    {name: 'Portugal', alphaTwoCode: 'PT', alphaThreeCode: 'PRT', numeric: '620'},
    {name: 'Palau', alphaTwoCode: 'PW', alphaThreeCode: 'PLW', numeric: '585'},
    {name: 'Paraguay', alphaTwoCode: 'PY', alphaThreeCode: 'PRY', numeric: '600'},
    {name: 'Qatar', alphaTwoCode: 'QA', alphaThreeCode: 'QAT', numeric: '634'},
    {name: 'Réunion', alphaTwoCode: 'RE', alphaThreeCode: 'REU', numeric: '638'},
    {name: 'Romania', alphaTwoCode: 'RO', alphaThreeCode: 'ROU', numeric: '642'},
    {name: 'Serbia', alphaTwoCode: 'RS', alphaThreeCode: 'SRB', numeric: '688'},
    {name: 'Russian Federation', alphaTwoCode: 'RU', alphaThreeCode: 'RUS', numeric: '643'},
    {name: 'Rwanda', alphaTwoCode: 'RW', alphaThreeCode: 'RWA', numeric: '646'},
    {name: 'Saudi Arabia', alphaTwoCode: 'SA', alphaThreeCode: 'SAU', numeric: '682'},
    {name: 'Solomon Islands', alphaTwoCode: 'SB', alphaThreeCode: 'SLB', numeric: '090'},
    {name: 'Seychelles', alphaTwoCode: 'SC', alphaThreeCode: 'SYC', numeric: '690'},
    {name: 'Sudan', alphaTwoCode: 'SD', alphaThreeCode: 'SDN', numeric: '729'},
    {name: 'Sweden', alphaTwoCode: 'SE', alphaThreeCode: 'SWE', numeric: '752'},
    {name: 'Singapore', alphaTwoCode: 'SG', alphaThreeCode: 'SGP', numeric: '702'},
    {name: 'Saint Helena, Ascension and Tristan da Cunha', alphaTwoCode: 'SH', alphaThreeCode: 'SHN', numeric: '654'},
    {name: 'Slovenia', alphaTwoCode: 'SI', alphaThreeCode: 'SVN', numeric: '705'},
    {name: 'Svalbard and Jan Mayen', alphaTwoCode: 'SJ', alphaThreeCode: 'SJM', numeric: '744'},
    {name: 'Slovakia', alphaTwoCode: 'SK', alphaThreeCode: 'SVK', numeric: '703'},
    {name: 'Sierra Leone', alphaTwoCode: 'SL', alphaThreeCode: 'SLE', numeric: '694'},
    {name: 'San Marino', alphaTwoCode: 'SM', alphaThreeCode: 'SMR', numeric: '674'},
    {name: 'Senegal', alphaTwoCode: 'SN', alphaThreeCode: 'SEN', numeric: '686'},
    {name: 'Somalia', alphaTwoCode: 'SO', alphaThreeCode: 'SOM', numeric: '706'},
    {name: 'Suriname', alphaTwoCode: 'SR', alphaThreeCode: 'SUR', numeric: '740'},
    {name: 'South Sudan', alphaTwoCode: 'SS', alphaThreeCode: 'SSD', numeric: '728'},
    {name: 'Sao Tome and Principe', alphaTwoCode: 'ST', alphaThreeCode: 'STP', numeric: '678'},
    {name: 'El Salvador', alphaTwoCode: 'SV', alphaThreeCode: 'SLV', numeric: '222'},
    {name: 'Sint Maarten (Dutch part)', alphaTwoCode: 'SX', alphaThreeCode: 'SXM', numeric: '534'},
    {name: 'Syrian Arab Republic', alphaTwoCode: 'SY', alphaThreeCode: 'SYR', numeric: '760'},
    {name: 'Eswatini', alphaTwoCode: 'SZ', alphaThreeCode: 'SWZ', numeric: '748'},
    {name: 'Turks and Caicos Islands', alphaTwoCode: 'TC', alphaThreeCode: 'TCA', numeric: '796'},
    {name: 'Chad', alphaTwoCode: 'TD', alphaThreeCode: 'TCD', numeric: '148'},
    {name: 'French Southern Territories', alphaTwoCode: 'TF', alphaThreeCode: 'ATF', numeric: '260'},
    {name: 'Togo', alphaTwoCode: 'TG', alphaThreeCode: 'TGO', numeric: '768'},
    {name: 'Thailand', alphaTwoCode: 'TH', alphaThreeCode: 'THA', numeric: '764'},
    {name: 'Tajikistan', alphaTwoCode: 'TJ', alphaThreeCode: 'TJK', numeric: '762'},
    {name: 'Tokelau', alphaTwoCode: 'TK', alphaThreeCode: 'TKL', numeric: '772'},
    {name: 'Timor-Leste', alphaTwoCode: 'TL', alphaThreeCode: 'TLS', numeric: '626'},
    {name: 'Turkmenistan', alphaTwoCode: 'TM', alphaThreeCode: 'TKM', numeric: '795'},
    {name: 'Tunisia', alphaTwoCode: 'TN', alphaThreeCode: 'TUN', numeric: '788'},
    {name: 'Tonga', alphaTwoCode: 'TO', alphaThreeCode: 'TON', numeric: '776'},
    {name: 'Turkey', alphaTwoCode: 'TR', alphaThreeCode: 'TUR', numeric: '792'},
    {name: 'Trinidad and Tobago', alphaTwoCode: 'TT', alphaThreeCode: 'TTO', numeric: '780'},
    {name: 'Tuvalu', alphaTwoCode: 'TV', alphaThreeCode: 'TUV', numeric: '798'},
    {name: 'Taiwan (Province of China)', alphaTwoCode: 'TW', alphaThreeCode: 'TWN', numeric: '158'},
    {name: 'Tanzania, the United Republic of', alphaTwoCode: 'TZ', alphaThreeCode: 'TZA', numeric: '834'},
    {name: 'Ukraine', alphaTwoCode: 'UA', alphaThreeCode: 'UKR', numeric: '804'},
    {name: 'Uganda', alphaTwoCode: 'UG', alphaThreeCode: 'UGA', numeric: '800'},
    {name: 'United States Minor Outlying Islands', alphaTwoCode: 'UM', alphaThreeCode: 'UMI', numeric: '581'},
    {name: 'United States of America', alphaTwoCode: 'US', alphaThreeCode: 'USA', numeric: '840'},
    {name: 'Uruguay', alphaTwoCode: 'UY', alphaThreeCode: 'URY', numeric: '858'},
    {name: 'Uzbekistan', alphaTwoCode: 'UZ', alphaThreeCode: 'UZB', numeric: '860'},
    {name: 'Holy See', alphaTwoCode: 'VA', alphaThreeCode: 'VAT', numeric: '336'},
    {name: 'Saint Vincent and the Grenadines', alphaTwoCode: 'VC', alphaThreeCode: 'VCT', numeric: '670'},
    {name: 'Venezuela (Bolivarian Republic of)', alphaTwoCode: 'VE', alphaThreeCode: 'VEN', numeric: '862'},
    {name: 'Virgin Islands (British)', alphaTwoCode: 'VG', alphaThreeCode: 'VGB', numeric: '092'},
    {name: 'Virgin Islands (U.S.)', alphaTwoCode: 'VI', alphaThreeCode: 'VIR', numeric: '850'},
    {name: 'Viet Nam', alphaTwoCode: 'VN', alphaThreeCode: 'VNM', numeric: '704'},
    {name: 'Vanuatu', alphaTwoCode: 'VU', alphaThreeCode: 'VUT', numeric: '548'},
    {name: 'Wallis and Futuna', alphaTwoCode: 'WF', alphaThreeCode: 'WLF', numeric: '876'},
    {name: 'Samoa', alphaTwoCode: 'WS', alphaThreeCode: 'WSM', numeric: '882'},
    {name: 'Yemen', alphaTwoCode: 'YE', alphaThreeCode: 'YEM', numeric: '887'},
    {name: 'Mayotte', alphaTwoCode: 'YT', alphaThreeCode: 'MYT', numeric: '175'},
    {name: 'South Africa', alphaTwoCode: 'ZA', alphaThreeCode: 'ZAF', numeric: '710'},
    {name: 'Zambia', alphaTwoCode: 'ZM', alphaThreeCode: 'ZMB', numeric: '894'},
    {name: 'Zimbabwe', alphaTwoCode: 'ZW', alphaThreeCode: 'ZWE', numeric: '716'},
];

export default isoCountryCodes;

import axios from 'axios';
import {CHELL_API_URL, CHELL_API_KEY} from '../../../constants/api.constants';
import {IOrganisation} from '@adaptavist-commerce/organisation-context-react';

const getOneOrganisation = async (
    accessToken: string,
    organisation: string,
    organisationId: string
): Promise<IOrganisation> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };

    const response = await axios.get<IOrganisation>(`${CHELL_API_URL}/organisations/${organisation}`, {headers});

    return response.data;
};

export default getOneOrganisation;

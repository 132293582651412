import React from 'react';
import {useSelector} from 'react-redux';
// COMPONENTS
import {SectionTitle, Table, TableHeader} from '@adaptavist/component-library';
import {Col} from 'antd';
import ItemRowSwitch from './item-rows/item-row-switch';
// INTERFACE / TYPES
import {IItem} from '../../interfaces/quote/item.interface';
import {IQuote} from '../../interfaces/quote/quote.interface';
// ACTIONS
import {getAllItems} from '../../actions/item-actions';
import {QuoteItemTableHeaderTitle} from '../../data/table-headers';

const colSize = {
    span: 24,
};

const ItemList = () => {
    const {quote} = useSelector((state: {quote: IQuote}) => ({quote: state.quote}));
    const items = getAllItems(quote.carts);

    if (items.length === 0) {
        return null;
    }
    return (
        <Col span={colSize.span} data-cy="quote-list">
            <SectionTitle>Quote</SectionTitle>
            <Table striped={true}>
                <thead>
                    <tr>
                        {QuoteItemTableHeaderTitle.map((header, index) => (
                            <TableHeader key={`${header.title}-${index}`} align={header.align}>
                                {header.title}
                            </TableHeader>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {items.map(({cartUuid, item}: {cartUuid: string; item: IItem}) => (
                        <ItemRowSwitch cartUuid={cartUuid} trackingId={quote.trackingId} item={item} key={item.uuid} />
                    ))}
                </tbody>
            </Table>
        </Col>
    );
};

export default ItemList;

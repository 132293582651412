import IRole from '../../interfaces/rbac/role/role.interface';

export const RolesActionTypes = {
    GET_ROLES_START: 'GET_ROLES_START',
    GET_ROLES_DONE: 'GET_ROLES_DONE',
};

export interface IRolesState {
    data: IRole[] | null;
    gettingRoles: false,
}

export interface IGetRolesStartAction {
    type: typeof RolesActionTypes.GET_ROLES_START;
}

export interface IGetRolesDoneActionPayload {
    data: IRole[] | null;
}

export interface IGetRolesDoneAction {
    type: typeof RolesActionTypes.GET_ROLES_DONE;
    payload: IGetRolesDoneActionPayload;
}

import React from 'react';
import AtlassianSelector from './suppliers/atlassian-selector';

import {useSelector} from 'react-redux';
import {ReduxStoreType} from '../../../redux/root.reducer';

const ProductSelectorSwitch = () => {
    const quoteReduxState = useSelector((state: ReduxStoreType) => state.quote);
    const {selectedProduct} = quoteReduxState;
    if (!selectedProduct) return null;
    switch (selectedProduct!.type) {
        case 'atlassian':
            return <AtlassianSelector />;
        default:
            return <div>Not Handled: {selectedProduct!.type}</div>;
    }
};

export default ProductSelectorSwitch;

import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {useSegment, ITrackUserAction} from '@adaptavist-commerce/analytics-provider-react';

export interface IAnalyticLinkProps {
    userAction: ITrackUserAction;
    route: string;
}

const AnalyticLink: FC<IAnalyticLinkProps> = (props) => {
    const {analytics, isAnalyticsInitialized} = useSegment();
    const {userAction, children, route} = props;
    const executeAnalytics = () => {
        if (analytics && isAnalyticsInitialized && userAction) {
            analytics.trackUserAction(userAction);
        }
    };
    return (
        <Link to={`${route}`} onClick={executeAnalytics}>
            {children}
        </Link>
    );
};

export default AnalyticLink;

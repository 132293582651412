import IFlatContactDetails from "../interfaces/contact/flat-contact-details";
import IPerson from "../interfaces/contact/person.interface";
import ICompany from "../interfaces/contact/company.interface";
import IAddress from "../interfaces/contact/address.interface";
import isoCountryCodes from "../data/iso-country-codes";

export const flatContactDetailsToPersonTransformer = (flatContactDetails: IFlatContactDetails): IPerson => {
    return {
        email: flatContactDetails.email,
        firstName: flatContactDetails.firstName,
        lastName: flatContactDetails.lastName,
        phone: flatContactDetails.phone
    }
}

export const flatContactDetailsToCompanyTransformer = (flatContactDetails: IFlatContactDetails): ICompany => {
    return {
        name: flatContactDetails.companyName,
        taxId: flatContactDetails.taxId,
        address: flatContactDetailsToAddressTransformer(flatContactDetails),
    }
}

export const flatContactDetailsToAddressTransformer = (flatContactDetails: IFlatContactDetails): IAddress => {
    if(!flatContactDetails.country) {
        flatContactDetails.country = isoCountryCodes.find(icc => icc.alphaTwoCode === flatContactDetails.isoCountryCode)?.name;
        if(!flatContactDetails.country) throw new Error('Invalid isoCountryCode');
    }
    return {
        addressLineOne: flatContactDetails.addressLineOne,
        addressLineTwo: flatContactDetails.addressLineTwo,
        city: flatContactDetails.city,
        country: flatContactDetails.country,
        isoCountryCode: flatContactDetails.isoCountryCode,
        ...(flatContactDetails.region && {region: flatContactDetails.region}),
        ...(flatContactDetails.postalCode && {postalCode: flatContactDetails.postalCode}),
        ...(flatContactDetails.cityArea && {cityArea: flatContactDetails.cityArea}),
        ...(flatContactDetails.sortingCode && {sortingCode: flatContactDetails.sortingCode})
    }
}


import axios from 'axios';
import {PARTNER_PORTAL_API, PARTNER_PORTAL_API_KEY} from '../../constants/api.constants';
import {IQuote} from '../../interfaces/quote/quote.interface';

const getQuoteUrl = async (ticketCode: string, accessToken: string, organisation: string): Promise<IQuote> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': PARTNER_PORTAL_API_KEY,
    };

    const response = await axios.get<IQuote>(
        `${PARTNER_PORTAL_API}/organisations/${organisation}/quote-url/${ticketCode}`,
        {
            headers,
        }
    );

    return response.data;
};

export default getQuoteUrl;

import {IQuote} from '../interfaces/quote/quote.interface';
import {cartFactory} from './cart.factory';
import {personFactory} from './person.factory';
import {companyFactory} from './companyFactory';
import {IQuoteResponse} from '../interfaces/quote/responses/quote-response.interface';
import {calculateTotalPrice} from '../utilities/quote-functions';
import {IOrganisationTier} from '../interfaces/organisation/organisation.interface';

export const quoteFactory = (quote: IQuoteResponse, organisationTier: IOrganisationTier | null = null): IQuote => {
    const endUserContactDetails = quote.endUserContactDetails && personFactory(quote.endUserContactDetails!);
    const endUserCompanyDetails = quote.endUserCompanyDetails && companyFactory(quote.endUserCompanyDetails!);
    const carts = quote.carts.length ? quote.carts.map((c) => cartFactory(c, organisationTier)) : [];
    const totalPrice = carts.reduce((acc, i) => (acc += calculateTotalPrice(i.items).total), 0);
    return {
        uuid: quote.uuid,
        organisation: quote.organisation,
        user: quote.user,
        ticketCode: quote.ticketCode,
        endUserContactDetails: endUserContactDetails || null,
        endUserCompanyDetails: endUserCompanyDetails || null,
        createdAt: new Date(quote.createdAt.split('_')[0]),
        createdAtKey: quote.createdAt,
        updatedAt: new Date(quote.updatedAt).toString(),
        status: quote.status || 'PENDING',
        totalPrice,
        carts,
        s3Url: quote.s3Url,
        trackingId: quote.trackingId!,
    };
};

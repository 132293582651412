import React from 'react';
import {Result, Typography} from 'antd';
import {Button} from '@adaptavist/component-library';
import {Link} from 'react-router-dom';
import {FrownOutlined} from '@ant-design/icons';
import * as Sentry from '@sentry/browser';
import CenterContent from '../structure/center-content';

const {Paragraph, Text} = Typography;

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    static componentDidCatch(error: any, errorInfo: any) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.hasError) {
            //  Render Fallback UI if error is true
            return (
                <CenterContent height={'100vh'}>
                    <Result
                        icon={<FrownOutlined />}
                        title="Something Went Wrong"
                        subTitle={
                            <div
                                style={{
                                    marginTop: '24px',
                                    padding: '24px 40px',
                                    backgroundColor: '#fafafa',
                                }}
                            >
                                <Paragraph>
                                    <Text>
                                        A technical error has occurred. Our Engineers have been notified and are working
                                        to rectify it.
                                    </Text>
                                </Paragraph>
                            </div>
                        }
                        extra={
                            <Link to="/">
                                <Button block ghost>
                                    Go back home
                                </Button>
                            </Link>
                        }
                    />
                </CenterContent>
            );
        }
        //     Render components when error not true
        return this.props.children;
    }
}

export default ErrorBoundary;

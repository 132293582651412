import axios from 'axios';
import IRole from '../../../interfaces/rbac/role/role.interface';
import {CHELL_API_URL, CHELL_API_KEY} from '../../../constants/api.constants';

export const getRolesAPI = async (accessToken: string, organisationId: string): Promise<IRole[]> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };
    const response = await axios.get<IRole[]>(`${CHELL_API_URL}/roles`, {headers});
    return response.data;
};

import React from 'react';
import {Row, Col} from 'antd';
import AppHeader from './app-header';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';

const ContentWrapper: React.FC = ({children}) => {
    const {isAuthenticated} = useAuth0WithCypress();
    return (
        <Row>
            {isAuthenticated && <AppHeader />}
            <Col offset={1} span={22} style={{marginBottom: '50px'}}>
                {children}
            </Col>
        </Row>
    );
};
export default ContentWrapper;

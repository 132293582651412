import React, {FC, useState} from 'react';
import removeAtlassianItem from '../../../../api/carts/atlassian/hamlet/remove-atlassian-item';
import {atlassianNewCartFactory} from '../../../../factories/suppliers/atlassian/atlassian-new-cart.factory';
import {useDispatch} from 'react-redux';
import {replaceCart} from '../../../../redux/actions/quote-actions';
import {IAtlassianItem} from '../../../../interfaces/suppliers/atlassian/atlassian-item.interface';
import {Button, TableCell} from '@adaptavist/component-library';
import {LoadingOutlined} from '@ant-design/icons/lib';
import {Spin, notification} from 'antd';
import {toCapitalised} from '../../../../utilities/switch-case';
import {formatPrice} from '../../../../utilities/currency-format';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';

export interface IAtlassianItemRow {
    cartUuid: string;
    trackingId: string;
    item: IAtlassianItem;
}

const AtlassianItemRow: FC<IAtlassianItemRow> = (props) => {
    const {cartUuid, item, trackingId} = props;
    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const {getOrganisationTier} = useOrganisation();
    const {analytics} = useSegment();

    const trackDeleteAction = (success: boolean, item: IAtlassianItem) => {
        if (!analytics || !trackingId) return;
        analytics.trackUserAction({
            event: 'Deleted App',
            properties: {
                addon_name: item.name,
                hosting: item.service,
                successful: success,
                list_price: item.price,
                tracking_id: trackingId,
                discount: item.totalDiscount,
                product_key: item.productKey,
                units: item.unitCount,
                maintenance_period: item.months,
            },
        });
    };

    const handleRemovingAtlassianItem = () => {
        try {
            setIsDeleting(true);
            removeAtlassianItem(cartUuid, item.uuid, 'UK').then((response) => {
                trackDeleteAction(true, item);
                dispatch(replaceCart(atlassianNewCartFactory(response, getOrganisationTier())));
            });
        } catch (error) {
            trackDeleteAction(false, item);
            notification.error({
                message: 'Item could not be removed',
            });
            setIsDeleting(false);
        }
    };

    const loadingWheel = <LoadingOutlined style={{fontSize: 24, color: 'red'}} spin />;

    return (
        <tr key={item.uuid}>
            <TableCell>{item.name}</TableCell>
            <TableCell>
                {item.unitCount} {toCapitalised(item.unitLabel)} ({item.months} Months)
            </TableCell>
            <TableCell align="right">{formatPrice({price: item.price})}</TableCell>
            <TableCell align="center">
                {isDeleting ? (
                    <Spin indicator={loadingWheel} />
                ) : (
                    <Button buttonType="danger" ghost onClick={handleRemovingAtlassianItem}>
                        Delete
                    </Button>
                )}
            </TableCell>
        </tr>
    );
};

export default AtlassianItemRow;

import React, {Fragment, useEffect} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// COMPONENTS
import {PageTitle, Table, TableHeader, TableCell, PaginationButton} from '@adaptavist/component-library';
import {Tooltip, Button} from '@adaptavist/component-library';
import {Row, Col, Button as AntButton, Empty, Spin} from 'antd';
import {UserAddOutlined, EditOutlined, LoadingOutlined} from '@ant-design/icons';
import Loading from '../../loading';
// ACTIONS
import {getAllUsersActionStart, getMoreUsersActionStart} from '../../../redux/actions/user.actions';
//OTHERS - interface / types, functions, hooks
import {getFullDate} from '../../../utilities/datetime/datetime-formatting';
import {UsersTableHeaderTitle} from '../../../data/table-headers';
import {EmptyPageWrapper} from '../../structure/empty-page-wrapper';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {ReduxStoreType} from '../../../redux/root.reducer';
import {AppName} from '../../../constants/metadata.constants';
import {ViewPagePermissions} from '../../../enums/permissions';

export interface ViewAllUsersProps extends RouteComponentProps {}

const ViewAllUsers = (props: ViewAllUsersProps) => {
    document.title = `Manage Users - ${AppName}`;
    const {match, history} = props;
    // Dispatch
    const dispatch = useDispatch();
    // State
    const {user, gettingMoreUsers, endOfUsers} = useSelector((state: ReduxStoreType) => ({
        user: state.user,
        gettingMoreUsers: state.user.gettingMoreUsers,
        endOfUsers: !state.user.users.metaData?.nextPage,
    }));

    const {hasPermission, organisation} = useOrganisation();

    useEffect(() => {
        if (!organisation) return;
        dispatch(getAllUsersActionStart({organisation}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    const getMoreUsers = () => {
        dispatch(getMoreUsersActionStart({organisation}));
    };

    const InviteUserButton = () => (
        <Button ghost onClick={() => history.push(`${match.path}/new`)}>
            <UserAddOutlined style={{marginRight: '10px'}} />
            Invite User
        </Button>
    );

    const {gettingUsers, users, errorMessage} = user;

    if (gettingUsers && !users.data.length) {
        return (
            <EmptyPageWrapper height="70vh">
                <Loading>
                    <h2>Getting all users...</h2>
                </Loading>
            </EmptyPageWrapper>
        );
    }

    if (!users) {
        return (
            <EmptyPageWrapper height="70vh">
                <Empty description={errorMessage || `There was no user found. Consider adding a user`}>
                    {hasPermission(ViewPagePermissions.quoteInviteUser) ? <InviteUserButton /> : null}
                </Empty>
            </EmptyPageWrapper>
        );
    }
    return (
        <Fragment>
            <Row justify="center" align="middle" gutter={[0, 30]}>
                <Col sm={16}>
                    <PageTitle>All Invited Users</PageTitle>
                </Col>
                <Col sm={{span: 4, offset: 4}} style={{textAlign: 'right'}}>
                    {hasPermission(ViewPagePermissions.quoteInviteUser) ? <InviteUserButton /> : null}
                </Col>
            </Row>
            <Table striped={true}>
                <thead>
                    <tr>
                        {UsersTableHeaderTitle.map((header, index) => (
                            <TableHeader key={`${header.title}-${index}`} align={header.align}>
                                {header.title}
                            </TableHeader>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {users.data
                        .sort((a, b) => b.createdAt - a.createdAt)
                        .map((user, index) => (
                            <tr key={`user-${index}`}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{getFullDate(new Date(user.createdAt), ' ', 2)}</TableCell>
                                <TableCell align="center">
                                    <Tooltip text="Edit user">
                                        <AntButton
                                            type="text"
                                            onClick={() => history.push(`${match.path}/edit/${user.uuid}`)}
                                        >
                                            <EditOutlined style={{fontSize: '25px'}} />
                                        </AntButton>
                                    </Tooltip>
                                </TableCell>
                            </tr>
                        ))}
                </tbody>
            </Table>
            {/* Todo: Add cursor pagination */}
            <Col span={24}>
                <div style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}>
                    <p>
                        Displaying {users.data.length} user{users.data.length > 1 ? 's' : ''}
                    </p>
                    {gettingMoreUsers && (
                        <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: '#5276f6'}} spin />}>
                            Getting more users
                        </Spin>
                    )}
                    {!gettingMoreUsers && (
                        <PaginationButton onClick={getMoreUsers} disabled={endOfUsers}>
                            {endOfUsers ? 'End of Users' : 'Get More Users'}
                        </PaginationButton>
                    )}
                </div>
            </Col>
        </Fragment>
    );
};

export default withRouter(ViewAllUsers);

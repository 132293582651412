import {GetTokenSilentlyOptions} from '../../interfaces/authorization';

export const AuthorizationActionTypes = {
    HANDLE_LOGIN: 'authorization/HANDLE_LOGIN',
    HANDLE_LOGOUT: 'authorization/HANDLE_LOGOUT',
    SELECT_ORGANISATION: 'authorization/SELECT_ORGANISATION',
    INITIALISE_ACCESS_TOKEN: 'authorization/INITIALISE_ACCESS_TOKEN',
};

export interface IAuthorizationState {
    organisation: string | null;
    getAccessTokenSilently: ((options?: GetTokenSilentlyOptions | undefined) => Promise<string>) | null;
}

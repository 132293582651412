import {IAtlassianItem} from '../../../interfaces/suppliers/atlassian/atlassian-item.interface';
import {IAtlassianItemResponse} from '../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface';
import Supplier from '../../../enums/supplier';
import {IOrganisationTier} from '../../../interfaces/organisation/organisation.interface';
import itemDiscountFactory from '../../item-discount-factory';
import {IDiscount} from '../../../interfaces/discounts/discount.interface';
import AtlassianService from '../../../enums/atlassian-service';
import {monthDifferenceFromDate} from '../../../utilities/datetime/month-difference';

const atlassianAddNewItemFactory = (
    atlassianItem: IAtlassianItemResponse,
    organisationTier: IOrganisationTier | null
): IAtlassianItem => {
    const getServiceFromProductKey = (productKey: string): AtlassianService => {
        if (/\.ondemand$/.test(productKey)) return AtlassianService.cloud;
        if (/\.data-?center$/.test(productKey)) return AtlassianService.datacenter;

        return AtlassianService.server;
    };

    const item: IAtlassianItem = {
        supplier: Supplier.atlassian,
        uuid: atlassianItem.id.toString(),
        service: getServiceFromProductKey(atlassianItem.productDetails.productKey),
        saleType: atlassianItem.productDetails.saleType.toLowerCase(),
        name: atlassianItem.productDetails.productDescription,
        price: atlassianItem.totalIncTax,
        discounts: [] as IDiscount[],
        totalDiscount: 0,
        quantity: 1,
        unitCount: atlassianItem.productDetails.unitCount,
        unitLabel: atlassianItem.productDetails.unitLabel,
        months: atlassianItem.maintenanceMonths,
        createdAt: +new Date(),
        description: atlassianItem.description,
        productKey: atlassianItem.productDetails.productKey,
    };

    item.discounts = itemDiscountFactory(item, organisationTier);

    const {discountFixed, discountPercent} = item.discounts.reduce(
        (discounts, d) => {
            switch (d.valueType) {
                case 'percentage':
                    discounts.discountPercent += d.value;
                    break;
                case 'fixed':
                    discounts.discountFixed += d.value;
                    break;
            }
            return discounts;
        },
        {discountFixed: 0, discountPercent: 0}
    );

    item.totalDiscount = item.price * (discountPercent / 100) + discountFixed;

    if (item.saleType === 'renewal') {
        try {
            const startDate = new Date(atlassianItem.maintenanceStartDate!);
            const endDate = new Date(atlassianItem.maintenanceEndDate!);
            item.months = monthDifferenceFromDate(startDate, endDate);
        } catch (e) {
            item.months = 0;
        }
    }

    return item;
};

export default atlassianAddNewItemFactory;

import React from 'react';
import {FrownOutlined} from '@ant-design/icons';
import {Col, Row, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {CenterContent} from '../../components/structure/center-content';
import {Button} from '@adaptavist/component-library';

const {Title, Text} = Typography;

const Page404 = () => {
    return (
        <CenterContent height={'70vh'}>
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <FrownOutlined style={{fontSize: '70px'}} />
                </Col>
                <Col span={24}>
                    <Title>Page not found</Title>
                </Col>
                <Col span={24}>
                    <Text>We can't find the page you're looking for.</Text>
                </Col>
                <Col span={24}>
                    <Link to="/">
                        <Button block ghost>
                            Go back home
                        </Button>
                    </Link>
                </Col>
            </Row>
        </CenterContent>
    );
};

export default Page404;

import axios from 'axios';
import {CHELL_API_URL, CHELL_API_KEY} from '../../../constants/api.constants';
import {IManageUserRoleAPI} from '../../../interfaces/rbac/user/user.interface';

const removeRolesFromUser = async (params: IManageUserRoleAPI, organisationId: string) => {
    const {accessToken, organisationUUID, userUUID, roles} = params;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };
    if (!userUUID) throw new Error('uuid is required');
    return await axios.delete<void>(`${CHELL_API_URL}/organisations/${organisationUUID}/user/${userUUID}/roles`, {
        headers,
        data: {roles},
    });
};

export default removeRolesFromUser;

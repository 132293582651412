import axios from 'axios';
import {SEARCH_MARKETPLACE_PRODUCTS} from '../../../../constants/api.constants';
import atlassianProductSearchFactory from '../../../../factories/suppliers/atlassian/atlassian-product-search.factory';
import IProductLookup from '../../../../interfaces/suppliers/atlassian/product-lookup.interface';
import {IAtlassianProductSearchResponse} from '../../../../interfaces/suppliers/atlassian/responses/atlassian-product-search-response.interface';

const searchAtlassianMarketplaceProducts = async (query: string): Promise<IAtlassianProductSearchResponse> => {
    const response = await axios.get(`${SEARCH_MARKETPLACE_PRODUCTS}/addons/vendor/81?${query}&limit=50`);
    return response.data;
};

export const searchAtlassianMarketplaceDataCenterForAdaptavistProducts = async (): Promise<IProductLookup[]> => {
    const results = await searchAtlassianMarketplaceProducts(`cost=orderable&hosting=datacenter&limit=50`);
    return atlassianProductSearchFactory(results, 'data-center');
};

export const searchAtlassianMarketplaceServerForAdaptavistProducts = async (): Promise<IProductLookup[]> => {
    const results = await searchAtlassianMarketplaceProducts(`cost=orderable&hosting=server&limit=50`);
    return atlassianProductSearchFactory(results, 'server');
};

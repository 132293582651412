import React, {FC, MouseEvent} from 'react';
import {Button} from '@adaptavist/component-library';
import {useSegment, ITrackUserAction} from '@adaptavist-commerce/analytics-provider-react';
import {ButtonProps} from '@adaptavist/component-library/dist/buttons/styled-button';

export interface IAnalyticButtonProps extends ButtonProps {
    userAction: ITrackUserAction;
    sendData?: boolean;
}

const AnalyticButton: FC<IAnalyticButtonProps> = (props) => {
    const {children, userAction, onClick, sendData = true, ...otherProps} = props;
    const {analytics} = useSegment();

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (analytics && sendData) {
            analytics.trackUserAction(userAction);
        }
        if (onClick) onClick(event);
    };
    return (
        <Button onClick={handleOnClick} {...otherProps}>
            {children}
        </Button>
    );
};

export default AnalyticButton;

import {HAMLET_API_KEY, HAMLET_URL} from "../../../../constants/api.constants";
import axios from "axios";
import IAtlassianCartResponse
    from "../../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface";
import {atlassianNewCartFactory} from "../../../../factories/suppliers/atlassian/atlassian-new-cart.factory";

export const getNewAtlassianCart = async ({mac}: {mac: string}) => {
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };
    return await axios
        .get<IAtlassianCartResponse>(`${HAMLET_URL}/cart/new`, {headers})
        .then((response) => atlassianNewCartFactory(response.data));
};

import {switchKebabCaseToSpacesCapitalised} from '../../../utilities/switch-case';
import {IAtlassianProductSearchResponse} from '../../../interfaces/suppliers/atlassian/responses/atlassian-product-search-response.interface';
import IProductLookup from '../../../interfaces/suppliers/atlassian/product-lookup.interface';
import Supplier from '../../../enums/supplier';

const atlassianProductSearchFactory = (product: IAtlassianProductSearchResponse, type: string): IProductLookup[] =>
    product._embedded?.addons.map((a: any) => ({
        type: Supplier.atlassian,
        label: `${a.name} – ${switchKebabCaseToSpacesCapitalised(type)}`,
        value: type === 'server' ? a.key : `${a.key}.${type}`,
    })) || [];
export default atlassianProductSearchFactory;

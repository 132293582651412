import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// COMPONENTS
import {Button} from '@adaptavist/component-library';
import {Spin, Modal, Row, Col} from 'antd';
import {CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons/lib';
import {StyledDangerButton} from '../../shared/elements/button';
// ACTIONS
import {deleteQuoteStart} from '../../../redux/actions/quote-view.actions';
// OTHERS - constants
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {IQuote} from '../../../interfaces/quote/quote.interface';
import {ReduxStoreType} from '../../../redux/root.reducer';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

export type IDeleteQuoteButtonProps = {
    quote: IQuote;
};

export const DeleteQuoteButton = (props: IDeleteQuoteButtonProps) => {
    const [visible, setVisible] = useState(false);
    const [quoteData] = useState({
        uuid: props.quote.uuid,
        organisation: props.quote.organisation,
        createdAt: props.quote.createdAtKey,
        ticketCode: props.quote.ticketCode,
        trackingId: props.quote.trackingId,
    });
    const [isDeleting, setIsDeleting] = useState(false);
    const {organisation} = useOrganisation();
    const {quoteView} = useSelector((state: ReduxStoreType) => state);
    const {errorDeletingQuote} = quoteView;

    const {analytics} = useSegment();
    const dispatch = useDispatch();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOnSubmit = () => {
        if (!organisation) return;
        dispatch(
            deleteQuoteStart({
                createdAtKey: quoteData.createdAt!,
                trackingId: quoteData.trackingId,
                organisation,
                trackUserAction: analytics.trackUserAction,
            })
        );
        setIsDeleting(true);
        if (errorDeletingQuote) setIsDeleting(false);
        setVisible(false);
    };

    const loadingWheel = <LoadingOutlined style={{fontSize: 24, color: 'red'}} spin />;

    return (
        <div>
            <StyledDangerButton type="text" onClick={showModal} data-cy="delete-quote">
                {isDeleting ? <Spin indicator={loadingWheel} /> : <CloseCircleOutlined style={{fontSize: '25px'}} />}
            </StyledDangerButton>
            <Modal
                visible={visible}
                title="Are you sure you want to delete this quote?"
                footer={
                    <Row gutter={[20, 0]} justify="end">
                        <Col>
                            <Button ghost key="back" onClick={handleCancel}>
                                No
                            </Button>
                        </Col>
                        <Col>
                            <Button buttonType="danger" key="submit" type="button" onClick={handleOnSubmit}>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <p>This quote will be deleted permanently</p>
            </Modal>
        </div>
    );
};

import React, {FC, useEffect} from 'react';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {useDispatch, useSelector} from 'react-redux';
// COMPONENTS
import {Col, Empty, Row, Alert} from 'antd';
import {Button, FormField, SectionTitle} from '@adaptavist/component-library';
import Loading from '../loading';
import {EmptyPageWrapper} from '../structure/empty-page-wrapper';
// OTHERS - interfaces, constants, actions
import {IOrganisationFormValues} from './form-values-interface';
import {ReduxStoreType} from '../../redux/root.reducer';
import {getOrganisationDetailsStart, updateOrganisationDetailsStart} from '../../redux/actions/user.actions';
import {AppName} from '../../constants/metadata.constants';
import {
    AddressFormProvider,
    AddressFormFormik,
    AddressFormFields,
    InputField,
    IInitialValue,
} from '@adaptavist-commerce/address-form';
import '@adaptavist-commerce/address-form/dist/index.css';
import {ADDRESS_FORM_API_KEY, ADDRESS_FORM_API_URL} from '../../constants/api.constants';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

const OrganisationForm: FC = () => {
    document.title = `Manage Organisation - ${AppName}`;
    const {organisation, organisationState} = useSelector((state: ReduxStoreType) => ({
        organisation: state.authorization.organisation,
        organisationState: state.user.organisation,
    }));

    const {isAuthenticated} = useAuth0WithCypress();

    const dispatch = useDispatch();

    const getData = () => {
        if (!organisation) return;
        dispatch(getOrganisationDetailsStart({organisation}));
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, organisation]);

    const {analytics} = useSegment();

    const handleSubmit = (values: IOrganisationFormValues) => {
        dispatch(
            updateOrganisationDetailsStart({
                contactDetails: values,
                organisation,
                trackUserAction: analytics.trackUserAction,
            })
        );
    };

    const {savedChanges, gettingData, isSaving, errorSaving, errorMessage, organisationData} = organisationState;

    if (gettingData) {
        return (
            <EmptyPageWrapper height="70vh">
                <Loading>
                    <h2>Getting organisation data...</h2>
                </Loading>
            </EmptyPageWrapper>
        );
    }
    if (!organisationData?.contactDetails) {
        return (
            <EmptyPageWrapper height="70vh">
                <Empty description={`No organisation data found. You can try again`}>
                    <Button ghost onClick={getData}>
                        Try Again
                    </Button>
                </Empty>
            </EmptyPageWrapper>
        );
    }

    return (
        <AddressFormProvider
            countryISOCode={organisationData.contactDetails.isoCountryCode || 'GB'}
            validationSource={'default-validation-schema'}
            apiUrl={ADDRESS_FORM_API_URL}
            apiKey={ADDRESS_FORM_API_KEY}
            extraInitialValues={{
                taxId: organisationData.contactDetails.taxId || '',
            }}
            defaultInitialValues={{...organisationData?.contactDetails} as IInitialValue}
        >
            <AddressFormFormik handleSubmitCallBack={handleSubmit}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <SectionTitle>Physical Address</SectionTitle>
                    </Col>
                    <Col span={24}>
                        <AddressFormFields />
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                        <SectionTitle>Tax Details</SectionTitle>
                        <FormField>
                            <InputField fieldName={'taxId'} />
                        </FormField>
                    </Col>
                </Row>
                <Row gutter={[20, 30]}>
                    <Col span={24}>
                        {/* Error Message */}
                        {errorSaving && (
                            <Alert
                                type="error"
                                showIcon
                                closable
                                message={errorMessage || 'There was an error saving changes '}
                            />
                        )}
                        {/* Success Message */}
                        {savedChanges && (
                            <Alert type="success" showIcon closable message={'All changes have been updated'} />
                        )}
                    </Col>
                    <Col span={24}>
                        <Button type="submit" loading={isSaving} disabled={isSaving}>
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </AddressFormFormik>
        </AddressFormProvider>
    );
};

export default OrganisationForm;

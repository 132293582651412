import axios from 'axios';
import {HAMLET_API_KEY, HAMLET_URL} from '../../../../constants/api.constants';
import {IAtlassianTiersResponse} from '../../../../interfaces/suppliers/atlassian/responses/atlassian-tiers-response.interface';
import {IAtlassianProductTiers} from '../../../../interfaces/suppliers/atlassian/atlassian-product-tiers.interface';
import atlassianProductTiersFactory from '../../../../factories/suppliers/atlassian/atlassian-product-tiers.factory';

const getAtlassianTiers = async (productKey: string, mac: string): Promise<IAtlassianProductTiers> => {
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };

    const response = await axios.get<IAtlassianTiersResponse>(
        `${HAMLET_URL}/products/search?productKey=${productKey}`,
        {headers}
    );

    return atlassianProductTiersFactory(response.data);
};

export default getAtlassianTiers;

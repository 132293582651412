import {IItem} from '../interfaces/quote/item.interface';
import round from "./round";

export const calculateTotalPrice = (items: IItem[]) => {
    const totalItemPrice = items.reduce((acc, i) => (acc += i.price), 0);
    const totalDiscount = items.reduce((acc, i) => acc += i.totalDiscount, 0);
    const discountPercentage = round((1 - (totalItemPrice - totalDiscount) / totalItemPrice) * 100, 2);
    const total = totalItemPrice - totalDiscount;
    return {
        totalItemPrice,
        total,
        totalDiscount,
        discountPercentage,
    };
};

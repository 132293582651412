import React, {Fragment, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
// COMPONENTS
import ViewAllUsers from '../../components/manage-users/view-all-users/view-all-users';
import InviteUser from '../../components/manage-users/invite-user/invite-user';
import EditUser from '../../components/manage-users/edit-user/edit-user';
// ACTIONS
import {getRolesStart} from '../../redux/actions/roles.actions';

const ManagerUsers = () => {
    // Dispatch
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRolesStart());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <ViewAllUsers />
            <Switch>
                <Route exact path={'/users/new'} component={InviteUser} />
                <Route exact path={'/users/edit/:uuid'} component={EditUser} />
            </Switch>
        </Fragment>
    );
};

export default withRouter(ManagerUsers);

import React from 'react';
import {StopOutlined} from '@ant-design/icons';
import {Col, Row, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {CenterContent} from '../../components/structure/center-content';
import {Button} from '@adaptavist/component-library';
import {AppName} from '../../constants/metadata.constants';

const {Title, Text} = Typography;

const UnauthorizedView = () => {
    document.title = `${AppName} - Unauthorized`;
    return (
        <CenterContent height={'70vh'}>
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <StopOutlined style={{fontSize: '70px'}} />
                </Col>
                <Col span={24}>
                    <Title>Unauthorized </Title>
                </Col>
                <Col span={24}>
                    <Text>You are not authorized to view this page</Text>
                </Col>
                <Col span={24}>
                    <Link to="/all-quotes">
                        <Button block ghost>
                            Go back
                        </Button>
                    </Link>
                </Col>
            </Row>
        </CenterContent>
    );
};

export default UnauthorizedView;

import {ITrackUserAction} from '@adaptavist-commerce/analytics-provider-react';
import {IQuote} from '../../interfaces/quote/quote.interface';

export enum QuoteViewActionTypes {
    GET_ALL_QUOTES_START = 'GET_ALL_QUOTES_START',
    GET_ALL_QUOTES_DONE = 'GET_ALL_QUOTES_DONE',
    GET_MORE_QUOTES_START = 'GET_MORE_QUOTES_START',
    GET_MORE_QUOTES_DONE = 'GET_MORE_QUOTES_DONE',

    DELETE_QUOTE_START = 'DELETE_QUOTE_START',
    DELETE_QUOTE_DONE = 'DELETE_QUOTE_DONE',
}

export interface IAllQuotes {
    data: IQuote[];
    metaData: {
        nextPage: string;
    } | null;
}

export interface IQuoteViewState {
    allQuotes: IAllQuotes;
    deletingQuote: boolean;
    gettingAllQuotes: boolean;
    gettingMoreQuotes: boolean;
    errorDeletingQuote: boolean;
    errorGettingQuotes: boolean;
    errorMessageGettingQuotes: string | null;
}

export interface IGetAllQuotesStartAction {
    type: QuoteViewActionTypes.GET_ALL_QUOTES_START;
    payload: {organisation: string | null};
}
export interface IGetAllQuotesDonePayload {
    allQuotes?: IAllQuotes;
    errorGettingQuotes?: boolean;
    errorMessageGettingQuotes?: string | null;
}
export interface IGetAllQuotesDoneAction {
    type: QuoteViewActionTypes.GET_ALL_QUOTES_DONE;
    payload: IGetAllQuotesDonePayload;
}
export interface IDeleteQuoteStartPayload {
    createdAtKey: string;
    trackingId: string;
    organisation: string | null;
    trackUserAction?: (payload: ITrackUserAction) => void;
}
export interface IDeleteQuoteStartAction {
    type: QuoteViewActionTypes.DELETE_QUOTE_START;
    payload: IDeleteQuoteStartPayload;
}
export interface IDeleteQuoteDonePayload {
    updatedAllQuotesData?: IQuote[];
    errorDeletingQuote?: boolean;
}
export interface IDeleteQuoteDoneAction {
    type: QuoteViewActionTypes.DELETE_QUOTE_DONE;
    payload: IDeleteQuoteDonePayload;
}

export interface IGetMoreQuotesStartAction {
    type: QuoteViewActionTypes.GET_MORE_QUOTES_START;
    payload: {organisation: string | null};
}

export interface IGetMoreQuotesDoneAction {
    type: QuoteViewActionTypes.GET_MORE_QUOTES_DONE;
    payload: IGetAllQuotesDonePayload;
}

export type IQuoteViewActions =
    | IGetAllQuotesStartAction
    | IGetAllQuotesDoneAction
    | IDeleteQuoteStartAction
    | IDeleteQuoteDoneAction
    | IGetMoreQuotesStartAction
    | IGetMoreQuotesDoneAction;

import {AuthorizationActionTypes, IAuthorizationState} from '../action-types/authorization.types';

const initialState: IAuthorizationState = {
    organisation: null,
    getAccessTokenSilently: null,
};

const authorizationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AuthorizationActionTypes.HANDLE_LOGIN:
            const organisation = Object.keys(action.payload).length ? Object.keys(action.payload)[0] : '';
            return {
                ...state,
                organisation,
                userOrganisations: action.payload,
            };
        case AuthorizationActionTypes.HANDLE_LOGOUT:
            return {
                ...state,
                organisation: null,
            };
        case AuthorizationActionTypes.INITIALISE_ACCESS_TOKEN:
            return {
                ...state,
                getAccessTokenSilently: action.payload,
            };
        case AuthorizationActionTypes.SELECT_ORGANISATION:
            return {
                ...state,
                organisation: action.payload,
            };
        default:
            return state;
    }
};

export default authorizationReducer;

// const _state = {
//     organisation: "c4c48e94-8886-458f-a556-6ce78f9bb0d9",
//     userOrganisations: {
//         "c4c48e94-8886-458f-a556-6ce78f9bb0d9": {
//             roles: ["admin"],
//             permissions: [],
//         },
//         "c4c48e94-8886-458f-a556-6ce78f9ba0d9": {
//             roles: ["admin"],
//             permissions: [],
//         },
//     },
// };

// functions hasPermission(permission: string, organisation?: string), hasRole(role: string, organisation?: string)
// if organisation isn't passed in, use current organisation

// create useAuthorization hook, for hasPermission/hasRole/hasAnyPermission

import React, {FC, Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// COMPONENTS
import {PageTitle, PaginationButton, Table, TableCell, TableHeader, Tooltip} from '@adaptavist/component-library';
// import { Tag } from '@adaptavist/component-library';
import {Button as AntButton, Col, Row, Spin} from 'antd';
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons/lib';
import {DeleteQuoteButton} from './quote-list-delete-button';
// ACTIONS
import {getOrganisationDetailsStart} from '../../../redux/actions/user.actions';
// FUNCTIONS
import {getFullDate} from '../../../utilities/datetime/datetime-formatting';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {formatPrice} from '../../../utilities/currency-format';
// INTERFACE / TYPES
import {IQuote} from '../../../interfaces/quote/quote.interface';
// OTHERS - constants
import {ReduxStoreType} from '../../../redux/root.reducer';
import getAtNumbersString from '../../../utilities/get-at-numbers-string';
import {getMoreQuotesStart} from '../../../redux/actions/quote-view.actions';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';
import getQuotePdf from '../../../api/quote-builder/get-quote-pdf';
import {ActionPermissions, ViewPagePermissions} from '../../../enums/permissions';

const QuoteListRow: FC<{quote: IQuote}> = ({quote}) => {
    const {hasPermission} = useOrganisation();
    const {analytics} = useSegment();

    let totalPrice = quote.totalPrice;
    const discount = 0;
    if (discount && totalPrice) {
        totalPrice -= totalPrice * (discount / 100);
    }

    const handlePdfDownload = async (url: string, ticketCode: string, trackingId: string) => {
        const blob = await getQuotePdf(url);
        const objectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', `AVST-${ticketCode}-Proforma.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (!analytics || !trackingId) return;
        analytics.trackUserAction({
            event: 'Downloaded Quote',
            properties: {
                tracking_id: trackingId,
            },
        });
    };

    return (
        <tr key={quote.uuid}>
            <TableCell>{getFullDate(new Date(quote.createdAt!))}</TableCell>
            <TableCell>{quote.ticketCode}</TableCell>
            {hasPermission(ViewPagePermissions.quoteViewAtNumber) ? (
                <TableCell>{getAtNumbersString(quote.carts)}</TableCell>
            ) : null}
            <TableCell>{quote.endUserCompanyDetails?.name || 'N/A'}</TableCell>
            <TableCell>{quote.endUserContactDetails?.email || 'N/A'}</TableCell>
            <TableCell align={'right'}>{totalPrice ? formatPrice({price: totalPrice}) : 'N/A'}</TableCell>
            <TableCell align="center">
                {hasPermission(ActionPermissions.quoteDownload) ? (
                    <Tooltip text="Download PDF">
                        <AntButton
                            type="text"
                            disabled={!quote.s3Url}
                            onClick={() => handlePdfDownload(quote.s3Url!, quote.ticketCode!, quote.trackingId!)}
                            data-cy="download-pdf"
                        >
                            <DownloadOutlined style={{fontSize: '25px'}} />
                        </AntButton>
                    </Tooltip>
                ) : null}
                {hasPermission(ActionPermissions.quoteDelete) ? (
                    <Tooltip text="Delete Quote">
                        <DeleteQuoteButton quote={quote} />
                    </Tooltip>
                ) : null}
            </TableCell>
        </tr>
    );
};

const QuoteList: FC<{quotes: IQuote[]}> = ({quotes}) => {
    const {gettingMoreQuotes, endOfQuote} = useSelector((state: ReduxStoreType) => ({
        organisationData: state.user.organisation.organisationData,
        gettingMoreQuotes: state.quoteView.gettingMoreQuotes,
        endOfQuote: !state.quoteView.allQuotes.metaData?.nextPage,
    }));

    const dispatch = useDispatch();
    const {organisation, hasPermission} = useOrganisation();

    const getMoreQuotes = () => {
        dispatch(getMoreQuotesStart({organisation}));
    };

    useEffect(() => {
        if (!organisation) return;
        dispatch(getOrganisationDetailsStart({organisation}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    return (
        <Fragment>
            <Row justify="center" align="middle" gutter={[0, 30]}>
                <Col span={24}>
                    <PageTitle>All Quotes</PageTitle>
                </Col>
                <Col span={24}>
                    <Table striped={true}>
                        <thead>
                            <tr>
                                <TableHeader align={'left'}>Created on</TableHeader>
                                <TableHeader align={'left'}>Ref.</TableHeader>
                                {hasPermission(ViewPagePermissions.quoteViewAtNumber) ? (
                                    <TableHeader align={'left'}>AT No.</TableHeader>
                                ) : null}
                                <TableHeader align={'left'}>Company</TableHeader>
                                <TableHeader align={'left'}>Technical Contact</TableHeader>
                                {/* <TableHeader align={'center'}>Status</TableHeader> */}
                                <TableHeader align={'right'}>Total Price</TableHeader>
                                <TableHeader align={'center'}>Action</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {quotes.map((quote) => (
                                <QuoteListRow quote={quote} />
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col span={24}>
                    <div style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}>
                        <p>
                            Displaying {quotes.length} quote{quotes.length > 1 ? 's' : ''}
                        </p>
                        {gettingMoreQuotes && (
                            <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: '#5276f6'}} spin />}>
                                Getting more quotes
                            </Spin>
                        )}
                        {!gettingMoreQuotes && (
                            <PaginationButton onClick={getMoreQuotes} disabled={endOfQuote}>
                                {endOfQuote ? 'End of Quotes' : 'Get More Quotes'}
                            </PaginationButton>
                        )}
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default QuoteList;

import React, {FC, useEffect} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
// COMPONENTS
import LandingView from './views/landing-view/landing-view';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import ConfirmInviteView from './views/confirm-invite/confirm-invite-view';
import {useDispatch} from 'react-redux';
import ViewAllQuotes from './views/view-all-quotes/view-all-quotes';
import {handleLogin, initialiseAccessToken} from './redux/actions/authorization.actions';
import ManagerUsers from './views/manage-users/manage-users';
import ManageOrganisationContactDetails from './views/manage-organisation/manage-organisation-contact-details';
import CreateNewQuoteView from './views/create-new-quote/create-new-quote-view';
import Loading, {LoadingFullPage, AuthorizedRedirectPage} from './components/loading';
import UnauthorizedView from './views/redirects/unauthorized-view';
import Page404 from './views/redirects/404-page';
// ENUMS
import {ViewPagePermissions} from './enums/permissions';
// LAYOUT
import ContentWrapper from './components/structure/content-wrapper';
// AUTH ROUTE COMPONENT
import ErrorBoundary from './components/error-boundary/error-boundary';
import {AuthenticatedUserRoute, AuthorizedUserRoute} from '@adaptavist-commerce/organisation-context-react';
import {AppName} from './constants/metadata.constants';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

const App: FC = () => {
    document.title = AppName;
    const {analytics, isAnalyticsInitialized} = useSegment();

    const location = useLocation();

    useEffect(() => {
        if (!isAnalyticsInitialized) return;
        analytics.pageView({
            name: location.pathname,
        });
    }, [analytics, isAnalyticsInitialized, location]);

    const {isAuthenticated, getAccessTokenSilently, user, isLoading, getIdTokenClaims} = useAuth0WithCypress();
    const dispatch = useDispatch();

    dispatch(initialiseAccessToken(getAccessTokenSilently));

    useEffect(() => {
        if (!isAnalyticsInitialized || !user) return;
        analytics.identifyUser({
            userId: user.sub,
            traits: {
                firstName: user.given_name,
                lastName: user.family_name,
                email: user.email,
                avatar: user.picture,
                username: user.nickname,
            },
        });
    }, [analytics, isAnalyticsInitialized, user]);

    useEffect(() => {
        if (!isAuthenticated) return;
        getIdTokenClaims().then((claims: any) => {
            const organisations = claims['https://my-adaptavist.com/authorization'].organisations;
            dispatch(handleLogin(organisations));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    if (isLoading) {
        return (
            <LoadingFullPage>
                <h1>Authenticating...</h1>
            </LoadingFullPage>
        );
    }

    return (
        <ErrorBoundary>
            <ContentWrapper>
                <Switch>
                    <Route exact path={'/'}>
                        {() => {
                            if (isAuthenticated) return <Redirect to="/all-quotes" />;
                            return <LandingView />;
                        }}
                    </Route>
                    <AuthorizedUserRoute
                        path={'/users'}
                        component={ManagerUsers}
                        allowedPermissions={[ViewPagePermissions.quoteUserList]}
                        unauthorizedComponent={UnauthorizedView}
                        LoadingComponent={AuthorizedRedirectPage}
                    />
                    <AuthorizedUserRoute
                        exact
                        path={'/organisation'}
                        component={ManageOrganisationContactDetails}
                        allowedPermissions={[ViewPagePermissions.quoteManageOrganisation]}
                        unauthorizedComponent={UnauthorizedView}
                        LoadingComponent={AuthorizedRedirectPage}
                    />
                    <AuthorizedUserRoute
                        exact
                        path={'/all-quotes'}
                        component={ViewAllQuotes}
                        allowedPermissions={[ViewPagePermissions.quoteList]}
                        unauthorizedComponent={UnauthorizedView}
                        LoadingComponent={AuthorizedRedirectPage}
                    />
                    <AuthorizedUserRoute
                        exact
                        path={'/quote/new'}
                        component={CreateNewQuoteView}
                        allowedPermissions={[ViewPagePermissions.quoteCreate]}
                        unauthorizedComponent={UnauthorizedView}
                        LoadingComponent={AuthorizedRedirectPage}
                    />
                    <AuthenticatedUserRoute
                        exact
                        path={'/confirm-invite/:token'}
                        component={ConfirmInviteView}
                        LoadingComponent={Loading}
                    />
                    <Route path="*" component={Page404} />
                </Switch>
            </ContentWrapper>
        </ErrorBoundary>
    );
};

export default App;

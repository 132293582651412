export const pad = (num: number, size = 2) => {
    if (num.toString().length >= size) return num;
    return (Math.pow(10, size) + Math.floor(num)).toString().substring(1);
};

export const getMonthShort = (date: Date) =>
    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()];

export const getMonthLong = (date: Date) =>
    [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ][date.getMonth()];

export const getDayPeriod = (date: Date) => (date.getHours() > 12 ? 'PM' : 'AM');

export const getFullDate = (date: Date, separator: string = ' ', padDigits: 1 | 2 = 1) => {
    return `${pad(date.getDate(), padDigits)}${separator}${getMonthShort(date)}${separator}${date.getFullYear()}`;
};

export const getTime = (date: Date) => `${pad(date.getHours())}:${pad(date.getMinutes())}${getDayPeriod(date)}`;

export const getFullDateTime = (date: Date) => {
    return `${getFullDate(date)} ${getTime(date)}`;
};

export const getDateRange = (dateFrom: Date, dateTo: Date) => {
    if (dateFrom.getDate() === dateTo.getDate()) {
        return `${getFullDateTime(dateFrom)} – ${getTime(dateTo)}`;
    }
    return `${getFullDateTime(dateFrom)} – ${getFullDateTime(dateTo)}`;
};

import {IAtlassianCart} from '../../../interfaces/suppliers/atlassian/atlassian-cart.interface';
import IAtlassianCartResponse from '../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface';
import atlassianAddNewItemFactory from './atlassian-add-new-item.factory';
import Supplier from '../../../enums/supplier';
import {IOrganisationTier} from "../../../interfaces/organisation/organisation.interface";

export const atlassianNewCartFactory = (
    cart: IAtlassianCartResponse,
    organisationTier: IOrganisationTier | null = null
): IAtlassianCart => ({
    type: Supplier.atlassian,
    uuid: cart.uuid,
    items: cart.items.map((i) => atlassianAddNewItemFactory(i, organisationTier)),
    currency: cart.currency,
    lastCartResponse: cart,
});

export const ATLASSIAN_PURCHASER_CONTACT = {
    purchaserContactDetails: {
        firstName: 'UK-Marvin',
        lastName: 'Organisation Account',
        email: 'uk-marvin@adaptavist.com',
        phone: '00000'
    }
}

export const ATLASSIAN_BILLING_CONTACT = {
    billingContactDetails: {
        firstName: 'UK-Marvin',
        lastName: 'Organisation Account',
        email: 'uk-marvin@adaptavist.com',
        phone: '00000'
    },
    billingOrganisationDetails: {
        address1: '25 Wilton Road',
        address2: 'Victoria',
        city: 'London',
        country: 'United Kingdom',
        isoCountryCode: 'GB',
        organisationName: 'Adaptavist UK Services Ltd',
        postcode: 'SW1V 1LW',
        state: 'London',
        taxId: 'GB242020075'
    }
}

import {IGetRolesDoneActionPayload, RolesActionTypes} from "../action-types/roles.types";


export const getRolesStart = () => ({
    type: RolesActionTypes.GET_ROLES_START,
});

export const getRolesDone = (payload: IGetRolesDoneActionPayload) => ({
    type: RolesActionTypes.GET_ROLES_DONE,
    payload,
});

import axios from 'axios';
import {ISENRenewal} from '../../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface';
import {HAMLET_API_KEY, HAMLET_URL} from '../../../../constants/api.constants';

const findAtlassianRenewal = async (technicalContact: string, sen: string, mac: string) => {
    const data = {
        email: technicalContact,
        accountId: sen,
    };

    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };

    const response = await axios.post<ISENRenewal>(`${HAMLET_URL}/cart/getrenewaloptions`, data, {headers});

    return response.data
};

export default findAtlassianRenewal;

import axios from 'axios';
import {PARTNER_PORTAL_API, PARTNER_PORTAL_API_KEY} from '../../constants/api.constants';

const deleteQuote = async (accessToken: string, organisation: string, createdAt: string) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': PARTNER_PORTAL_API_KEY,
    };

    await axios.delete(`${PARTNER_PORTAL_API}/organisations/${organisation}/quotes/${createdAt}`, {headers});
};

export default deleteQuote;

import {
    CreateQuoteControlsTypes,
    IInitializeStepperPayload,
    INextOrPreviousStepPayload,
} from '../action-types/create-quote-controls.types';

export const initializeCreateQuoteStep = (payload: IInitializeStepperPayload) => ({
    type: CreateQuoteControlsTypes.INITIALIZE_STEPPER,
    payload,
});

export const createQuoteNextStep = (payload?: INextOrPreviousStepPayload) => ({
    type: CreateQuoteControlsTypes.NEXT_STEP,
    payload,
});

export const createQuotePreviousStep = (payload?: INextOrPreviousStepPayload) => ({
    type: CreateQuoteControlsTypes.PREVIOUS_STEP,
    payload,
});

export const resetStep = () => ({
    type: CreateQuoteControlsTypes.RESET_STEP,
});

import React, {FC, Fragment} from 'react';
import {Button, PanelBody, PanelHeader, PanelWithBorder, Rule} from "@adaptavist/component-library";
import {CenterCol} from "../../../components/structure/center-content";
import {MailFilled} from "@ant-design/icons";
import {Row} from "antd";
import {Link} from "react-router-dom";


const InviteConfirmedPanel: FC = () =>
    <PanelWithBorder>
        <PanelHeader paddingBottom={0}>
            <CenterCol>
                <Fragment>
                    <MailFilled style={{fontSize: '50px', color: '#52C41A'}}/>
                    <h2>Invitation confirmed</h2>
                </Fragment>
                <Rule/>
            </CenterCol>
        </PanelHeader>
        <br/>
        <PanelBody>
            <Row gutter={[0, 20]}>
                <CenterCol>
                    <p>
                        Your invitation has been confirmed successfully. You can continue by using the
                        button below
                    </p>
                </CenterCol>
                <CenterCol>
                    <Link to="/">
                        <Button block ghost>
                            Continue
                        </Button>
                    </Link>
                </CenterCol>
            </Row>
        </PanelBody>
    </PanelWithBorder>

export default InviteConfirmedPanel;

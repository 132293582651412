import axios from 'axios';
import {HAMLET_API_KEY, HAMLET_URL} from '../../../../constants/api.constants';
import IAtlassianCartResponse from '../../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface';

const removeAtlassianItem = async (cartID: string, itemID: string, mac: string) => {
    const data = {
        cartID,
        itemID,
    };

    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };

    const response = await axios.post<IAtlassianCartResponse>(`${HAMLET_URL}/cart/remove`, data, {headers});

    return response.data;
};

export default removeAtlassianItem;

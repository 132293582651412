import {IItem} from "../interfaces/quote/item.interface";
import {IOrganisationTier} from "../interfaces/organisation/organisation.interface";
import {IAtlassianDiscount, IDiscount} from "../interfaces/discounts/discount.interface";
import Supplier from "../enums/supplier";
import Discount from "../enums/discount";
import {IAtlassianItem} from "../interfaces/suppliers/atlassian/atlassian-item.interface";

const itemDiscountFactory = (item: IItem, organisationTier: IOrganisationTier | null): IDiscount[] => {
    if(!organisationTier) return [];

    const itemDiscounts: IDiscount[] = [];

    for (const discount of organisationTier.discounts) {
        switch (true) {
            case item.supplier === Supplier.atlassian && discount.type === Discount.atlassian:
                const foundDiscount = lookupAtlassianDiscount(item as IAtlassianItem, discount as IAtlassianDiscount)
                if (foundDiscount) itemDiscounts.push(foundDiscount);
        }
    }

    return itemDiscounts
}

export default itemDiscountFactory;

const lookupAtlassianDiscount = (item: IAtlassianItem, discount: IAtlassianDiscount): IDiscount | null => {
    if(item.saleType !== discount.saleType) return null;
    if(discount.tier > 0 && item.unitCount > discount.tier) return null;
    if(item.service !== discount.service) return null;
    return discount;
};

import React, {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {selectOrganisation} from '../redux/actions/authorization.actions';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';

const ReduxOrganisationProviderContext = React.createContext<{organisation: string | null}>({
    organisation: null,
});

const ReduxOrganisationProvider: FC<{}> = ({children}) => {
    const {organisation} = useOrganisation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!organisation) return;
        dispatch(selectOrganisation(organisation));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    return (
        <ReduxOrganisationProviderContext.Provider
            value={{
                organisation,
            }}
        >
            {children}
        </ReduxOrganisationProviderContext.Provider>
    );
};

export {ReduxOrganisationProviderContext, ReduxOrganisationProvider};

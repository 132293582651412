import React, {FC, Fragment} from 'react';
import {Button, PanelBody, PanelHeader, PanelWithBorder, Rule} from "@adaptavist/component-library";
import {CenterCol} from "../../../components/structure/center-content";
import {MailOutlined} from "@ant-design/icons";
import {Row} from "antd";

interface InviteUnconfirmedPanelProps {
    isConfirming: boolean,
    confirmationHandler: () => void
}

const InviteUnconfirmedPanel: FC<InviteUnconfirmedPanelProps> = ({isConfirming, confirmationHandler}) =>
    <PanelWithBorder>
        <PanelHeader paddingBottom={0}>
            <CenterCol>
                <Fragment>
                    <MailOutlined style={{fontSize: '50px'}}/>
                    <h2>You have an invitation</h2>
                </Fragment>
                <Rule/>
            </CenterCol>
        </PanelHeader>
        <br/>
        <PanelBody>
            <Row gutter={[0, 20]}>
                <CenterCol>
                    <p>Please, confirm your invitation to continue.</p>
                </CenterCol>
                <CenterCol>
                    <Button
                        block
                        onClick={confirmationHandler}
                        disabled={isConfirming}
                        loading={isConfirming}
                    >
                        Confirm Invite
                    </Button>
                </CenterCol>
            </Row>
        </PanelBody>
    </PanelWithBorder>

export default InviteUnconfirmedPanel;

/**
 * Check Stepper Range helps prevent over or under stepping
 * a stepper limit
 */
export const checkStepperRange = ({totalSteps, currentStep}: {totalSteps: number; currentStep: number}) => {
    const endOfStepIndex = totalSteps - 1;
    // Protect against under or over range
    let current = currentStep;

    if (currentStep > endOfStepIndex) {
        current = endOfStepIndex;
    }

    if (currentStep < 0 || endOfStepIndex < 0) {
        current = 0;
    }

    return current;
};

import React, {useEffect, useState} from 'react';
import {FormField, Label} from '@adaptavist/component-library';
import AsyncSelect from 'react-select/async';
import {Row, Col} from 'antd';
import ProductSelectorSwitch from '../../quote/product-selectors/product-selector-switch';
import searchProducts from '../../../api/quote-builder/search-products';
import './find-product.css';
import {useSelector, useDispatch} from 'react-redux';
import {ReduxStoreType} from '../../../redux/root.reducer';
import {setSelectedProduct} from '../../../redux/actions/quote-actions';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

const FindProduct: React.FC = () => {
    const quoteReduxState = useSelector((state: ReduxStoreType) => state.quote);
    const {selectedProduct} = quoteReduxState;
    const dispatch = useDispatch();
    const {analytics, isAnalyticsInitialized} = useSegment();

    const [searchResult, setResultValue] = useState({
        value: '',
        suggestionsCount: 0,
    });

    useEffect(() => {
        if (!analytics) return;
        analytics.trackUserAction({
            event: 'Switched Tab',
            properties: {
                tab: 'Find Product',
            },
        });
    }, [analytics]);

    let timeout: any;

    const loadOptions = (search: string, callback: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            searchProducts(search).then((data) => {
                callback(data);
                setResultValue({value: search, suggestionsCount: data.length});
            });
        }, 500);
    };

    const onOptionSelected = (product: any) => {
        dispatch(setSelectedProduct(product));
        if (!isAnalyticsInitialized) return;
        analytics.trackUserAction({
            event: 'Searched App',
            properties: {
                searchQuery: searchResult.value,
                selection: product.label,
                productKey: product.value,
                hosting: product.label.split('–')[1],
                numResults: searchResult.suggestionsCount,
            },
        });
        setResultValue({value: '', suggestionsCount: 0});
    };
    return (
        <Row gutter={[0, 30]}>
            <Col span={24}>
                <FormField>
                    <Label>Search for new apps</Label>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        value={selectedProduct}
                        placeholder="Search..."
                        getOptionLabel={(product: any) => product.label}
                        onChange={onOptionSelected}
                    />
                </FormField>
            </Col>
            <Col span={24}>
                <ProductSelectorSwitch />
            </Col>
        </Row>
    );
};
export default FindProduct;

import axios from 'axios';
import {CHELL_API_URL, CHELL_API_KEY} from '../../../constants/api.constants';
import {IOrganisationFormValues} from '../../../components/forms/form-values-interface';

const updateOrganisation = async (
    accessToken: string,
    organisation: string,
    data: {
        taxId: string;
        companyName: string;
        contactDetails: Pick<
            IOrganisationFormValues,
            'isoCountryCode' | 'region' | 'addressLineOne' | 'addressLineTwo' | 'city' | 'postalCode'
        >;
    },
    organisationId: string
) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisationId,
        'x-api-key': CHELL_API_KEY,
    };

    await axios.put(`${CHELL_API_URL}/organisations/${organisation}`, data, {headers});
};

export default updateOrganisation;

import React from 'react';
import {PageTitle} from '@adaptavist/component-library';
import {Col, Row} from 'antd';
import OrganisationForm from '../../components/forms/organisation-form';

const colSize = {
    xs: {span: 24, offset: 0},
    sm: {span: 24, offset: 0},
    md: {span: 24, offset: 0},
    lg: {span: 16, offset: 4},
};

const ManageOrganisationContactDetails = () => {
    return (
        <Row>
            <Col xs={colSize.xs} sm={colSize.sm} md={colSize.md} lg={colSize.lg}>
                <PageTitle>Manage Organisation</PageTitle>
                <OrganisationForm />
            </Col>
        </Row>
    );
};

export default ManageOrganisationContactDetails;

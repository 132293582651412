import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// COMPONENTS
import {ContentTitle, ListTable, PanelBody} from '@adaptavist/component-library';
import {PanelDropShadowLeft, PanelHeader, PanelTitle, Rule} from '@adaptavist/component-library';
import {Col, Row, Empty, Alert, notification, Space, Typography} from 'antd';
import QuoteSummaryItem from './quote-summary-item';
import {EmptyPageWrapper} from '../../structure/empty-page-wrapper';
import {SyncOutlined} from '@ant-design/icons';
// ACTIONS
import {createQuoteNextStep, resetStep} from '../../../redux/actions/create-quote-controls.actions';
import {resetQuote} from '../../../redux/actions/quote-actions';
// OTHERS - interface / types, functions
import {IItem} from '../../../interfaces/quote/item.interface';
import {ReduxStoreType} from '../../../redux/root.reducer';
import {formatPrice} from '../../../utilities/currency-format';
import QuoteSummaryPrices from './quote-summary-prices';
import styles from '../../../styles.module.css';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import AnalyticsButton from '../../../analytics-components/analytics-button';

const {Text} = Typography;

const QuoteSummary: React.FC = () => {
    const [items, setItems] = useState<IItem[]>([]);
    const {getOrganisationTier} = useOrganisation();
    const dispatch = useDispatch();
    // Redux State
    const {stepper, quote} = useSelector((state: ReduxStoreType) => ({
        stepper: state.controls.createQuoteStepper,
        quote: state.quote,
    }));

    useEffect(() => {
        const items = quote.carts
            .reduce((arr: IItem[], cart) => [...arr, ...cart.items], [])
            .sort((a, b) => +a.createdAt - +b.createdAt);
        setItems(items);
    }, [quote.carts]);

    const {currentStep, totalSteps} = stepper;

    const handleContinue = () => dispatch(createQuoteNextStep());

    const handleAbandon = () => {
        dispatch(resetStep());
        dispatch(resetQuote());
        notification.info({
            message: 'Quote Abandoned',
        });
    };

    if (items.length === 0) {
        return (
            <PanelDropShadowLeft borderRadius={1}>
                <EmptyPageWrapper height="40vh">
                    <Empty description="Your quote summary is empty" />
                </EmptyPageWrapper>
            </PanelDropShadowLeft>
        );
    }

    const {errorMessageGeneratingQuote} = quote;

    const tier = getOrganisationTier();

    return (
        <PanelDropShadowLeft borderRadius={1} className={styles.sidebarWrapper}>
            <div>
                <PanelHeader paddingBottom={1}>
                    <PanelTitle>Quote Summary</PanelTitle>
                </PanelHeader>
            </div>
            <div style={{padding: '0 20px'}}>
                <PanelBody>
                    <ContentTitle>Products</ContentTitle>
                    <Rule />
                </PanelBody>
            </div>
            <div className={styles.sideBarScrollList}>
                <ListTable>
                    <tbody>
                        {items.map((i, index) => (
                            <QuoteSummaryItem
                                key={`${i.name}-${index}`}
                                leftItem={i.name}
                                rightItem={formatPrice({price: i.price})}
                            />
                        ))}
                    </tbody>
                </ListTable>
            </div>
            <div className={styles.sideBarFooter}>
                <Rule />
                <QuoteSummaryPrices quoteQuantity={items.length} items={items} discountName={tier?.name} />
                <Rule />
                <br />
                <Row gutter={[0, 10]}>
                    {quote.generatingQuote && (
                        <Col span={24}>
                            <Alert
                                message={
                                    <div>
                                        <SyncOutlined spin style={{marginRight: '10px'}} />
                                        Generating quote. Please wait...
                                    </div>
                                }
                                type="info"
                            />
                        </Col>
                    )}
                    {quote.errorGeneratingQuote && !quote.generatingQuote && (
                        <Col span={24}>
                            <Alert
                                message={
                                    <Space direction="vertical">
                                        {(typeof errorMessageGeneratingQuote === 'object' &&
                                            errorMessageGeneratingQuote !== null &&
                                            errorMessageGeneratingQuote.map((error, index) => (
                                                <Text key={`${index}-error`}>{error.errorKey}</Text>
                                            ))) || (
                                            <Text>
                                                {errorMessageGeneratingQuote ||
                                                    `There was an error generating quote.
                                                Check form and try again`}
                                            </Text>
                                        )}
                                    </Space>
                                }
                                type="error"
                                showIcon
                            />
                        </Col>
                    )}
                    {currentStep + 1 < totalSteps - 1 && (
                        <Col span={24}>
                            <AnalyticsButton
                                onClick={handleContinue}
                                block
                                userAction={{
                                    event: 'Clicked Button',
                                    properties: {
                                        item: 'Continue',
                                        step: currentStep + 1,
                                        quote: items,
                                        tracking_id: quote.trackingId,
                                    },
                                }}
                            >
                                Continue
                            </AnalyticsButton>
                        </Col>
                    )}
                    {currentStep + 1 === totalSteps - 1 && (
                        <Col span={24}>
                            <AnalyticsButton
                                type="submit"
                                disabled={quote.generatingQuote}
                                block
                                sendData={quote.generatingQuote}
                                userAction={{
                                    event: 'Clicked Button',
                                    properties: {
                                        item: 'Generate Quote',
                                        step: currentStep + 1,
                                        quote: items,
                                        tracking_id: quote.trackingId,
                                    },
                                }}
                            >
                                Generate Quote
                            </AnalyticsButton>
                        </Col>
                    )}
                    <Col span={24}>
                        <AnalyticsButton
                            block
                            buttonType="text"
                            borderOnHover
                            danger
                            onClick={handleAbandon}
                            disabled={quote.generatingQuote}
                            userAction={{
                                event: 'Abandoned Quote',
                                properties: {tracking_id: quote.trackingId, step: currentStep + 1},
                            }}
                        >
                            Abandon
                        </AnalyticsButton>
                    </Col>
                </Row>
            </div>
        </PanelDropShadowLeft>
    );
};
export default QuoteSummary;

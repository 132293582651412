import axios from 'axios';
import {HAMLET_API_KEY, HAMLET_URL} from '../../../../constants/api.constants';
import {IAtlassianCart} from '../../../../interfaces/suppliers/atlassian/atlassian-cart.interface';
import {IAtlassianQuoteContactDetails} from "../../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface";
import {atlassianNewCartFactory} from "../../../../factories/suppliers/atlassian/atlassian-new-cart.factory";
import {IOrganisationTier} from "../../../../interfaces/organisation/organisation.interface";

const saveAtlassianCartDraft = async (cart: IAtlassianCart, contactDetails: IAtlassianQuoteContactDetails, organisationTier: IOrganisationTier | null, mac: string): Promise<IAtlassianCart> => {
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };

    const response = await axios.post(`${HAMLET_URL}/cart/details`, {
        ...cart.lastCartResponse,
        ...contactDetails

    }, {headers});

    return atlassianNewCartFactory(response.data, organisationTier);
};

export default saveAtlassianCartDraft;

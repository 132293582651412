import {IRolesState, RolesActionTypes} from "../action-types/roles.types";

const initialState: IRolesState = {
    data: null,
    gettingRoles: false,
};

const rolesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case RolesActionTypes.GET_ROLES_START:
            return {
                ...state,
                gettingRoles: true,
            };
        case RolesActionTypes.GET_ROLES_DONE:
            return {
                ...state,
                gettingRoles: false,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default rolesReducer;

import React from 'react';
// COMPONENTS
import {FormField, SectionTitle} from '@adaptavist/component-library';
import {Col, Row} from 'antd';
import {AddressFormFields, InputField} from '@adaptavist-commerce/address-form';
import '@adaptavist-commerce/address-form/dist/index.css';
import {
    flatContactDetailsToCompanyTransformer,
    flatContactDetailsToPersonTransformer,
} from '../../../transformers/flat-contact-details-to-person-company';
import {AddressFormProvider, AddressFormFormik} from '@adaptavist-commerce/address-form';
import {ADDRESS_FORM_API_KEY, ADDRESS_FORM_API_URL} from '../../../constants/api.constants';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {useDispatch} from 'react-redux';
import {generateQuoteStart} from '../../../redux/actions/quote-actions';
import QuoteSummary from '../quote-summary/quote-summary';

const ContactDetails = () => {
    const {organisation, getOrganisationTier} = useOrganisation();

    const dispatch = useDispatch();

    const handleSubmit = (values: any) => {
        if (!organisation) return;
        const organisationTier = getOrganisationTier();
        const {phoneNumber, ...rest} = values;
        const finalValues = {phone: values.phoneNumber, ...rest};
        dispatch(
            generateQuoteStart({
                endUserContactDetails: flatContactDetailsToPersonTransformer(finalValues),
                endUserCompanyDetails: flatContactDetailsToCompanyTransformer(finalValues),
                organisation,
                organisationTier,
            })
        );
    };
    return (
        <Col span={24}>
            <AddressFormProvider
                apiUrl={ADDRESS_FORM_API_URL}
                apiKey={ADDRESS_FORM_API_KEY}
                countryISOCode={'GB'}
                validationSource={'default-validation-schema'}
                defaultInitialValues={{}}
                extraInitialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    taxId: '',
                }}
            >
                <AddressFormFormik handleSubmitCallBack={handleSubmit}>
                    <Row gutter={[40, 0]}>
                        <Col span={18}>
                            <Row gutter={[30, 0]}>
                                <Col span={24}>
                                    <SectionTitle>Technical Details</SectionTitle>
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <FormField>
                                        <InputField fieldName="firstName" />
                                    </FormField>
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <FormField>
                                        <InputField fieldName="lastName" />
                                    </FormField>
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <FormField>
                                        <InputField fieldName="email" />
                                    </FormField>
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <FormField>
                                        <InputField fieldName="phoneNumber" />
                                    </FormField>
                                </Col>
                            </Row>
                            <Row gutter={[30, 0]}>
                                <Col span={24}>
                                    <SectionTitle>End User Company Details</SectionTitle>
                                </Col>
                            </Row>
                            <AddressFormFields />
                            <Row gutter={[30, 0]}>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <SectionTitle>Tax Details</SectionTitle>
                                    <FormField>
                                        <InputField fieldName={'taxId'} />
                                    </FormField>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <QuoteSummary />
                        </Col>
                    </Row>
                </AddressFormFormik>
            </AddressFormProvider>
        </Col>
    );
};

export default ContactDetails;

import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IQuote} from '../interfaces/quote/quote.interface';
import getOneQuote from '../api/quote-builder/get-one-quote';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {useSelector} from 'react-redux';

interface IUseFetchQuote {
    (quoteUuid?: string): {
        isLoading: boolean;
        isError: boolean;
        quote: IQuote;
        setQuote: Dispatch<SetStateAction<IQuote>>;
        doRefresh: () => void;
    };
}

const initialState: IQuote = {
    organisation: '', // Todo: set to logged in user organisation
    user: '', // Todo: set to logged in user uuid
    trackingId: '',
    status: 'PENDING',
    endUserCompanyDetails: null,
    endUserContactDetails: null,
    carts: [],
};

const useFetchQuote: IUseFetchQuote = (quoteUuid) => {
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0WithCypress();
    // dispatch setQuote
    const [quote, setQuote] = useState<IQuote>(initialState);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const organisation = useSelector(
        (state: {authorization: {organisation: string}}) => state.authorization.organisation
    );

    useEffect(() => {
        if (!isAuthenticated || !organisation) return;
        const fetchQuote = async () => {
            if (!quoteUuid && user) {
                setQuote((prevState) => ({...prevState, organisation, user: user.sub!}));
                return;
            }
            try {
                getAccessTokenSilently().then((accessToken) => {
                    getOneQuote(quoteUuid!, accessToken, organisation).then((result) => {
                        setQuote(result);
                    });
                });
            } catch (error) {
                setIsError(true);
            }
        };
        setIsError(false);
        setIsLoading(true);
        fetchQuote().then(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation, isAuthenticated, refresh, quoteUuid, user]);

    const doRefresh = () => setRefresh(!refresh);

    return {quote, setQuote, isLoading, isError, doRefresh};
};

export default useFetchQuote;

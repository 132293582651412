import React, {FC, useEffect, useState} from 'react';
import {Button, Table, TableHeader, TableCell} from '@adaptavist/component-library';
import {Col, Row, Select, Alert} from 'antd';
import {
    IAccountChangeOption,
    ISENUpgrade,
} from '../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface';
import {toCapitalised} from '../../../utilities/switch-case';
import {useDispatch, useSelector} from 'react-redux';
import {IQuote} from '../../../interfaces/quote/quote.interface';
import {getOneCartByType} from '../../../actions/cart-actions';
import Supplier from '../../../enums/supplier';
import {addToCartDone, replaceCart} from '../../../redux/actions/quote-actions';
import {addAtlassianUpgrade} from '../../../api/carts/atlassian/hamlet/add-atlassian-upgrade';
import {UpgradeLicenseTableHeaderTitle} from '../../../data/table-headers';
import {getNewAtlassianCart} from '../../../api/carts/atlassian/hamlet/get--new-atlassian-cart';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';

const {Option} = Select;

interface IQuoteLicenseUpgradePreviewProps {
    upgrade: ISENUpgrade[];
    setUpgrade: (upgrade: ISENUpgrade[] | null) => void;
}

interface IOption {
    value: string;
    text: string;
}

const QuoteLicenseUpgradePreview: FC<IQuoteLicenseUpgradePreviewProps> = ({upgrade, setUpgrade}) => {
    const carts = useSelector((state: {quote: IQuote}) => state.quote.carts);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch();

    const {getOrganisationTier} = useOrganisation();

    const [type, setType] = useState<string | null>('Server');
    const [changeOptions, setChangeOptions] = useState<IAccountChangeOption[] | null>(null);
    const [options, setOptions] = useState<IOption[] | null>(null);
    const [selectedVerificationHash, setSelectedVerificationHash] = useState<string | null>(null);

    useEffect(() => {
        if (!type) return;
        const foundOptions = upgrade.find((u) => u.type === type);
        const changeOptions = foundOptions?.changeOptions?.[0];
        if (!changeOptions) {
            setError('Missing upgrade options for this SEN');
            return;
        }
        setChangeOptions(changeOptions);
        setOptions(
            changeOptions.map((co) => ({
                value: co.verificationHash,
                text: `${co.editionDescription} - ${co.maintenanceMonths} Months`,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const createNewCartAddItem = async (changeOption: IAccountChangeOption) => {
        if (!type) return;
        const newCart = await getNewAtlassianCart({mac: 'UK'});
        const payload = {cartID: newCart.uuid, accountChangeOption: changeOption, type, mac: 'UK'};
        const newCartWithItem = await addAtlassianUpgrade(payload, getOrganisationTier());
        dispatch(addToCartDone(newCartWithItem));
    };

    const addItem = async (cartUuid: string, changeOption: IAccountChangeOption) => {
        if (!type) return;
        const payload = {cartID: cartUuid, accountChangeOption: changeOption, type, mac: 'UK'};
        const newCart = await addAtlassianUpgrade(payload, getOrganisationTier());
        dispatch(replaceCart(newCart));
    };

    const addUpgradeToQuote = async () => {
        if (!selectedVerificationHash || !changeOptions) return;
        const changeOption = changeOptions.find((co) => co.verificationHash === selectedVerificationHash);
        const cart = getOneCartByType(carts, Supplier.atlassian);
        if (!cart) {
            createNewCartAddItem(changeOption!).then(() => setUpgrade(null));
            return;
        }
        addItem(cart.uuid, changeOption!).then(() => setUpgrade(null));
    };

    const handleSelect = (value: string) => {
        setSelectedVerificationHash(value);
    };

    if (error) {
        return (
            <Row>
                <Col span={24}>
                    <Alert
                        type="error"
                        message={
                            <div>
                                <p>There was an error getting renew preview</p>
                                {error}
                            </div>
                        }
                    />
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col span={24}>
                <Table striped={true}>
                    <thead>
                        <tr>
                            {UpgradeLicenseTableHeaderTitle.map((header, index) => (
                                <TableHeader key={`${header.title}-${index}`} align={header.align}>
                                    {header.title}
                                </TableHeader>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <TableCell align="left">
                                <Row gutter={[0, 10]}>
                                    {upgrade
                                        .filter((u) => ![''].includes(u.type))
                                        .map((u, index) => (
                                            <Col span={12} key={`${index}-upgrade`}>
                                                <Button ghost={u.type !== type} onClick={() => setType(u.type)}>
                                                    {toCapitalised(u.type)}
                                                </Button>
                                            </Col>
                                        ))}
                                </Row>
                            </TableCell>
                            <TableCell>
                                {options && (
                                    <Select style={{width: 230}} onChange={handleSelect} placeholder="Select a tier">
                                        {options.map((option, index) => (
                                            <Option key={`${option.text}-${index}`} value={option.value}>
                                                {option.text}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={addUpgradeToQuote} disabled={!selectedVerificationHash}>
                                    Add to Quote
                                </Button>
                            </TableCell>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default QuoteLicenseUpgradePreview;

import React, {FC} from 'react';
import {Col, Row} from 'antd';
import {Button, FormField, Label, SectionTitle} from '@adaptavist/component-library';
import {Input} from '@adaptavist/component-library';
import {Form, Formik} from 'formik';
import {object, string} from 'yup';
import IProductLookupBySen from '../../interfaces/forms/product-look-up-by-sen';

const productLookUpBySenSchema = object().shape({
    sen: string().required('SEN is required'),
    technicalContact: string().required('Technical contact is required'),
});

const initialProductLookUpBySenData = {
    sen: '',
    technicalContact: '',
};
const ProductLookupBySen: FC<{isSearching: boolean; handleSubmit: (values: IProductLookupBySen) => void}> = ({
    isSearching,
    handleSubmit,
}) => {
    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={productLookUpBySenSchema}
            initialValues={initialProductLookUpBySenData}
        >
            {({errors, touched, values, handleChange, handleSubmit}) => (
                <Form>
                    <Row>
                        <Col span={24}>
                            <SectionTitle>Company Details</SectionTitle>
                        </Col>
                    </Row>
                    <Row gutter={[20, 0]} align="middle">
                        <Col xs={20} sm={20} md={10} xl={10}>
                            <FormField>
                                <Label>SEN</Label>
                                <Input
                                    type="text"
                                    addonBefore="SEN-"
                                    value={values.sen}
                                    name="sen"
                                    onChange={handleChange}
                                    errorMessage={touched.sen && errors.sen ? errors.sen : ''}
                                />
                            </FormField>
                        </Col>
                        <Col xs={20} sm={20} md={11} xl={11}>
                            <FormField>
                                <Label>Technical Contact</Label>
                                <Input
                                    type="email"
                                    value={values.technicalContact}
                                    name="technicalContact"
                                    onChange={handleChange}
                                    errorMessage={
                                        touched.technicalContact && errors.technicalContact
                                            ? errors.technicalContact
                                            : ''
                                    }
                                />
                            </FormField>
                        </Col>
                        <Col xs={20} sm={20} md={3} xl={3} style={{paddingBottom: '12px'}}>
                            <FormField>
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                    loading={isSearching}
                                    disabled={isSearching}
                                >
                                    Find
                                </Button>
                            </FormField>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default ProductLookupBySen;

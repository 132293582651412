import {all, call} from 'redux-saga/effects';
import {onAddToCartByType} from './add-item-to-quote-by-type.saga';
import {onGenerateQuote} from './generate-quote.saga';
import {onGetAllQuotes, onGetMoreQuotes} from './get-all-quotes.saga';
import {onDelete} from './delete-quote.saga';

export function* quoteSagas() {
    yield all([
        call(onAddToCartByType),
        call(onGenerateQuote),
        call(onGetAllQuotes),
        call(onDelete),
        call(onGetMoreQuotes),
    ]);
}

import React from 'react';
import ReactDOM from 'react-dom';
import {AppState, Auth0WithCypressProvider} from '@adaptavist-commerce/auth0-with-cypress-react';
import {Router} from 'react-router-dom';
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from './constants/auth0.constants';
import {CHELL_API_URL, CHELL_API_KEY} from './constants/api.constants';
import history from './utilities/history';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import reduxStore from './redux/redux.store';
import * as Sentry from '@sentry/react';
import {SentryDNS} from './constants/api.constants';
import 'antd/dist/antd.css';
import './index.css';
import {OrganisationProvider} from '@adaptavist-commerce/organisation-context-react';
import {AnalyticsProvider} from '@adaptavist-commerce/analytics-provider-react';
import {ReduxOrganisationProvider} from './context/redux-context';
import {segmentCode} from './constants/segment.constants';
import {ENVIRONMENT} from './constants/api.constants';

const onRedirectCallback = (appState: AppState) => {
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({dsn: SentryDNS, environment: process.env.NODE_ENV});
}

ReactDOM.render(
    <React.StrictMode>
        <AnalyticsProvider
            analyticKey={segmentCode!}
            currentEnvironment={ENVIRONMENT!}
            allowedEnvironments={['production', 'staging', 'stg', 'prod']}
        >
            <Auth0WithCypressProvider
                domain={AUTH0_DOMAIN!}
                clientId={AUTH0_CLIENT_ID!}
                redirectUri={window.location.origin}
                audience={AUTH0_AUDIENCE!}
                onRedirectCallback={onRedirectCallback}
                cypressTokenStoreName={'quoteAuth0Cypress'}
            >
                <Router history={history}>
                    <Provider store={reduxStore}>
                        <OrganisationProvider
                            chellApiUrl={CHELL_API_URL!}
                            chellApiKey={CHELL_API_KEY!}
                            initialOrganisation={null}
                        >
                            <ReduxOrganisationProvider>
                                <App />
                            </ReduxOrganisationProvider>
                        </OrganisationProvider>
                    </Provider>
                </Router>
            </Auth0WithCypressProvider>
        </AnalyticsProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {CHELL_API_URL, CHELL_API_KEY} from '../../../constants/api.constants';
import axios from 'axios';

const confirmInvite = async (accessToken: string, token: string) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': CHELL_API_KEY
    };

    return await axios.post(`${CHELL_API_URL}/user/confirm-invite/${token}`, {}, {headers});
};

export default confirmInvite;

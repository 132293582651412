import React, {Fragment, useState} from 'react';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
// COMPONENTS
import {Button} from '@adaptavist/component-library';
import {Popover, Row, Col, Badge} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {DropdownContent, StyledDivider, StyledLinkList, StyledAvatar, StyledDetails} from './user-avatar.style';
// STYLES
import './user-avatar.css';
import {useOrganisation, IOrganisation} from '@adaptavist-commerce/organisation-context-react';
import AnalyticsLink from '../../analytics-components/analytics-link';
import {ViewPagePermissions} from '../../enums/permissions';

const UserAvatar = () => {
    const {logout, user, loginWithRedirect, isAuthenticated} = useAuth0WithCypress();
    const logoutWithRedirect = () => {
        logout({
            returnTo: window.location.origin,
        });
        setMenuVisible(false);
    };

    const {organisation, userOrganisationDetails, setOrganisation, hasPermission} = useOrganisation();

    const [menuVisible, setMenuVisible] = useState(false);

    const selectOrganisation = (organisation: IOrganisation) => () => {
        setOrganisation(organisation.organisation);
    };

    const Content = (
        <Fragment>
            <DropdownContent>
                <Row gutter={[32, 0]}>
                    <Col span={20}>{user && <StyledDetails>{user.email}</StyledDetails>}</Col>
                </Row>
            </DropdownContent>
            <StyledDivider />
            <StyledLinkList>
                {hasPermission(ViewPagePermissions.quoteUserList) ? (
                    <li key={'Manage users'} onClick={() => setMenuVisible(false)}>
                        <AnalyticsLink
                            route={`/users`}
                            userAction={{
                                event: 'Clicked Navbar',
                                properties: {item: 'Manage users', link: `/users`},
                            }}
                        >
                            Manage users
                        </AnalyticsLink>
                    </li>
                ) : null}
                {hasPermission(ViewPagePermissions.quoteManageOrganisation) ? (
                    <li key={'Manage organization'} onClick={() => setMenuVisible(false)}>
                        <AnalyticsLink
                            route={`/organisation`}
                            userAction={{
                                event: 'Clicked Navbar',
                                properties: {item: 'Manage organization', link: `/organisation`},
                            }}
                        >
                            Manage organisation
                        </AnalyticsLink>
                    </li>
                ) : null}
            </StyledLinkList>
            {userOrganisationDetails ? (
                <Fragment>
                    <DropdownContent>
                        <Row gutter={[32, 0]}>
                            <Col span={20}>
                                <StyledDetails>Organisation</StyledDetails>
                            </Col>
                        </Row>
                    </DropdownContent>
                    <StyledDivider />
                    <StyledLinkList hideBackground>
                        {userOrganisationDetails.map((cvd: any) => {
                            return (
                                <li key={`${cvd.organisation}-list`} onClick={selectOrganisation(cvd)}>
                                    {cvd.companyName}
                                    <Badge
                                        count={cvd.organisation === organisation ? 'Active' : '0'}
                                        style={{backgroundColor: '#52c41a', marginLeft: '10px', fontSize: '10px'}}
                                    />
                                </li>
                            );
                        })}
                    </StyledLinkList>
                </Fragment>
            ) : null}
            <StyledDivider />
            <StyledLinkList>
                <li onClick={logoutWithRedirect}>Log out</li>
            </StyledLinkList>
        </Fragment>
    );

    return (
        <Fragment>
            {isAuthenticated && (
                <Popover
                    placement="bottomRight"
                    content={Content}
                    trigger={['click']}
                    visible={menuVisible}
                    onVisibleChange={(e) => setMenuVisible(e)}
                >
                    <span onClick={() => setMenuVisible(!menuVisible)}>
                        <StyledAvatar
                            src={user && user.picture}
                            icon={<UserOutlined />}
                            size="large"
                            className="user-avatar"
                        />
                    </span>
                </Popover>
            )}
            {!isAuthenticated && (
                <Button ghost onClick={loginWithRedirect}>
                    Login
                </Button>
            )}
        </Fragment>
    );
};

export default UserAvatar;

import {ICart} from "../interfaces/quote/cart.interface";
import Supplier from "../enums/supplier";
import {IAtlassianCart} from "../interfaces/suppliers/atlassian/atlassian-cart.interface";

const getAtNumbersString = (carts: ICart[]): string => carts.reduce<string[]>((arr, c) =>
        c.type === Supplier.atlassian && (c as IAtlassianCart).lockedQuoteData
            ? [...arr, (c as IAtlassianCart).lockedQuoteData!.orderId]
            : arr,
    []).join(', ');


export default getAtNumbersString;

import {combineReducers} from 'redux';
// REDUCERS
import quoteReducer from './reducers/quote-reducer';
import authorizationReducer from './reducers/authorization.reducer';
import userReducer from './reducers/user.reducer';
import controlsReducer from './reducers/create-quote-controls.reducer';
import quoteViewReducer from './reducers/quote-view.reducer';
// STATE TYPES
import {IQuoteState} from './action-types/quote-types';
import {IAuthorizationState} from './action-types/authorization.types';
import {IUserState} from './action-types/user.types';
import {IControlsState} from './action-types/create-quote-controls.types';
import {IQuoteViewState} from './action-types/quote-view-types';
import rolesReducer from "./reducers/roles.reducer";
import {IRolesState} from "./action-types/roles.types";

export default combineReducers({
    roles: rolesReducer,
    quote: quoteReducer,
    authorization: authorizationReducer,
    user: userReducer,
    controls: controlsReducer,
    quoteView: quoteViewReducer,
});

export type ReduxStoreType = {
    roles: IRolesState
    quote: IQuoteState
    authorization: IAuthorizationState
    user: IUserState
    controls: IControlsState
    quoteView: IQuoteViewState
};

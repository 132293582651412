export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const CHELL_API_URL = process.env.REACT_APP_CHELL_API_URL;
export const CHELL_API_KEY = process.env.REACT_APP_CHELL_API_KEY;

export const PARTNER_PORTAL_API = process.env.REACT_APP_PARTNER_PORTAL_API || '';
export const PARTNER_PORTAL_API_KEY = process.env.REACT_APP_PARTNER_PORTAL_API_KEY || '';

export const OPPORTUNITY_TICKET_PREFIX: string = process.env.REACT_APP_OPPORTUNITY_TICKET_PREFIX || '';
export const QUOTE_BUILDER_API_URL: string = process.env.REACT_APP_QUOTE_BUILDER_API_URL || '';
export const QUOTE_BUILDER_API_KEY: string = process.env.REACT_APP_QUOTE_BUILDER_API_KEY || '';

export const HAMLET_URL: string = process.env.REACT_APP_HAMLET_URL || '';
export const HAMLET_API_KEY: string = process.env.REACT_APP_HAMLET_API_KEY || '';

export const ELASTIC_SEARCH_API: string = process.env.REACT_APP_ELASTIC_SEARCH_API || '';
export const SEARCH_MARKETPLACE_PRODUCTS: string = process.env.REACT_APP_SEARCH_MARKETPLACE_PRODUCTS || '';

export const XERO_API_URL: string = process.env.REACT_APP_XERO_API_URL || '';
export const XERO_API_KEY: string = process.env.REACT_APP_XERO_API_KEY || '';

export const XERO_ACCOUNT_UKSVC: string = process.env.REACT_APP_XERO_ACCOUNT_UKSVC || '';
export const XERO_ACCOUNT_UK: string = process.env.REACT_APP_XERO_ACCOUNT_UK || '';
export const XERO_ACCOUNT_US: string = process.env.REACT_APP_XERO_ACCOUNT_US || '';
export const XERO_ACCOUNT_CA: string = process.env.REACT_APP_XERO_ACCOUNT_CA || '';

export const XERO_INVOICE_URL: string = process.env.REACT_APP_XERO_INVOICE_URL || '';

export const FX_API_URL: string = process.env.REACT_APP_FX_API_URL || '';
export const FX_API_KEY: string = process.env.REACT_APP_FX_API_KEY || '';

export const TECH_CONTACTS_API_URL: string = process.env.REACT_APP_TECH_CONTACTS_API_URL || '';
export const TECH_CONTACTS_API_KEY: string = process.env.REACT_APP_TECH_CONTACTS_API_KEY || '';

export const SentryDNS = process.env.REACT_APP_SENTRY_DNS || '';

export const ADDRESS_FORM_API_URL: string = process.env.REACT_APP_ADDRESS_FORM_API_URL || '';
export const ADDRESS_FORM_API_KEY: string = process.env.REACT_APP_ADDRESS_FORM_API_KEY || '';

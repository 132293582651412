import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Col, Row} from 'antd';
import QuoteLicenseRenewalPreview from '../quote-license-previews/quote-license-renewal-preview';
import ProductLookupBySen from '../../forms/product-look-up-by-sen-form';
import IProductLookupBySen from '../../../interfaces/forms/product-look-up-by-sen';
import findAtlassianRenewal from '../../../api/carts/atlassian/hamlet/find-atlassian-renewal';
import {ISENRenewal} from '../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

export interface IState {
    renewal: null | ISENRenewal;
    errors: any[];
    error: boolean;
    isSearching: boolean;
}

const RenewLicenseForm = () => {
    const initialState = {
        renewal: null,
        errors: [],
        error: false,
        isSearching: false,
    };
    const [renewLicenseState, setRenewLicenseState] = useState<IState>(initialState);

    const {analytics, isAnalyticsInitialized} = useSegment();
    useEffect(() => {
        if (!isAnalyticsInitialized || !analytics) return;
        analytics.trackUserAction({
            event: 'Switched Tab',
            properties: {
                tab: 'Renew License',
            },
        });
    }, [analytics, isAnalyticsInitialized]);

    const handleSubmit = async (values: IProductLookupBySen) => {
        try {
            setRenewLicenseState({
                ...initialState,
                renewal: renewLicenseState.renewal,
                isSearching: true,
            });
            const response = await findAtlassianRenewal(values.technicalContact, values.sen, 'UK');
            if (!response.changeOptions?.[0]) {
                setRenewLicenseState({
                    ...initialState,
                    renewal: response,
                    error: true,
                    errors: ['Missing renewal options for this SEN'],
                    isSearching: false,
                });
                return;
            }
            setRenewLicenseState({
                ...initialState,
                renewal: response,
                isSearching: false,
            });
            if (!isAnalyticsInitialized || !analytics) return;
            const addonNames: string[] = [];
            const productKeys: string[] = [];
            const hostings: string[] = [];
            response.changeOptions.forEach((license) => {
                addonNames.push(license.productDescription);
                productKeys.push(license.productKey);
                hostings.push(license.licenseType);
            });
            analytics.trackUserAction({
                event: 'Searched License',
                properties: {
                    tab: 'Renew License',
                    addon_names: addonNames,
                    hostings: hostings,
                    product_keys: productKeys,
                    list_price: response.changeOptions?.[0].amount,
                    amount: response.changeOptions?.[0].amount,
                    quote_uuid: response.productKey,
                    tier: `${response.unitCount}`,
                },
            });
        } catch (error) {
            setRenewLicenseState({
                ...initialState,
                error: true,
                errors: ['Please check the details you provided and try again'],
                isSearching: false,
            });
        }
    };

    const setRenewal = (renewal: null | ISENRenewal) => {
        setRenewLicenseState({
            ...renewLicenseState,
            renewal: renewal,
        });
    };

    const {isSearching, errors, error, renewal} = renewLicenseState;

    return (
        <Fragment>
            <Row>
                <Col span={24}>
                    <ProductLookupBySen isSearching={isSearching} handleSubmit={handleSubmit} />
                </Col>
                {renewal && renewal.changeOptions?.[0] && !error && (
                    <Col span={24}>
                        <QuoteLicenseRenewalPreview renewal={renewal} setRenewal={setRenewal} />
                    </Col>
                )}
                {error && (
                    <Col span={24}>
                        <Alert
                            type="error"
                            message={
                                <div>
                                    <p>There was an error getting renew preview</p>
                                    {errors.map((e: string) => (
                                        <p>{e}</p>
                                    ))}
                                </div>
                            }
                        />
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};

export default RenewLicenseForm;

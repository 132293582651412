import axios from 'axios';
import {PARTNER_PORTAL_API, PARTNER_PORTAL_API_KEY} from '../../constants/api.constants';
import {IQuoteResponse} from '../../interfaces/quote/responses/quote-response.interface';
import {IQuote} from '../../interfaces/quote/quote.interface';
import {quoteFactory} from '../../factories/quote.factory';

const getOneQuote = async (uuid: string, accessToken: string, organisation: string): Promise<IQuote> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': PARTNER_PORTAL_API_KEY,
    };

    const response = await axios.get<IQuoteResponse>(
        `${PARTNER_PORTAL_API}/organisations/${organisation}/quotes/${uuid}`,
        {
            headers,
        }
    );

    return quoteFactory(response.data);
};

export default getOneQuote;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
// COMPONENTS
import {Button, PanelBody, PanelHeader, PanelTitle, PanelWithBorder} from '@adaptavist/component-library';
import {Col, Row, Alert} from 'antd';
import {CheckCircleFilled, FilePdfFilled, SyncOutlined} from '@ant-design/icons';
import {EmptyPageWrapper} from '../../structure/empty-page-wrapper';
// ACTIONS
import {resetQuote} from '../../../redux/actions/quote-actions';
import {resetStep} from '../../../redux/actions/create-quote-controls.actions';
// OTHERS - functions
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';
import {ReduxStoreType} from '../../../redux/root.reducer';
import getAtNumbersString from '../../../utilities/get-at-numbers-string';
import getQuotePdf from '../../../api/quote-builder/get-quote-pdf';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {ActionPermissions, ViewPagePermissions} from '../../../enums/permissions';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import getQuoteUrl from '../../../api/quote-builder/get-quote-url';

const colSize = {
    xs: {span: 24, offset: 0},
    sm: {span: 24, offset: 0},
    md: {span: 10, offset: 7},
};

const QuoteConfirmation = () => {
    const callReduxAction = useDispatch();
    const resetQuoteBuilder = () => {
        callReduxAction(resetQuote());
        callReduxAction(resetStep());
    };

    const {hasPermission} = useOrganisation();

    const {getAccessTokenSilently} = useAuth0WithCypress();

    const {quote, organisation} = useSelector((state: ReduxStoreType) => ({
        quote: state.quote,
        organisation: state.authorization.organisation,
    }));

    const [quoteUrl, setQuoteUrl] = useState<string>();

    const {isAnalyticsInitialized, analytics} = useSegment();
    useEffect(() => {
        if (!isAnalyticsInitialized || !quote || !quote.trackingId) return;

        const hostings: string[] = [];
        const productKeys: string[] = [];
        const addonNames: string[] = [];
        quote.carts.forEach((cart) => {
            cart.items.forEach((item) => {
                hostings.push(item.service || '');
                productKeys.push(item.uuid);
                addonNames.push(item.name);
            });
        });

        const quoteTotalPrice = quote.carts.reduce<number>((total, c) => {
            c.items.map((itemPrice: any) => (total += itemPrice.price));
            return total;
        }, 0);
        const totalDiscountSavings = quote.carts.reduce<number>((total, c) => {
            c.items.map((item: any) => (total += item.totalDiscount));
            return total;
        }, 0);
        analytics.trackUserAction({
            event: 'Generated Quote',
            properties: {
                addon_names: addonNames,
                hostings: hostings,
                product_keys: productKeys,
                list_price: quoteTotalPrice,
                tracking_id: quote.trackingId,
                discount: totalDiscountSavings,
            },
        });
    }, [quote, isAnalyticsInitialized, analytics]);

    useEffect(() => {
        (async () => {
            if (!organisation || quoteUrl || !hasPermission(ActionPermissions.quoteDownload)) return;
            const accessToken = await getAccessTokenSilently();
            const timer = setInterval(async () => {
                if (!quoteUrl) {
                    if (!quote.ticketCode) return;
                    const quoteData = await getQuoteUrl(quote.ticketCode, accessToken, organisation);
                    if (quoteData.s3Url) {
                        setQuoteUrl(quoteData.s3Url);
                        clearInterval(timer);
                    }
                }
            }, 1000);
        })();
    }, [quote, quoteUrl, hasPermission, getAccessTokenSilently, organisation]);

    const handlePdfDownload =
        (url: string, ticketCode: string, trackingId: string) => async (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            const blob = await getQuotePdf(url);
            const objectUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.setAttribute('download', `AVST-${ticketCode}-Proforma.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if (!analytics || !trackingId) return;
            analytics.trackUserAction({
                event: 'Downloaded Quote',
                properties: {
                    tracking_id: trackingId,
                },
            });
        };

    return (
        <EmptyPageWrapper height="60vh">
            <Row gutter={[0, 40]} style={{textAlign: 'center'}}>
                <Col xs={colSize.xs} sm={colSize.sm} md={colSize.md}>
                    <CheckCircleFilled style={{color: '#52C41A', fontSize: '50px'}} />
                    <h2>Quote Created Successfully</h2>
                </Col>
                <Col xs={colSize.xs} sm={colSize.sm} md={colSize.md}>
                    <PanelWithBorder style={{padding: '35px 0px'}}>
                        <PanelHeader paddingBottom={2}>
                            <PanelTitle>Adaptavist Quote</PanelTitle>
                        </PanelHeader>
                        <PanelBody>
                            <p>Your quote is ready.</p>
                            {hasPermission(ViewPagePermissions.quoteViewAtNumber) ? (
                                <p>
                                    <strong>AT No.</strong> {getAtNumbersString(quote.carts)}
                                </p>
                            ) : (
                                ''
                            )}
                            <p>Get the document by clicking the button below</p>
                            {hasPermission(ActionPermissions.quoteDownload) ? (
                                quoteUrl ? (
                                    <Button
                                        onClick={handlePdfDownload(quoteUrl!, quote.ticketCode!, quote.trackingId!)}
                                    >
                                        <FilePdfFilled style={{fontSize: '15px', marginRight: '8px'}} />
                                        Download PDF
                                    </Button>
                                ) : (
                                    <Col xs={colSize.xs} sm={colSize.sm} md={colSize.md}>
                                        <Alert
                                            message={
                                                <div>
                                                    <SyncOutlined spin style={{marginRight: '10px'}} />
                                                    Getting download ready. Please wait...
                                                </div>
                                            }
                                            type="info"
                                        />
                                    </Col>
                                )
                            ) : (
                                ''
                            )}
                        </PanelBody>
                    </PanelWithBorder>
                </Col>
                <Col xs={colSize.xs} sm={colSize.sm} md={colSize.md}>
                    <Button ghost onClick={resetQuoteBuilder} style={{margin: '0px 40px'}}>
                        Build Another Quote
                    </Button>
                    <Link to="/all-quotes">
                        <Button ghost onClick={resetQuoteBuilder} style={{margin: '0px 15px'}}>
                            View Your Quotes
                        </Button>
                    </Link>
                </Col>
            </Row>
        </EmptyPageWrapper>
    );
};

export default QuoteConfirmation;

import React, {FC, Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
// COMPONENTS
import {PageTitle} from '@adaptavist/component-library';
import {Col, Row} from 'antd';
import QuoteSummary from '../../components/create-quote/quote-summary/quote-summary';
import ContactDetails from '../../components/create-quote/contact-details/contact-details';
import QuoteForm from '../../components/create-quote/quote-form/quote-form';
import QuoteConfirmation from '../../components/create-quote/quote-confirmation/quote-confirmation';
import useFetchQuote from '../../hooks/use-fetch-quote';
import Stepper from './stepper';
// ACTIONS
import {replaceQuote} from '../../redux/actions/quote-actions';
import {createQuotePreviousStep, initializeCreateQuoteStep} from '../../redux/actions/create-quote-controls.actions';
// INTERFACES / TYPES
import {ReduxStoreType} from '../../redux/root.reducer';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {AppName} from '../../constants/metadata.constants';

const StepsWithContent = [
    {
        title: 'Get Started',
        content: <QuoteForm />,
    },
    {
        title: 'Contact Details',
        content: <ContactDetails />,
    },
    {
        title: 'Quote Confirmation',
        content: <QuoteConfirmation />,
    },
];

const CreateNewQuoteView: FC = () => {
    document.title = `Create Quote - ${AppName}`;
    const {uuid} = useParams<{uuid: string}>();
    const {quote: currentQuote} = useFetchQuote(uuid);
    const dispatch = useDispatch();
    const {organisation} = useOrganisation();
    // State
    const {stepper} = useSelector((state: ReduxStoreType) => ({
        stepper: state.controls.createQuoteStepper,
        quote: state.quote,
    }));

    useEffect(() => {
        const totalSteps = StepsWithContent.length;
        dispatch(initializeCreateQuoteStep({totalSteps, currentStep: 0}));
        if (!organisation) return;
        dispatch(replaceQuote(currentQuote));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuote]);

    const currentStep = stepper.currentStep;
    const endOfStepperIndex = stepper.totalSteps - 1;

    const quoteBuildingDone = stepper.currentStep === stepper.totalSteps - 1;

    return (
        <Fragment>
            <PageTitle>Quote Builder</PageTitle>
            <Row gutter={[40, 0]}>
                <Col span={quoteBuildingDone ? 24 : 18}>
                    <Stepper
                        endOfStepIndex={endOfStepperIndex}
                        currentStep={currentStep}
                        steps={StepsWithContent}
                        changeStep={() => dispatch(createQuotePreviousStep())}
                    />
                </Col>
                {StepsWithContent[currentStep].content}
                {!quoteBuildingDone && currentStep !== 1 && (
                    <Col span={6}>
                        <QuoteSummary />
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};

export default CreateNewQuoteView;

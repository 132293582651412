import React from 'react';
// COMPONENTS
import {Header, SiteLogoWrapper, NavLinkWrapper, NavLink} from '@adaptavist/component-library';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/marketplace_logo.svg';
import UserAvatar from '../user-avatar/user-avatar';
// OTHERS - constants
import AnalyticsLink from '../../analytics-components/analytics-link';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {ViewPagePermissions} from '../../enums/permissions';

export interface IAppHeader extends RouteComponentProps {}

const AppHeader = (props: IAppHeader) => {
    const {pathname} = props.history.location;
    const {hasPermission} = useOrganisation();
    return (
        <Header height="80px" style={{marginBottom: '4rem'}}>
            <AnalyticsLink
                route={'/'}
                userAction={{event: 'Clicked Navbar', properties: {item: 'App Logo', link: `/`}}}
            >
                <SiteLogoWrapper padding="0px 10px">
                    <Logo style={{height: '48', width: '240'}} />
                </SiteLogoWrapper>
            </AnalyticsLink>
            <NavLinkWrapper align="right">
                {hasPermission(ViewPagePermissions.quoteList) ? (
                    <AnalyticsLink
                        route={`/all-quotes`}
                        userAction={{
                            event: 'Clicked Navbar',
                            properties: {item: 'View all Quotes', link: `/all-quotes`},
                        }}
                    >
                        <NavLink isActive={pathname === `/all-quotes`}>View All Quotes</NavLink>
                    </AnalyticsLink>
                ) : null}
                {hasPermission(ViewPagePermissions.quoteCreate) ? (
                    <AnalyticsLink
                        route={`/quote/new`}
                        userAction={{event: 'Clicked Navbar', properties: {item: 'Create Quote', link: `/quote/new`}}}
                    >
                        <NavLink isActive={pathname === `/quote/new`}>Create Quote</NavLink>
                    </AnalyticsLink>
                ) : null}
                <NavLink>
                    <UserAvatar />
                </NavLink>
            </NavLinkWrapper>
        </Header>
    );
};

export default withRouter(AppHeader);

export const monthDifferenceFromDate = (fromDate: Date, toDate: Date) => {
    const fromMonth = fromDate.getMonth() + 1;
    const fromYear = fromDate.getFullYear();
    const toMonth = toDate.getMonth() + 1;
    const toYear = toDate.getFullYear();
    return monthDifference(fromMonth, fromYear, toMonth, toYear);
};

export const monthDifference = (fromMonth: number, fromYear: number, toMonth: number, toYear: number) => {
    const yearDifference = (toYear - fromYear) * 12;
    return toMonth - fromMonth + yearDifference;
};


import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import getOneOrganisation from '../../../api/chell/organisation/get-one-organisation';
import updateOrganisation from '../../../api/chell/organisation/update-organisation-details';
import {IOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {IAuthorizationState} from '../../action-types/authorization.types';
import {
    IGetOrganisationDetailsStartAction,
    IUpdateOrganisationStartAction,
    UserActionTypes,
} from '../../action-types/user.types';
import {getOrganisationDetailsDone, updateOrganisationDetailsDone} from '../../actions/user.actions';
import {ReduxStoreType} from '../../root.reducer';

export function* getOrganisationData({payload}: IGetOrganisationDetailsStartAction) {
    const {organisation} = payload;
    const {authorization}: {authorization: IAuthorizationState} = yield select();
    try {
        if (!authorization.organisation) return;
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();
        const response: IOrganisation = yield getOneOrganisation(accessToken, organisation, authorization.organisation);
        yield put(
            getOrganisationDetailsDone({
                organisationData: {
                    contactDetails: {
                        companyName: response.companyName || '',
                        addressLineOne: response.contactDetails?.addressLineOne || '',
                        addressLineTwo: response.contactDetails?.addressLineTwo || '',
                        city: response.contactDetails?.city || '',
                        region: response.contactDetails?.region || '',
                        postalCode: response.contactDetails?.postalCode || '',
                        isoCountryCode: response.contactDetails?.isoCountryCode || '',
                        taxId: response.taxId || '',
                    },
                    tier: {
                        discount: response.tier?.discount || 0,
                        name: response.tier?.name || '',
                    },
                },
            })
        );
    } catch (error) {
        yield put(
            getOrganisationDetailsDone({
                errorGettingData: true,
                errorMessage: error.response || 'There was an error getting organisation data. Please try again',
            })
        );
    }
}

export function* onGetOrganisationData() {
    yield takeLatest(UserActionTypes.GET_ORGANISATION_DETAILS_START, getOrganisationData);
}

/**
 * UPDATE ORGANISATION DATA SAGA
 */
export function* updateOrganisationData({payload}: IUpdateOrganisationStartAction) {
    const {contactDetails, trackUserAction} = payload;
    const {authorization, user}: ReduxStoreType = yield select();
    try {
        if (!authorization.organisation) return;
        const {organisation, getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();
        const {companyName, taxId, ...rest} = contactDetails;
        yield updateOrganisation(
            accessToken,
            organisation,
            {
                companyName,
                taxId,
                contactDetails: rest,
            },
            authorization.organisation
        );
        yield put(
            updateOrganisationDetailsDone({
                contactDetails: contactDetails,
            })
        );
        if (trackUserAction) {
            const modifiedFields: string[] = [];
            const initialContactDetails = user.organisation.organisationData?.contactDetails || {};
            for (const [key, value] of Object.entries(contactDetails)) {
                const foundChange = Object.entries(initialContactDetails).find(
                    ([initialKey, initialValue]) => value !== initialValue && key === initialKey
                );
                if (foundChange) {
                    modifiedFields.push(key);
                }
            }
            modifiedFields.forEach((field) => {
                trackUserAction({
                    event: 'Modified Organisation',
                    properties: {
                        field: field,
                    },
                });
            });
        }
    } catch (error) {
        yield put(
            updateOrganisationDetailsDone({
                errorSaving: true,
                savedChanges: false,
                errorMessage: 'There was an error making changes',
            })
        );
    }
}

export function* onUpdateOrganisationData() {
    yield takeLatest(UserActionTypes.UPDATE_ORGANISATION_DETAILS_START, updateOrganisationData);
}

// COMBINE ALL SAGAS
export function* onGetOrganisation() {
    yield all([call(onGetOrganisationData), call(onUpdateOrganisationData)]);
}

import React from 'react';
import {Tab, TabbedContainer} from '@adaptavist/component-library';
import {Row, Col} from 'antd';
import FindProduct from '../find-product/find-product';
import RenewLicenseForm from '../renew-license-form/renew-license-form';
import UpgradeLicenseForm from '../upgrade-license-form/upgrade-license-form';
import ItemList from '../../quote/item-list';

const Tabs = [
    {
        name: 'find_product',
        title: 'Find Product',
        content: <FindProduct />,
    },
    {
        name: 'renew_license',
        title: 'Renew License',
        content: <RenewLicenseForm />,
    },
    {
        name: 'upgrade_license',
        title: 'Upgrade License',
        content: <UpgradeLicenseForm />,
    },
];

const QuoteForm = () => {
    return (
        <Col span={18}>
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <TabbedContainer defaultTab="find_product">
                        {Tabs.map((tab, index) => (
                            <Tab key={`${tab.title}-${index}`} name={tab.name} title={tab.title}>
                                {tab.content}
                            </Tab>
                        ))}
                    </TabbedContainer>
                </Col>
                <Col span={24}>
                    <Row gutter={[0, 30]}>
                        <ItemList />
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default QuoteForm;

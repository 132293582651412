import QuoteStatus from '../../enums/quote-status';
import {IQuoteState, QuoteActionTypes} from '../action-types/quote-types';
import {v4 as uuidv4} from 'uuid';

export const initialState: IQuoteState = {
    uuid: '',
    user: '',
    organisation: '',
    trackingId: '',
    status: QuoteStatus.pending,
    carts: [],
    addingToCart: false,
    errorAddingToCart: false,
    selectedProduct: null,
    generatingQuote: false,
    errorGeneratingQuote: false,
    errorMessageGeneratingQuote: null,
    endUserContactDetails: null,
    endUserCompanyDetails: null,
};

const quoteReducer = (state = initialState, action: any): IQuoteState => {
    switch (action.type) {
        case QuoteActionTypes.REPLACE_QUOTE:
            return action.payload;
        case QuoteActionTypes.RESET_QUOTE:
            return initialState;
        case QuoteActionTypes.CREATE_QUOTE:
            return {
                ...initialState,
                user: action.payload.user,
                organisation: action.payload.organisation,
            };

        case QuoteActionTypes.SET_SELECTED_PRODUCT: {
            const newTrackingId = uuidv4();
            return {
                ...state,
                trackingId: newTrackingId,
                selectedProduct: action.payload,
            };
        }

        case QuoteActionTypes.ADD_TO_CART_BY_TYPE_START:
            return {
                ...state,
                errorAddingToCart: false,
                addingToCart: true,
            };
        case QuoteActionTypes.ADD_TO_CART_DONE:
            return {
                ...state,
                carts: [...state.carts, action.payload],
                errorAddingToCart: false,
                addingToCart: false,
            };
        case QuoteActionTypes.ADD_TO_CART_FAILED:
            return {
                ...state,
                addingToCart: false,
                errorAddingToCart: true,
            };

        case QuoteActionTypes.REMOVE_FROM_CART:
            return {
                ...state,
                carts: state.carts.filter((c) => c.uuid !== action.payload),
            };
        case QuoteActionTypes.REPLACE_CART:
            return {
                ...state,
                carts: state.carts.map((c) => (c.uuid !== action.payload.uuid ? c : action.payload)),
                errorAddingToCart: false,
                addingToCart: false,
            };
        case QuoteActionTypes.ADD_ITEM_BY_UUID:
            return {
                ...state,
                carts: state.carts.map((c) =>
                    c.uuid === action.payload.cartUuid
                        ? {
                              ...c,
                              items: [...c.items, action.payload.item],
                          }
                        : c
                ),
            };
        case QuoteActionTypes.ADD_ITEM_BY_TYPE:
            const newCarts = [];
            while (state.carts.length) {
                const currentCart = state.carts.shift();
                if (!currentCart) continue;
                if (currentCart!.type === action.payload.type) {
                    currentCart!.items = [...currentCart!.items, action.payload];
                    newCarts.push(currentCart);
                    break;
                }
                newCarts.push(currentCart);
            }
            newCarts.push(...state.carts);

            return {
                ...state,
                carts: newCarts,
            };
        case QuoteActionTypes.REMOVE_ITEM:
            return {
                ...state,
                carts: state.carts.map((c) =>
                    c.uuid === action.payload.cartUuid
                        ? {
                              ...c,
                              items: c.items.filter((i) => i.uuid !== action.payload.item.uuid),
                          }
                        : c
                ),
            };
        case QuoteActionTypes.REPLACE_ITEM:
            return {
                ...state,
                carts: state.carts.map((c) =>
                    c.uuid !== action.payload.cartUuid
                        ? c
                        : {
                              ...c,
                              items: c.items.map((i) =>
                                  i.uuid !== action.payload.item.uuid ? i : action.payload.item
                              ),
                          }
                ),
            };
        case QuoteActionTypes.GENERATE_QUOTE_START:
            return {
                ...state,
                generatingQuote: true,
                errorGeneratingQuote: false,
                errorMessageGeneratingQuote: null,
                endUserContactDetails: action.payload.endUserContactDetails,
                endUserCompanyDetails: action.payload.endUserCompanyDetails,
            };

        case QuoteActionTypes.GENERATE_QUOTE_DONE:
            return {
                ...state,
                generatingQuote: false,
                ...action.payload,
            };

        default:
            return state;
    }
};

export default quoteReducer;

import {ControlsActions, IControlsState, CreateQuoteControlsTypes} from '../action-types/create-quote-controls.types';
import {checkStepperRange} from '../../utilities/controls-function';

export const INITIAL_STATE: IControlsState = {
    createQuoteStepper: {
        totalSteps: 0,
        startingStep: 0,
        currentStep: 0,
    },
};

const controlsReducer = (state = INITIAL_STATE, action: ControlsActions) => {
    switch (action.type) {
        case CreateQuoteControlsTypes.INITIALIZE_STEPPER:
            return {
                ...state,
                createQuoteStepper: {
                    ...state.createQuoteStepper,
                    ...action.payload,
                },
            };
        case CreateQuoteControlsTypes.NEXT_STEP:
            // Decide on getting the current step from payload or state
            const nextStep =
                action.payload?.currentStep !== undefined
                    ? action.payload?.currentStep
                    : state.createQuoteStepper.currentStep + 1;
            // Get new current step from function after check
            const newNextStep = checkStepperRange({
                totalSteps: state.createQuoteStepper.totalSteps,
                currentStep: nextStep,
            });
            return {
                ...state,
                createQuoteStepper: {
                    ...state.createQuoteStepper,
                    ...action.payload,
                    currentStep: newNextStep,
                },
            };
        case CreateQuoteControlsTypes.PREVIOUS_STEP:
            //Decide on getting the previous step from payload or state
            const previousStep =
                action.payload?.currentStep !== undefined
                    ? action.payload?.currentStep
                    : state.createQuoteStepper.currentStep - 1;
            // Get new current step from function after check
            const newPreviousStep = checkStepperRange({
                totalSteps: state.createQuoteStepper.totalSteps,
                currentStep: previousStep,
            });
            return {
                ...state,
                createQuoteStepper: {
                    ...state.createQuoteStepper,
                    ...action.payload,
                    currentStep: newPreviousStep,
                },
            };

        case CreateQuoteControlsTypes.RESET_STEP:
            return {
                ...state,
                createQuoteStepper: {
                    ...state.createQuoteStepper,
                    currentStep: 0,
                },
            };

        default:
            return state;
    }
};

export default controlsReducer;

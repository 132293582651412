export enum ViewPagePermissions {
    quoteCreate = 'q:view-page-quote-create',
    quoteList = 'q:view-page-quote-list',
    quoteSuccess = 'q:view-page-success',
    quoteUserList = 'q:view-page-user-list',
    quoteManageOrganisation = 'q:view-page-manage-organisation',
    quoteInviteUser = 'q:view-page-invite-user',
    quoteEditUser = 'q:view-page-edit-user',
    quoteViewAtNumber = 'q:view-at-number',
}

export enum ActionPermissions {
    quoteDelete = 'q:quote-delete',
    quoteDownload = 'q:quote-download',
}

import styled from 'styled-components';
import {Divider, Avatar} from 'antd';

export const DropdownContent = styled.div`
    padding: 0 16px;
`;

export const StyledDivider = styled(Divider)`
    margin: 10px 0;
`;

export const StyledLinkList = styled.ul<{hideBackground?: boolean}>`
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    & li {
        margin: 5px 0;
        padding: 6px 16px;
        font-size: 17px;
        &:hover,
        &:focus {
            background-color: ${(props) => (props.hideBackground ? '#ffffff' : '#ecf1ff')};
            cursor: pointer;
        }
        & a {
            color: #000000;
            text-decoration: none;
        }
    }
`;

export const StyledDetails = styled.p`
    color: gray;
    margin: 10px 0;
`;

export const StyledAvatar = styled(Avatar)`
    &:active {
        border: 3.2px solid #ecf1ff;
        cursor: pointer;
    }
`;

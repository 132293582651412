import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {getRolesAPI} from '../../api/chell/roles/roles';
import {getRolesDone} from '../actions/roles.actions';
import {ReduxStoreType} from '../root.reducer';
import {RolesActionTypes} from '../action-types/roles.types';
import IRole from '../../interfaces/rbac/role/role.interface';

export function* getAllRoles() {
    const {authorization}: ReduxStoreType = yield select();
    try {
        if (!authorization.organisation) return;
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();
        const response: IRole[] = yield getRolesAPI(accessToken, authorization.organisation);
        yield put(
            getRolesDone({
                data: response,
            })
        );
    } catch (error) {
        yield put(
            getRolesDone({
                data: null,
            })
        );
    }
}

export function* onGetAllRoles() {
    yield takeLatest(RolesActionTypes.GET_ROLES_START, getAllRoles);
}

export function* rolesSagas() {
    yield all([call(onGetAllRoles)]);
}

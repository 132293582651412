import React, {useEffect, useState} from 'react';
import {Alert, Col, Row} from 'antd';
import IProductLookupBySen from '../../../interfaces/forms/product-look-up-by-sen';
import ProductLookupBySen from '../../forms/product-look-up-by-sen-form';
import findAtlassianUpgrade from '../../../api/carts/atlassian/hamlet/find-atlassian-upgrade';
import {ISENUpgrade} from '../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface';
import QuoteLicenseUpgradePreview from '../quote-license-previews/quote-license-upgrade-preview';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

export interface IState {
    upgrade: null | ISENUpgrade[];
    errors: any[];
    error: boolean;
    isSearching: boolean;
}

const UpgradeLicenseForm = () => {
    const initialState = {
        upgrade: null,
        errors: [],
        error: false,
        isSearching: false,
    };
    const [upgradeLicenseState, setUpgradeLicenseState] = useState<IState>(initialState);

    const {analytics, isAnalyticsInitialized} = useSegment();
    useEffect(() => {
        if (!analytics || !isAnalyticsInitialized) return;
        analytics.trackUserAction({
            event: 'Switched Tab',
            properties: {
                tab: 'Upgrade License',
            },
        });
    }, [analytics, isAnalyticsInitialized]);

    const handleSubmit = async (values: IProductLookupBySen) => {
        try {
            setUpgradeLicenseState({
                ...initialState,
                upgrade: upgradeLicenseState.upgrade,
                isSearching: true,
            });
            const response = await findAtlassianUpgrade(values.technicalContact, values.sen, 'UK');
            setUpgradeLicenseState({
                ...initialState,
                upgrade: response,
                isSearching: false,
            });
            if (!isAnalyticsInitialized || !analytics) return;
            const addonNames: string[] = [];
            const productKeys: string[] = [];
            const hostings: string[] = [];
            response.forEach((license) => {
                addonNames.push(license.title);
                hostings.push(license.type);
            });
            analytics.trackUserAction({
                event: 'Searched License',
                properties: {
                    tab: 'Upgrade License',
                    addon_names: addonNames,
                    hostings: hostings,
                    product_keys: productKeys,
                    list_price: '',
                    amount: '',
                    quote_uuid: '',
                    tier: '',
                },
            });
        } catch (error) {
            setUpgradeLicenseState({
                ...initialState,
                error: true,
                errors: ['Please check the details you provided and try again'],
                isSearching: false,
            });
        }
    };

    const setUpgrade = (upgrade: null | ISENUpgrade[]) => {
        setUpgradeLicenseState({
            ...upgradeLicenseState,
            upgrade: upgrade,
        });
    };

    const {isSearching, upgrade, error, errors} = upgradeLicenseState;

    return (
        <Row>
            <Col span={24}>
                <Col span={24}>
                    <ProductLookupBySen isSearching={isSearching} handleSubmit={handleSubmit} />
                </Col>
            </Col>
            {upgrade && !error && (
                <Col span={24}>
                    <QuoteLicenseUpgradePreview upgrade={upgrade} setUpgrade={setUpgrade} />
                </Col>
            )}
            {error && (
                <Col span={24}>
                    <Alert
                        type="error"
                        message={
                            <div>
                                <p>There was an error getting upgrade preview</p>
                                {errors.map((e: string) => (
                                    <p>{e}</p>
                                ))}
                            </div>
                        }
                    />
                </Col>
            )}
        </Row>
    );
};

export default UpgradeLicenseForm;

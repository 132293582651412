import axios from 'axios';
import {HAMLET_API_KEY, HAMLET_URL} from '../../../../constants/api.constants';
import IAtlassianCartResponse
    from '../../../../interfaces/suppliers/atlassian/responses/atlassian-cart-response.interface';
import {atlassianNewCartFactory} from '../../../../factories/suppliers/atlassian/atlassian-new-cart.factory';
import {IAtlassianCart} from '../../../../interfaces/suppliers/atlassian/atlassian-cart.interface';
import {IAccountChangeOption} from '../../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface';
import {IOrganisationTier} from "../../../../interfaces/organisation/organisation.interface";


export interface IAddAtlassianUpgrade {
    cartID: string;
    accountChangeOption: IAccountChangeOption;
    mac: string;
    type: string;
}

export const addAtlassianUpgrade = async (payload: IAddAtlassianUpgrade, organisationTier: IOrganisationTier | null = null): Promise<IAtlassianCart> => {
    const {cartID, accountChangeOption, type, mac} = payload;
    const data = {
        cartID,
        type,
        data: {
            accountChanges: [{accountChangeOption}],
        },
    };
    const headers = {
        'x-api-key': HAMLET_API_KEY,
        mac,
    };
    const response = await axios.post<IAtlassianCartResponse>(`${HAMLET_URL}/cart/addupgradeitem`, data, {headers});

    return atlassianNewCartFactory(response.data, organisationTier);
};

import {ICart} from '../interfaces/quote/cart.interface';
import {atlassianCartFactory} from './suppliers/atlassian/atlassian-cart.factory';
import Supplier from '../enums/supplier';
// INTERFACES / TYPES
import {IAtlassianCart} from '../interfaces/suppliers/atlassian/atlassian-cart.interface';
import {IOrganisationTier} from "../interfaces/organisation/organisation.interface";

export const cartFactory = (data: ICart, organisationTier: IOrganisationTier | null = null): ICart => {
    switch (data.type) {
        case Supplier.atlassian:
            return atlassianCartFactory(data as IAtlassianCart, organisationTier);
        default:
            throw new Error('Unhandled supplier cart type');
    }
};

export enum CreateQuoteControlsTypes {
    INITIALIZE_STEPPER = 'INITIALIZE_STEPPER',
    NEXT_STEP = 'NEXT_STEP',
    PREVIOUS_STEP = 'PREVIOUS_STEP',
    RESET_STEP = 'RESET_STEP',
}

export interface StepperStateI {
    totalSteps: number;
    startingStep: number;
    currentStep: number;
}

export interface IControlsState {
    createQuoteStepper: StepperStateI;
}

export interface IInitializeStepperPayload {
    totalSteps: number;
    currentStep?: number;
}
export interface INextOrPreviousStepPayload {
    currentStep: number;
}
export interface IInitializeStepperAction {
    type: typeof CreateQuoteControlsTypes.INITIALIZE_STEPPER;
    payload: IInitializeStepperPayload;
}
export interface INextStepAction {
    type: typeof CreateQuoteControlsTypes.NEXT_STEP;
    payload?: INextOrPreviousStepPayload;
}
export interface IPreviousStepAction {
    type: typeof CreateQuoteControlsTypes.PREVIOUS_STEP;
    payload?: INextOrPreviousStepPayload;
}
export interface IResetStepAction {
    type: typeof CreateQuoteControlsTypes.RESET_STEP;
}

export type ControlsActions = IInitializeStepperAction | INextStepAction | IPreviousStepAction | IResetStepAction;

import {ListTable, Rule} from '@adaptavist/component-library';
import React, {Fragment} from 'react';
import {IItem} from '../../../interfaces/quote/item.interface';
import {formatPrice} from '../../../utilities/currency-format';
import {calculateTotalPrice} from '../../../utilities/quote-functions';
import QuoteSummaryItem from './quote-summary-item';

export interface IQuoteSummaryPrices {
    quoteQuantity: number;
    items: IItem[];
    discountName?: string;
    currency?: string;
}

// Todo: Need to calc discount to display here
const QuoteSummaryPrices = (props: IQuoteSummaryPrices) => {
    const {quoteQuantity, items, currency, discountName} = props;
    const {totalItemPrice, total, totalDiscount, discountPercentage} = calculateTotalPrice(items);
    return (
        <Fragment>
            <ListTable>
                <tbody>
                <QuoteSummaryItem leftItem="Quote Quantity" rightItem={quoteQuantity}/>
                {totalDiscount > 0
                    ? <QuoteSummaryItem leftItem="List Price Total" rightItem={formatPrice({price: totalItemPrice})}/>
                    : null}
                {totalDiscount > 0
                    ? (
                        <QuoteSummaryItem
                            leftItem={`Discounts: ${discountName} (${discountPercentage}%)`}
                            rightItem={`(${formatPrice({price: totalDiscount, currencyType: currency})})`}
                        />
                    ) : null}
                </tbody>
            </ListTable>
            <Rule/>
            <ListTable>
                <tbody>
                <QuoteSummaryItem
                    leftItem="Total"
                    rightItem={formatPrice({price: total, currencyType: currency})}
                />
                </tbody>
            </ListTable>
        </Fragment>
    );
};

export default QuoteSummaryPrices;

import {put, select, takeEvery} from 'redux-saga/effects';
// APIS
import deleteQuote from '../../../api/quote-builder/delete-quote';
// ACTIONS
import {deleteQuoteDone} from '../../actions/quote-view.actions';
// OTHERS - interface / types, constants
import {IDeleteQuoteStartAction, QuoteViewActionTypes} from '../../action-types/quote-view-types';
import {IQuote} from '../../../interfaces/quote/quote.interface';
import {notification} from 'antd';
import {ReduxStoreType} from '../../root.reducer';

export function* deleteGivenQuote({payload}: IDeleteQuoteStartAction) {
    const {organisation, trackUserAction} = payload;
    const {authorization, quoteView}: ReduxStoreType = yield select();
    try {
        const {getAccessTokenSilently} = authorization;
        if (!getAccessTokenSilently || !organisation) {
            throw new Error('Authorization required');
        }
        const accessToken: string = yield getAccessTokenSilently();

        yield deleteQuote(accessToken, organisation, payload.createdAtKey);

        const newQuoteView = quoteView.allQuotes.data;
        const quoteIndex = newQuoteView.findIndex((quote: IQuote) => quote.createdAtKey === payload.createdAtKey);
        newQuoteView.splice(quoteIndex, 1);
        yield put(deleteQuoteDone({updatedAllQuotesData: newQuoteView}));
        if (trackUserAction && payload.trackingId) {
            trackUserAction({
                event: 'Deleted Quote',
                properties: {
                    tracking_id: payload.trackingId,
                },
            });
        }
        notification.success({
            message: 'Quote Deleted Successfully',
        });
    } catch (error) {
        notification.error({
            message: 'Quote Could not be Deleted',
        });
        yield put(
            deleteQuoteDone({
                errorDeletingQuote: true,
            })
        );
    }
}

export function* onDelete() {
    yield takeEvery(QuoteViewActionTypes.DELETE_QUOTE_START, deleteGivenQuote);
}

import React, {useEffect, useState, Fragment} from 'react';
import styled from 'styled-components';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// COMPONENTS
import {Button, FormField, Input, Label} from '@adaptavist/component-library';
import {Checkbox} from '@adaptavist/component-library';
import {Row, Col, Drawer, Typography, Skeleton, Empty} from 'antd';
// ACTIONS
import {getAllUsersActionStart} from '../../../redux/actions/user.actions';
// API
import {getUserAPI} from '../../../api/chell/users/users';
// INTERFACES
import {IUser, UserMatchParams} from '../../../interfaces/rbac/user/user.interface';
import IRole from '../../../interfaces/rbac/role/role.interface';
import {ReduxStoreType} from '../../../redux/root.reducer';
// OTHERS - functions
import {openNotification} from '../invite-user/invite-functions';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';
import {AppName} from '../../../constants/metadata.constants';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';
import addRolesToUser from '../../../api/chell/users/add-roles-to-user';
import removeRolesFromUser from '../../../api/chell/users/remove-roles-from-user';

const {Title} = Typography;

const RolesContainer = styled.div`
    > *:not(:last-child) {
        margin-right: 15px;
    }
    > * {
        margin-bottom: 10px;
    }
`;

const ButtonContainer = styled.div`
    text-align: right;
    > *:not(:last-child) {
        margin-right: 30px;
    }
`;

export interface InviteUserProps extends RouteComponentProps<UserMatchParams> {}

export interface EditUserStateI {
    isLoading: boolean;
    isSubmitting: boolean;
    changesMade: boolean;
    user: IUser | null;
    userRoles: (IRole & {selected: boolean})[];
    addUserRoles: string[];
    removeUserRoles: string[];
    initialUserRoles: string[];
    error: boolean;
}

const InviteUser = (props: InviteUserProps) => {
    document.title = `Edit User - ${AppName}`;
    const {history, match} = props;
    // Auth0
    const {getAccessTokenSilently} = useAuth0WithCypress();
    // REDUX STATE
    const {roles} = useSelector((state: ReduxStoreType) => ({
        roles: state.roles.data,
    }));
    // Dispatch
    const dispatch = useDispatch();

    const [showDrawer, setShowDrawer] = useState(true);
    const [editUserState, setEditUserState] = useState<EditUserStateI>({
        isLoading: false,
        isSubmitting: false,
        changesMade: false,
        user: null,
        userRoles: [],
        addUserRoles: [],
        removeUserRoles: [],
        initialUserRoles: [],
        error: false,
    });

    const {organisation} = useOrganisation();

    const closeDrawer = () => {
        setShowDrawer(false);
        if (editUserState.changesMade) {
            dispatch(getAllUsersActionStart({organisation}));
        }
        history.push('/users');
    };

    const {uuid} = match.params;
    const getData = async () => {
        setEditUserState({
            ...editUserState,
            isLoading: true,
        });
        if (!roles || !organisation) return;
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getUserAPI({accessToken, organisation, uuid}, organisation);
            const userRoles: (IRole & {selected: boolean})[] = [];
            const initialUserRoles: string[] = [];
            for (const role of roles) {
                const selected = !!response.roles.find((userRole) => userRole.uuid === role.uuid);
                userRoles.push({...role, selected});
                if (selected) initialUserRoles.push(role.uuid);
            }
            setEditUserState({
                ...editUserState,
                error: false,
                isLoading: false,
                userRoles: userRoles,
                initialUserRoles,
                user: response,
            });
        } catch (error) {
            setEditUserState({
                ...editUserState,
                error: true,
                isLoading: false,
            });
        }
    };
    useEffect(() => {
        getData();
        setShowDrawer(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match, roles]);

    const handleToggleRole = (role: IRole & {selected: boolean}) => () => {
        const isSelected = !role.selected;
        const {userRoles, addUserRoles, removeUserRoles, initialUserRoles} = editUserState;
        if (!userRoles) return;
        let newAddUserRoles: string[] = [...addUserRoles];
        let newRemoveUserRoles: string[] = [...removeUserRoles];
        if (!initialUserRoles.includes(role.uuid) && isSelected) {
            newAddUserRoles.push(role.uuid);
        }
        if (removeUserRoles.includes(role.uuid)) {
            newRemoveUserRoles = removeUserRoles.filter((rus) => rus !== role.uuid);
        }
        if (initialUserRoles.includes(role.uuid) && !isSelected) {
            newRemoveUserRoles.push(role.uuid);
        }
        if (addUserRoles.includes(role.uuid)) {
            newAddUserRoles = addUserRoles.filter((aus) => aus !== role.uuid);
        }
        setEditUserState((prevState) => ({
            ...editUserState,
            userRoles: prevState.userRoles.map((ur) => (ur.uuid === role.uuid ? {...ur, selected: !ur.selected} : ur)),
            addUserRoles: newAddUserRoles,
            removeUserRoles: newRemoveUserRoles,
        }));
    };

    const {analytics, isAnalyticsInitialized} = useSegment();

    const trackAction = ({success}: {success: boolean}) => {
        const {userRoles, addUserRoles} = editUserState;
        if (!userRoles) return;
        if (!analytics || !isAnalyticsInitialized) return;
        const addedRoles: Pick<IRole, 'name' | 'uuid'>[] = [];
        for (const roleKey in addUserRoles) {
            const found = userRoles.find((userRole) => userRole.uuid === addUserRoles[roleKey]);
            addedRoles.push({
                uuid: found?.uuid || '',
                name: found?.name || '',
            });
        }
        analytics.trackUserAction({
            event: 'Modified User',
            properties: {
                successful: success,
                action: 'Edit Roles',
                roles_added: addedRoles,
            },
        });
    };

    const submitEditedChanges = async () => {
        const {user, userRoles, removeUserRoles, addUserRoles} = editUserState;
        if (!userRoles || user === null || !organisation) return;
        setEditUserState({
            ...editUserState,
            isSubmitting: true,
        });
        try {
            const addedRoles: Pick<IRole, 'name' | 'uuid'>[] = [];
            for (const roleKey in addUserRoles) {
                const found = userRoles.find((userRole) => userRole.uuid === addUserRoles[roleKey]);
                addedRoles.push({
                    uuid: found?.uuid || '',
                    name: found?.name || '',
                });
            }
            const accessToken = await getAccessTokenSilently();
            if (addUserRoles.length) {
                await addRolesToUser(
                    {
                        accessToken,
                        userUUID: uuid,
                        roles: addUserRoles,
                        organisationUUID: organisation,
                    },
                    organisation
                );
            }
            if (removeUserRoles.length) {
                await removeRolesFromUser(
                    {
                        accessToken,
                        userUUID: uuid,
                        roles: removeUserRoles,
                        organisationUUID: organisation,
                    },
                    organisation
                );
            }
            openNotification({
                content: `Changes updated successfully`,
                type: 'success',
                duration: 5,
            });
            setEditUserState({
                ...editUserState,
                changesMade: true,
                isSubmitting: false,
            });
            trackAction({success: true});
        } catch (error) {
            trackAction({success: false});
            openNotification({
                content: `There was an error making changes. Please, try again`,
                type: 'error',
                duration: 7,
            });
            setEditUserState({
                ...editUserState,
                isSubmitting: false,
            });
        }
    };

    const {isLoading, isSubmitting, user, error, userRoles, addUserRoles, removeUserRoles} = editUserState;
    const disableButtons = error || isLoading || isSubmitting;
    const disableSubmitButton = !addUserRoles.length && !removeUserRoles.length ? true : false;
    const Footer = (
        <ButtonContainer>
            <Button disabled={disableButtons} buttonType="secondary" ghost onClick={closeDrawer}>
                Cancel
            </Button>
            <Button
                disabled={disableButtons || disableSubmitButton}
                loading={isSubmitting}
                onClick={submitEditedChanges}
            >
                Save Changes
            </Button>
        </ButtonContainer>
    );

    return (
        <Drawer
            title={
                <Title level={2} style={{marginBottom: 0}}>
                    Edit User
                </Title>
            }
            visible={showDrawer}
            width={500}
            onClose={closeDrawer}
            footer={Footer}
        >
            {/* Display component when loading */}
            {isLoading && <Skeleton />}
            {/* Display component when not loading and has */}
            {!isLoading && error && (
                <Empty description="There was an error getting user data. Please, try again">
                    <Button onClick={getData}>Refresh</Button>
                </Empty>
            )}
            {/* Display component when not loading and error free */}
            {!isLoading && !error && (
                <Fragment>
                    <Row>
                        {userRoles && (
                            <Fragment>
                                <Title level={4}>Change Roles</Title>
                                <Col span={24} style={{marginBottom: '10px'}}>
                                    <RolesContainer>
                                        {userRoles.map((role, index: number) => (
                                            <Checkbox
                                                key={`role-${index}`}
                                                checked={role.selected}
                                                onChange={handleToggleRole(role)}
                                            >
                                                {role.name}
                                            </Checkbox>
                                        ))}
                                    </RolesContainer>
                                </Col>
                            </Fragment>
                        )}
                        <Title level={4}>User Information</Title>
                        <Col span={24}>
                            <FormField>
                                <Label>Email</Label>
                                <Input disabled value={user?.email} />
                            </FormField>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Drawer>
    );
};

export default withRouter(InviteUser);

import React, {FC, Fragment} from 'react';
import {Steps} from 'antd';

import {INextOrPreviousStepPayload} from '../../redux/action-types/create-quote-controls.types';

const {Step} = Steps;

export interface StepperProps {
    steps: {
        title: string;
        content?: JSX.Element;
        disable?: boolean;
    }[];
    currentStep: number;
    endOfStepIndex: number;
    changeStep?: (payload: INextOrPreviousStepPayload) => void;
}

const Stepper: FC<StepperProps> = (props) => {
    const {steps, currentStep, endOfStepIndex, changeStep, children} = props;

    const isStepDisabled = (current: number, index: number) =>
        current === 0 || current === endOfStepIndex || index >= current;

    return (
        <Fragment>
            <Steps
                current={currentStep}
                progressDot
                onChange={(current) => {
                    if (changeStep) changeStep({currentStep: current});
                }}
            >
                {steps.map((step, index) => (
                    <Step
                        key={`${step.title}-${index}`}
                        title={step.title}
                        disabled={step.disable || isStepDisabled(currentStep, index)}
                    />
                ))}
            </Steps>
            {children}
        </Fragment>
    );
};

export default Stepper;

import {
    IGetAllUsersDoneActionPayload,
    IGetAllUsersStartPayload,
    IGetMoreUsersStartPayload,
    IGetMoreUsersDonePayload,
    IGetOrganisationDetailsStartPayload,
    IGetOrganisationDonePayload,
    IUpdateOrganisationDonePayload,
    IUpdateOrganisationStartPayload,
    UserActionTypes,
} from '../action-types/user.types';

export const getAllUsersActionStart = (payload: IGetAllUsersStartPayload) => ({
    type: UserActionTypes.GET_ALL_USERS_START,
    payload
});

export const getAllUsersActionDone = (payload: IGetAllUsersDoneActionPayload) => ({
    type: UserActionTypes.GET_ALL_USERS_DONE,
    payload,
});

export const getMoreUsersActionStart = (payload: IGetMoreUsersStartPayload) => ({
    type: UserActionTypes.GET_MORE_USERS_START,
    payload
});

export const getMoreUsersActionDone = (payload: IGetMoreUsersDonePayload) => ({
    type: UserActionTypes.GET_MORE_USERS_DONE,
    payload,
});

export const getOrganisationDetailsStart = (payload: IGetOrganisationDetailsStartPayload) => ({
    type: UserActionTypes.GET_ORGANISATION_DETAILS_START,
    payload
});

export const getOrganisationDetailsDone = (payload: IGetOrganisationDonePayload) => ({
    type: UserActionTypes.GET_ORGANISATION_DETAILS_DONE,
    payload,
});

export const updateOrganisationDetailsStart = (payload: IUpdateOrganisationStartPayload) => ({
    type: UserActionTypes.UPDATE_ORGANISATION_DETAILS_START,
    payload,
});

export const updateOrganisationDetailsDone = (payload: IUpdateOrganisationDonePayload) => ({
    type: UserActionTypes.UPDATE_ORGANISATION_DETAILS_DONE,
    payload,
});

import React from 'react';
import {TableCell} from '@adaptavist/component-library';

export interface IQuoteSummaryItem {
    leftItem: React.ReactNode;
    rightItem: React.ReactNode;
}

const QuoteSummaryItem = (props: IQuoteSummaryItem) => {
    const {leftItem, rightItem} = props;
    return (
        <tr>
            <TableCell>{leftItem}</TableCell>
            <TableCell align="right">{rightItem}</TableCell>
        </tr>
    );
};
export default QuoteSummaryItem;

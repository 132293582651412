import React, {Fragment, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {useAuth0WithCypress} from '@adaptavist-commerce/auth0-with-cypress-react';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
// COMPONENTS
import {Button, Checkbox, Input, Label, Row} from '@adaptavist/component-library';
import {Col, Drawer, Skeleton, Typography} from 'antd';
import {SendOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
// TYPES/INTERFACE
import IRole from '../../../interfaces/rbac/role/role.interface';
// FUNCTIONS
import {inviteUsers} from '../../../api/chell/users/users';
import {ButtonContainer, EmailTag, RolesContainer, TagButton} from './invite-user.style';
import {ReduxStoreType} from '../../../redux/root.reducer';
import {openNotification} from './invite-functions';
import {AppName} from '../../../constants/metadata.constants';
import {getAllUsersActionStart} from '../../../redux/actions/user.actions';
import {useSegment} from '@adaptavist-commerce/analytics-provider-react';

const {Title} = Typography;

export interface InviteUserProps extends RouteComponentProps {}

interface IInviteState {
    emails: string[];
    value: string;
    inviteSent: boolean;
    emailError?: string;
    isSending: boolean;
}

let emailValidation = Yup.string().email().required();

const InviteUser = (props: InviteUserProps) => {
    document.title = `Invite User - ${AppName}`;
    const [showDrawer, setShowDrawer] = useState(true);
    const [userRoles, setUserRoles] = useState<(IRole & {selected: boolean})[] | null>(null);
    const [inviteState, setInviteState] = useState<IInviteState>({
        emails: [],
        value: '',
        emailError: undefined,
        inviteSent: false,
        isSending: false,
    });
    const [isRoleSelected, setIsRoleSelected] = useState<boolean>(false);
    const {authorization, roles} = useSelector((state: ReduxStoreType) => state);
    const {gettingRoles} = roles;
    const {organisation} = authorization;
    const {getAccessTokenSilently} = useAuth0WithCypress();
    const {history} = props;

    const dispatch = useDispatch();

    const closeDrawer = () => {
        setShowDrawer(false);
        if (inviteState.inviteSent) {
            dispatch(getAllUsersActionStart({organisation}));
        }
        history.push('/users');
    };

    useEffect(() => {
        setShowDrawer(true);
    }, []);

    const {analytics, isAnalyticsInitialized} = useSegment();

    useEffect(() => {
        const {emails, isSending} = inviteState;
        if (!isSending || !organisation) return;
        (async () => {
            const payload = emails.map((email: string) => ({
                emailAddress: email,
                roles: userRoles!.reduce((arr: string[], role) => (role.selected ? [...arr, role.uuid!] : arr), []),
            }));
            const plural = emails.length > 1 ? 's' : '';
            try {
                const accessToken = await getAccessTokenSilently();
                await inviteUsers({accessToken, organisation, invites: payload}, organisation);
                openNotification({content: `Invite${plural} sent successfully`, type: 'success', duration: 5});
                setInviteState({
                    ...inviteState,
                    emails: [],
                    value: '',
                    inviteSent: true,
                    isSending: false,
                });
                if (analytics || isAnalyticsInitialized) {
                    const rolesAdded = userRoles?.map((role) => ({
                        uuid: role.uuid,
                        name: role.name,
                    }));
                    analytics.trackUserAction({
                        event: 'Modified User',
                        properties: {
                            action: 'Invite User',
                            roles_added: rolesAdded,
                        },
                    });
                }
                initializeRoles();
            } catch (error) {
                openNotification({
                    content: `There was an error sending invite${plural}. Please, try again`,
                    type: 'error',
                });
                setInviteState({
                    ...inviteState,
                    isSending: false,
                });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteState]);

    const initializeRoles = () => {
        if (!roles.data) return;
        setUserRoles(roles.data.map((r) => ({...r, selected: false})));
    };

    useEffect(() => {
        initializeRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles.data]);

    useEffect(() => {
        setIsRoleSelected(userRoles?.some((role) => role.selected) || false);
    }, [userRoles]);

    const handleToggleRole = (role: IRole & {selected: boolean}) => () => {
        setUserRoles(
            (prevState) => prevState?.map((r) => (r.uuid === role.uuid ? {...r, selected: !r.selected} : r)) || null
        );
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'Tab', ','].includes(event.key)) {
            event.preventDefault();
            const value = inviteState.value.trim();
            if (value) updateEmailList(value).then();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInviteState({
            ...inviteState,
            value: event.target.value,
            emailError: undefined,
        });
    };

    const handleDelete = (item: string) => {
        const {emails} = inviteState;
        setInviteState({
            ...inviteState,
            emails: emails.filter((i) => i !== item),
        });
    };

    const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        const paste = event.clipboardData.getData('text');
        const strArr = paste.split(/,\s*/);
        const emails: string[] = [];
        for (const str of strArr) {
            if (await emailValidation.isValid(str)) {
                emails.push(str);
            }
        }

        if (emails.length) {
            setInviteState({
                ...inviteState,
                emails: [...new Set([...inviteState.emails, ...emails])],
            });
        }
    };

    const inviteHandler = async () => {
        if (!organisation) return;
        const {emails, value} = inviteState;
        if (!value && !emails.length) {
            console.log(emails);
            openNotification({content: 'Please provide a valid email to continue', type: 'error'});
            return;
        }
        if (value) {
            updateEmailList(value, true).then();
            return;
        }
        setInviteState({
            ...inviteState,
            value: '',
            isSending: true,
        });
    };

    const updateEmailList = async (email: string, shouldSend: boolean = false) => {
        let error: string | null = null;
        if (isInList(email)) error = `${email} has already been added.`;
        if (!(await emailValidation.isValid(email)))
            error = email ? `${email} is not a valid email address.` : `Please provide a valid email address`;
        if (error) {
            setInviteState({
                ...inviteState,
                emailError: error,
            });
            return;
        }
        setInviteState({
            ...inviteState,
            emails: [...inviteState.emails, inviteState.value],
            value: '',
            isSending: shouldSend,
        });
    };

    const isInList = (email: string) => {
        return inviteState.emails.includes(email);
    };

    const {isSending} = inviteState;
    return (
        <Drawer
            title={
                <Title level={2} style={{marginBottom: 0}}>
                    Invite User
                </Title>
            }
            visible={showDrawer}
            width={500}
            onClose={closeDrawer}
            footer={
                <ButtonContainer>
                    <Button disabled={isSending} buttonType="secondary" ghost onClick={closeDrawer}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        loading={isSending}
                        disabled={isSending || !isRoleSelected}
                        onClick={() => inviteHandler()}
                    >
                        <SendOutlined rotate={320} style={{marginRight: '5px', fontSize: '18px'}} />
                        Send Invite
                    </Button>
                </ButtonContainer>
            }
        >
            <Fragment>
                <Row>
                    {gettingRoles && <Skeleton active />}
                    {!gettingRoles && userRoles && (
                        <Fragment>
                            <Title level={3}>Select Roles</Title>
                            <Col span={24} style={{marginBottom: '10px'}}>
                                <RolesContainer>
                                    {roles && (
                                        <Fragment>
                                            {userRoles.map((r, index) => (
                                                <Checkbox
                                                    key={`${r}-${index}`}
                                                    checked={r.selected}
                                                    onChange={handleToggleRole(r)}
                                                >
                                                    {r.name}
                                                </Checkbox>
                                            ))}
                                        </Fragment>
                                    )}
                                </RolesContainer>
                            </Col>{' '}
                        </Fragment>
                    )}
                    <Title level={3}>User Information</Title>
                    <Col span={24}>
                        <Label>Email</Label>
                        <Input
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Type, paste, single or multiple email addresses..."
                            value={inviteState.value}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            onPaste={handlePaste}
                            errorMessage={inviteState.emailError}
                        />
                        {inviteState.emails.map((email, index) => (
                            <EmailTag key={`email-${index}`}>
                                {email}
                                <TagButton onClick={() => handleDelete(email)}>&times;</TagButton>
                            </EmailTag>
                        ))}
                    </Col>
                </Row>
            </Fragment>
        </Drawer>
    );
};

export default withRouter(InviteUser);

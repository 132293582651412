import {put, select, takeLatest} from 'redux-saga/effects';
import {IAddToCartByTypeStartAction, IQuoteState, QuoteActionTypes} from '../../action-types/quote-types';
import {getOneCartByType} from '../../../actions/cart-actions';
import {addToCartDone, addToCartFailed, replaceCart, setSelectedProduct} from '../../actions/quote-actions';
import {ICart} from '../../../interfaces/quote/cart.interface';
import Supplier from '../../../enums/supplier';
import {IOrganisationTier} from '../../../interfaces/organisation/organisation.interface';
import {addAtlassianItemToCart} from '../../../api/carts/atlassian/hamlet/add-atlassian-item-to-cart';
import {getNewAtlassianCart} from '../../../api/carts/atlassian/hamlet/get--new-atlassian-cart';
import {IAtlassianCart} from '../../../interfaces/suppliers/atlassian/atlassian-cart.interface';
import {ReduxStoreType} from '../../root.reducer';
import {ITrackUserAction} from '@adaptavist-commerce/analytics-provider-react';

interface IAddItemToCart {
    cartId: string;
    organisationTier: IOrganisationTier | null;
    tier: string;
    month: {value: string; label: string};
    supplier: Supplier;
}

function* createNewCartWithItem(payload: Omit<IAddItemToCart, 'cartId'>) {
    const {organisationTier, tier, month, supplier} = payload;
    const newCart: IAtlassianCart = yield getNewAtlassianCart({mac: 'UK'});
    let newCartWithItem: ICart = yield addItemToCart({
        cartId: newCart.uuid,
        organisationTier,
        tier,
        month,
        supplier,
    });
    yield put(addToCartDone(newCartWithItem));
    yield put(setSelectedProduct(null));
    return newCartWithItem;
}

function* addItemToCart(payload: IAddItemToCart) {
    const {month, supplier, ...rest} = payload;
    switch (supplier) {
        case Supplier.atlassian:
            const result: IAtlassianCart = yield addAtlassianItemToCart({
                ...rest,
                maintenanceMonths: month.value,
                mac: 'UK',
            });
            return result;
        default:
            throw new Error('Unhandled supplier');
    }
}

export function* addToCartByType({payload}: IAddToCartByTypeStartAction) {
    const {quote}: ReduxStoreType = yield select();
    const {tier, supplier, trackUserAction, ...rest} = payload;
    if (!tier) {
        yield put(addToCartFailed());
        return;
    }
    const cart: ICart | undefined = yield getOneCartByType(quote.carts, supplier);
    try {
        if (!cart) {
            const newCart: ICart = yield createNewCartWithItem({tier, supplier, ...rest});
            trackAddItemToCart(trackUserAction, quote, tier, newCart, rest);
            return;
        }
        const newCart: IAtlassianCart = yield addItemToCart({cartId: cart.uuid, tier, supplier, ...rest});
        yield put(replaceCart(newCart));
        trackAddItemToCart(trackUserAction, quote, tier, newCart, rest);
        yield put(setSelectedProduct(null));
    } catch (error) {
        yield put(addToCartFailed());
        const {trackingId} = quote;
        if (!trackUserAction || !trackingId) return;
        const {selectedProduct} = quote;
        const product = regexMatchNameService(selectedProduct?.label || '');
        const orderableItemIDArray = tier.split(':');
        trackUserAction({
            event: 'Added App',
            properties: {
                addon_name: product?.name,
                hosting: product?.service,
                successful: false,
                tracking_id: trackingId,
                product_key: orderableItemIDArray[1],
                units: Number(orderableItemIDArray[3]),
                tier: tier,
                maintenance_period: Number(rest.month.value),
            },
        });
    }
}
const regexMatchNameService = (str: string) => {
    const result = /(.*?)\s+–\s+(Server|Data Center)$/.exec(str);
    if (!result) return null;
    return {name: result[1], service: result[2]};
};

function trackAddItemToCart(
    trackUserAction: ((payload: ITrackUserAction) => void) | undefined,
    quote: IQuoteState,
    tier: string,
    newCart: ICart,
    rest: {organisationTier: IOrganisationTier | null; month: {value: string; label: string}}
) {
    const {trackingId} = quote;
    if (!trackUserAction || !trackingId) return;
    const {selectedProduct} = quote;
    const product = regexMatchNameService(selectedProduct?.label || '');
    const orderableItemIDArray = tier.split(':');
    trackUserAction({
        event: 'Added App',
        properties: {
            addon_name: product?.name,
            hosting: product?.service,
            successful: true,
            tracking_id: trackingId,
            product_key: orderableItemIDArray[1],
            units: Number(orderableItemIDArray[3]),
            tier: tier,
            maintenance_period: Number(rest.month.value),
        },
    });
}

export function* onAddToCartByType() {
    yield takeLatest(QuoteActionTypes.ADD_TO_CART_BY_TYPE_START, addToCartByType);
}

import React, {FC} from 'react';
import styled from 'styled-components';

const LoadingFullPageContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    animation: blink 3s infinite;

    @keyframes blink {
        0% {
            opacity: 0;
            background-color: #fff;
        }
        50% {
            opacity: 0.5;
            background-color: #edf1fe;
        }
        100% {
            opacity: 0;
            background-color: #fff;
        }
    }
`;
const LoadingPageContainer = styled.div`
    position: relative;
    min-height: 30px;
    width: 100%;
    animation: blink 3s infinite;

    @keyframes blink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.8;
        }
        100% {
            opacity: 0;
        }
    }
`;

const LoadingContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    display: table-cell;
    text-align: center;
`;
export const LoadingFullPage: FC = (props) => {
    return (
        <LoadingFullPageContainer>
            <LoadingContent>
                {props.children && props.children}
                {!props.children && <h1>Getting data...</h1>}
            </LoadingContent>
        </LoadingFullPageContainer>
    );
};

export const AuthorizedRedirectPage: FC = () => {
    return (
        <LoadingFullPageContainer>
            <LoadingContent>
                <h1>Redirecting for authentication...</h1>
            </LoadingContent>
        </LoadingFullPageContainer>
    );
};

export const LoadingPage: FC = (props) => {
    return (
        <LoadingPageContainer>
            <LoadingContent>
                {props.children && props.children}
                {!props.children && <h1>Getting data...</h1>}
            </LoadingContent>
        </LoadingPageContainer>
    );
};

export default LoadingPage;

import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './root.reducer';
import {rootSaga} from './root.saga';
import {composeWithDevTools} from 'redux-devtools-extension';

const logger = createLogger();
const middleWare = [];
const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === 'development') {
    middleWare.push(logger);
}
middleWare.push(sagaMiddleware);
middleWare.push(thunk);

const reduxStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleWare)));

sagaMiddleware.run(rootSaga);

export default reduxStore;

import React, {FC} from 'react';
import {Button, Table, TableCell, TableHeader} from '@adaptavist/component-library';
import {Col, Row} from 'antd';
import {
    IAccountChangeOption,
    ISENRenewal,
} from '../../../interfaces/suppliers/atlassian/sen-renewals-and-upgrades.interface';
import {toCapitalised} from '../../../utilities/switch-case';
import {getFullDate} from '../../../utilities/datetime/datetime-formatting';
import {useDispatch, useSelector} from 'react-redux';
import {IQuote} from '../../../interfaces/quote/quote.interface';
import {getOneCartByType} from '../../../actions/cart-actions';
import Supplier from '../../../enums/supplier';
import {addToCartDone, replaceCart} from '../../../redux/actions/quote-actions';
import {formatPrice} from '../../../utilities/currency-format';
import {RenewLicenseTableHeaderTitle} from '../../../data/table-headers';
import {addAtlassianRenewal} from '../../../api/carts/atlassian/hamlet/add-atlassian-renewal';
import {getNewAtlassianCart} from '../../../api/carts/atlassian/hamlet/get--new-atlassian-cart';
import {useOrganisation} from '@adaptavist-commerce/organisation-context-react';

interface IQuoteLicenseRenewalPreviewProps {
    renewal: ISENRenewal;
    setRenewal: (renewal: ISENRenewal | null) => void;
}

const QuoteLicenseRenewalPreview: FC<IQuoteLicenseRenewalPreviewProps> = ({renewal, setRenewal}) => {
    const carts = useSelector((state: {quote: IQuote}) => state.quote.carts);
    const dispatch = useDispatch();

    const {getOrganisationTier} = useOrganisation();

    const addRenewalToQuote = async () => {
        const cart = getOneCartByType(carts, Supplier.atlassian);
        if (!cart) {
            createNewCartAddItem(renewal.changeOptions?.[0]).then(() => setRenewal(null));
            return;
        }
        addItem(cart.uuid, renewal.changeOptions?.[0]).then(() => setRenewal(null));
    };

    const createNewCartAddItem = async (changeOption: IAccountChangeOption) => {
        const newCart = await getNewAtlassianCart({mac: 'UK'});
        const payload = {cartID: newCart.uuid, accountChangeOption: changeOption, mac: 'UK'};
        const newCartWithItem = await addAtlassianRenewal(payload, getOrganisationTier());
        dispatch(addToCartDone(newCartWithItem));
    };

    const addItem = async (cartUuid: string, changeOption: IAccountChangeOption) => {
        const payload = {cartID: cartUuid, accountChangeOption: changeOption, mac: 'UK'};
        const newCart = await addAtlassianRenewal(payload, getOrganisationTier());
        dispatch(replaceCart(newCart));
    };

    return (
        <Row>
            <Col span={24}>
                <Table striped={true}>
                    <thead>
                        <tr>
                            {RenewLicenseTableHeaderTitle.map((header, index) => (
                                <TableHeader key={`${header.title}-${index}`} align={header.align}>
                                    {header.title}
                                </TableHeader>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <TableCell>{renewal.productDescription}</TableCell>
                            <TableCell>{getFullDate(new Date(renewal.expireDate))}</TableCell>
                            <TableCell>
                                {renewal.unitCount} {toCapitalised(renewal.unitLabel)}
                            </TableCell>
                            <TableCell align="right">
                                {formatPrice({price: renewal.changeOptions?.[0].amount})}
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={addRenewalToQuote}>Add to Quote</Button>
                            </TableCell>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default QuoteLicenseRenewalPreview;
